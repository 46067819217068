import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Checkmark } from '../../assets/svg/outlinedCheckmark.svg';
import Button, { ButtonVariation } from '../../components/button';
import { ButtonContainer, FormWrapper } from '../../components/cmpAuthWrapper';
import { pxToRem } from '../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';

interface SuccessFormProps {
    title: string;
    description: string;
    buttonText: string;
    onClick?: () => void;
}

const SuccessForm = ({
    title,
    description,
    buttonText,
    onClick
}: SuccessFormProps) => {
    return (
        <FormWrapper>
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                sx={{
                    letterSpacing: pxToRem(-0.20000000298023224),
                    marginBottom: pxToRem(32)
                }}
                text={title}
            />
            <Box sx={{ display: 'flex', gap: pxToRem(16) }}>
                <Checkmark style={{ flexShrink: 0 }} />
                <CMPTypography variation={TypographyVariation.TYPO_SCALE_18}>
                    {description}
                </CMPTypography>
            </Box>

            <ButtonContainer>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            </ButtonContainer>
        </FormWrapper>
    );
};

export default SuccessForm;
