import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';
import CMPTypography, { TypographyVariation } from './textFields/cmpTypography';
import CMPColors from './colors';

interface CustomTooltipProps {
    message: string;
    children: React.ReactElement;
    followCursor?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: CMPColors.light.text.secondary,
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}));

const CustomTooltip = (props: CustomTooltipProps): React.ReactElement => {
    return (
        <LightTooltip
            followCursor={props.followCursor}
            title={
                props.message && (
                    <CMPTypography
                        variation={TypographyVariation.TYPO_SCALE_14}
                        text={props.message}
                    />
                )
            }
            sx={{ order: 1 }}
        >
            {props.children}
        </LightTooltip>
    );
};

export default CustomTooltip;
