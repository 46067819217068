import { Box } from '@mui/material';
import React from 'react';
import {
    MultiSelectBooleanValue,
    MutliSelectBooleanOption
} from '../../../../redux/propertyValue/model';
import { pxToRem } from '../../../../utils/stylesUtils';
import PropertySectionCheckBox from './propertySectionCheckBox';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface PropertySectionMultiSelectBooleanProps {
    value: MultiSelectBooleanValue;
    options: MutliSelectBooleanOption[];
    description?: string;
    onUpdateValue?: (value: MultiSelectBooleanValue) => void;
}

const PropertySectionMultiSelectBoolean = (
    props: PropertySectionMultiSelectBooleanProps
): React.ReactElement => {
    const { value, options, onUpdateValue, description } = props;

    const handleChange = (
        option: MutliSelectBooleanOption,
        checked: boolean
    ) => {
        value[option.key] = checked;
        onUpdateValue?.(value);
    };

    return (
        <Box sx={{ marginTop: pxToRem(16) }}>
            {description && (
                <CMPTypography
                    sx={{
                        color: CMPColors.light.text.primary,
                        marginBottom: pxToRem(6)
                    }}
                    variation={TypographyVariation.TYPO_SCALE_18}
                    text={description}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    width: 'fit-content',
                    overflow: 'hidden'
                }}
            >
                {options.map((o) => {
                    return (
                        <PropertySectionCheckBox
                            key={o.key}
                            checked={value[o.key] ?? o.defaultValue}
                            handleChange={(value) => handleChange(o, value)}
                            label={o.name}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default PropertySectionMultiSelectBoolean;
