import React, { useEffect } from 'react';
import { Box, Grid, IconButton, styled } from '@mui/material';
import { arrayMove } from 'react-sortable-hoc';
import { useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import { ReactComponent as Delete } from '../../../../assets/svg/trashBin.svg';
import { pxToRem } from '../../../../utils/stylesUtils';
import Tooltip from '../../../../components/tooltip';
import CMPRadioButtonGroup, {
    Orientaton
} from '../../../../components/cmpRadioButtonGroup';
import {
    SortableWidgetContainer,
    SortableWidgetItem
} from '../../../canvas/elementEditors/components/sortableWidgetComponents';
import PropertyAssetSelector from '../../propertyDetails/components/propertyAssetSelector';
import { selectAssets } from '../../../../redux/assets/reducer';
import {
    PropertyField,
    PropertyFieldValue,
    ScreenSaverContentValue
} from '../../../../redux/propertyValue/model';
import { MediaType } from '../../../../redux/element/model';
import { AssetPreviewDetails } from '../screenSaver';
import cmpTexts from '../../../../locale/en';
import { ZoneToScreenSaverAssets } from '../manageZones';
import { Options } from '../../../../components/cmpDropDown';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

const PreviewStyles = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'assetUrl'
})<{ assetUrl: string }>(({ assetUrl }) => ({
    backgroundColor: 'black',
    width: pxToRem(168),
    height: pxToRem(95),
    borderRadius: pxToRem(4),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${assetUrl})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
}));

const IconContainer = styled(Box)(() => ({
    position: 'absolute',
    top: pxToRem(6),
    right: pxToRem(8),
    zIndex: 1,
    padding: 0,
    color: CMPColors.dark.icon.primary,
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 16, 1))'
}));

const AssetMiniPreview = ({
    assetType,
    assetUrl = '',
    onDeleteClick,
    onAssetClick
}: {
    assetType: MediaType;
    assetUrl?: string;
    onDeleteClick?: () => void;
    onAssetClick?: ({ assetUrl, type }: AssetPreviewDetails) => void;
}) => (
    <PreviewStyles
        assetUrl={assetType === MediaType.Image ? assetUrl : ''}
        onClick={() => onAssetClick?.({ assetUrl, type: assetType })}
    >
        {!isEmpty(assetUrl) ? (
            <>
                {assetType === MediaType.Video && (
                    <video
                        src={`${assetUrl}#t=1`}
                        style={{
                            objectFit: 'contain',
                            maxHeight: pxToRem(294),
                            maxWidth: '100%',
                            zIndex: 1,
                            borderRadius: pxToRem(5)
                        }}
                        muted
                    />
                )}
                <IconContainer>
                    <Tooltip message="Remove Asset">
                        <IconButton
                            sx={{
                                padding: pxToRem(0),
                                color: CMPColors.dark.icon.primary,
                                filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 16, 1))',
                                width: '16px',
                                height: '16px'
                            }}
                            onClick={() => {}}
                        >
                            <Delete fontSize="small" onClick={onDeleteClick} />
                        </IconButton>
                    </Tooltip>
                </IconContainer>
            </>
        ) : (
            <CMPTypography
                text={
                    assetType === MediaType.Image
                        ? cmpTexts.screenSaver.emptyImageText
                        : cmpTexts.screenSaver.emptyVideoText
                }
                variation={TypographyVariation.TYPO_SCALE_12}
                sx={{
                    letterSpacing: pxToRem(0.4000000059604645),
                    color: CMPColors.dark.text.primary
                }}
            />
        )}
    </PreviewStyles>
);

const drawRemainingEmptyBoxes = (
    count: number,
    assetType: MediaType.Image | MediaType.Video
) => {
    const emptyBoxes = [];
    for (let i = 0; i < count; i++) {
        emptyBoxes.push(
            <Box
                key={i}
                sx={{
                    marginRight: pxToRem(10),
                    position: 'relative'
                }}
            >
                <AssetMiniPreview assetType={assetType} />
            </Box>
        );
    }
    return emptyBoxes;
};

interface ScreenSaverSelectorProps {
    screenSaverContent: PropertyField;
    setHasError: (hasError: boolean) => void;
    onAssetClick: ({ assetUrl, type }: AssetPreviewDetails) => void;
    onScreenSaverFieldUpdate: (value: PropertyFieldValue) => void;
    zoneToScreenSaverAssets: ZoneToScreenSaverAssets;
    setZoneToScreenSaverAssets: (value: ZoneToScreenSaverAssets) => void;
    zoneId: string;
    propertyLogoAssetId: string;
}

const ScreenSaverSelector = ({
    screenSaverContent,
    setHasError,
    onAssetClick,
    onScreenSaverFieldUpdate,
    setZoneToScreenSaverAssets,
    zoneToScreenSaverAssets,
    zoneId,
    propertyLogoAssetId
}: ScreenSaverSelectorProps) => {
    const assets = useSelector(selectAssets);

    const screenSaverContentValue =
        screenSaverContent.value as ScreenSaverContentValue;

    const screenSaverContentConfig = screenSaverContent.configuration;

    const filteredAssets = assets
        .filter(
            (asset) =>
                asset.type.toLocaleLowerCase() ===
                screenSaverContentValue.type.toLocaleLowerCase()
        )
        .filter((asset) => !screenSaverContentValue.assets.includes(asset.id));

    useEffect(() => {
        onAssetClick(
            getDefaultAssetPreviewDetails(screenSaverContentValue.type)
        );
    }, []);

    useEffect(() => {
        if (
            isEmpty(zoneToScreenSaverAssets) ||
            !zoneToScreenSaverAssets[zoneId + screenSaverContentValue.type]
        ) {
            setZoneToScreenSaverAssets({
                ...zoneToScreenSaverAssets,
                [zoneId + screenSaverContentValue.type]: {
                    type: screenSaverContentValue.type,
                    assets: screenSaverContentValue.assets,
                    selected: true
                }
            });
        }
    }, [zoneToScreenSaverAssets]);

    const getDefaultAssetPreviewDetails = (type: MediaType) => {
        const assetPreviewDetails = {
            type: MediaType.Image,
            assetUrl: screenSaverContent.configuration!.defaultScreenSaverImage
        };

        if (type === MediaType.Image) {
            if (screenSaverContentValue.assets.length > 0) {
                assetPreviewDetails.assetUrl =
                    assets?.find(
                        (asset) =>
                            asset.id === screenSaverContentValue.assets[0]
                    )?.url ?? '';
            } else if (propertyLogoAssetId) {
                assetPreviewDetails.assetUrl = assets?.find(
                    (asset) => asset.id === propertyLogoAssetId
                )!.url;
            }
        } else {
            if (screenSaverContentValue.assets.length === 0) {
                assetPreviewDetails.assetUrl = '';
            } else {
                assetPreviewDetails.assetUrl =
                    assets?.find(
                        (asset) =>
                            asset.id === screenSaverContentValue.assets[0]
                    )?.url ?? '';
                assetPreviewDetails.type = MediaType.Video;
            }
        }

        return assetPreviewDetails;
    };

    useEffect(() => {
        onAssetClick(
            getDefaultAssetPreviewDetails(screenSaverContentValue.type)
        );
    }, [screenSaverContentValue.assets, propertyLogoAssetId]);

    const evaluateErrorState = (
        zoneToScreenSaverAssets: ZoneToScreenSaverAssets
    ): boolean =>
        Object.values(zoneToScreenSaverAssets).some(
            (value) =>
                value.type === MediaType.Video &&
                value.assets.length === 0 &&
                value.selected
        );

    useEffect(() => {
        onAssetClick(
            getDefaultAssetPreviewDetails(screenSaverContentValue.type)
        );

        setHasError(evaluateErrorState(zoneToScreenSaverAssets));
    }, [screenSaverContentValue.assets, zoneToScreenSaverAssets]);

    const updateScreenSaverContent = (
        value: MediaType | string[],
        field: string
    ) => {
        let tempScreenSaverContentValue = { ...screenSaverContentValue };
        const tempZoneToScreenSaverAssets = cloneDeep(zoneToScreenSaverAssets);
        let zoneToScreenSaverAssetsWIthSelected = {};

        if (field === 'type') {
            if (zoneToScreenSaverAssets[zoneId + value])
                tempScreenSaverContentValue =
                    zoneToScreenSaverAssets[zoneId + value];
            else {
                const payload = {
                    type: value as MediaType,
                    assets: []
                };

                tempScreenSaverContentValue = { ...payload };
                zoneToScreenSaverAssetsWIthSelected = markSelected(
                    zoneId,
                    value as MediaType,
                    payload
                );
            }
        } else if (field === 'assets') {
            const payload = {
                type: screenSaverContentValue.type,
                assets: value as string[]
            };

            tempScreenSaverContentValue = { ...payload };
            zoneToScreenSaverAssetsWIthSelected = markSelected(
                zoneId,
                screenSaverContentValue.type,
                payload
            );
        }

        setHasError(evaluateErrorState(tempZoneToScreenSaverAssets));
        setZoneToScreenSaverAssets(zoneToScreenSaverAssetsWIthSelected);
        onScreenSaverFieldUpdate(tempScreenSaverContentValue);
    };

    const markSelected = (
        zoneId: string,
        type: MediaType,
        payload: ScreenSaverContentValue
    ) => {
        const tempZoneToScreenSaverAssets = cloneDeep(zoneToScreenSaverAssets);
        if (type === MediaType.Image) {
            if (tempZoneToScreenSaverAssets[zoneId + MediaType.Video]) {
                tempZoneToScreenSaverAssets[zoneId + MediaType.Video].selected =
                    false;
            }
            tempZoneToScreenSaverAssets[zoneId + MediaType.Image] = {
                ...payload,
                selected: true
            };
        } else {
            if (tempZoneToScreenSaverAssets[zoneId + MediaType.Image]) {
                tempZoneToScreenSaverAssets[zoneId + MediaType.Image].selected =
                    false;
            }
            tempZoneToScreenSaverAssets[zoneId + MediaType.Video] = {
                ...payload,
                selected: true
            };
        }
        return tempZoneToScreenSaverAssets;
    };

    const onAssetDelete = (assetId: string) => {
        const updatedAssetIds = screenSaverContentValue.assets.filter(
            (id) => id !== assetId
        );
        updateScreenSaverContent(updatedAssetIds, 'assets');
    };

    const isAssetSelectionAllowed = (() => {
        const maxAssets =
            screenSaverContentValue.type === MediaType.Image
                ? screenSaverContentConfig?.imageConfig?.maxAllowed ?? 5
                : screenSaverContentConfig?.videoConfig?.maxAllowed ?? 1;
        return screenSaverContentValue.assets.length < maxAssets;
    })();

    return (
        <>
            <Grid item xs={12}>
                <CMPTypography
                    sx={{
                        marginTop: pxToRem(16)
                    }}
                    text={screenSaverContentConfig?.screenSaverTypeConfig?.name}
                    variation={TypographyVariation.HEADER4}
                />
            </Grid>
            <Grid item xs={12}>
                <CMPRadioButtonGroup
                    options={
                        screenSaverContentConfig?.screenSaverTypeConfig!
                            .options as Options[]
                    }
                    value={screenSaverContentValue.type}
                    onChange={(value: MediaType): void => {
                        updateScreenSaverContent(value, 'type');
                    }}
                    orientation={Orientaton.HORIZONTAL}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ margin: `${pxToRem(16)} 0` }}>
                    <PropertyAssetSelector
                        label={
                            screenSaverContentConfig?.[
                                screenSaverContentValue.type === MediaType.Image
                                    ? 'imageConfig'
                                    : 'videoConfig'
                            ]?.name ?? 'Select'
                        }
                        tooltipMessage={
                            screenSaverContentConfig?.[
                                screenSaverContentValue.type === MediaType.Image
                                    ? 'imageConfig'
                                    : 'videoConfig'
                            ]?.toolTip ?? 'Select'
                        }
                        assets={filteredAssets}
                        mediaConfigs={
                            screenSaverContentValue.type === MediaType.Image
                                ? screenSaverContentConfig?.imageConfig
                                : screenSaverContentConfig?.videoConfig
                        }
                        error={
                            screenSaverContentValue.type === MediaType.Video &&
                            screenSaverContentValue.assets.length === 0
                        }
                        required
                        assetSelectionAllowed={isAssetSelectionAllowed}
                        onChange={(assetId: string): void =>
                            updateScreenSaverContent(
                                [...screenSaverContentValue.assets, assetId],
                                'assets'
                            )
                        }
                        selectedAssetId={''}
                        hidePreview
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ overflowY: 'hidden' }}>
                {screenSaverContentValue.type === MediaType.Image ? (
                    <SortableWidgetContainer
                        axis="xy"
                        useDragHandle
                        lockAxis="x"
                        onSortEnd={({ oldIndex, newIndex }) => {
                            const value = arrayMove(
                                screenSaverContentValue.assets,
                                oldIndex,
                                newIndex
                            );
                            updateScreenSaverContent(value, 'assets');
                        }}
                    >
                        {screenSaverContentValue.assets.map(
                            (assetId, index) => (
                                <SortableWidgetItem
                                    disabled={false}
                                    key={assetId}
                                    enableSort={true}
                                    index={index}
                                    sx={{
                                        marginRight: pxToRem(10),
                                        position: 'relative'
                                    }}
                                >
                                    <AssetMiniPreview
                                        assetType={screenSaverContentValue.type}
                                        onAssetClick={onAssetClick}
                                        assetUrl={
                                            assets.find(
                                                (asset) => asset.id === assetId
                                            )?.url
                                        }
                                        onDeleteClick={() =>
                                            onAssetDelete(assetId)
                                        }
                                    />
                                </SortableWidgetItem>
                            )
                        )}
                        {drawRemainingEmptyBoxes(
                            (screenSaverContentConfig?.imageConfig
                                ?.maxAllowed ?? 5) -
                                screenSaverContentValue.assets.length,
                            screenSaverContentValue.type
                        )}
                    </SortableWidgetContainer>
                ) : (
                    <AssetMiniPreview
                        assetType={screenSaverContentValue.type}
                        assetUrl={
                            assets.find(
                                (asset) =>
                                    asset.id ===
                                    screenSaverContentValue.assets[0]
                            )?.url
                        }
                        onDeleteClick={() =>
                            onAssetDelete(screenSaverContentValue.assets[0])
                        }
                        onAssetClick={onAssetClick}
                    />
                )}
            </Grid>
        </>
    );
};

export default ScreenSaverSelector;
