import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import Button, { ButtonSize, ButtonVariation } from './button';
import cmpTexts from '../locale/en';
import CMPTypography from './textFields/cmpTypography';
import CMPColors from './colors';

const SaveBarTexts = {
    cancel: 'Cancel',
    save: 'Save'
};

const Container = styled(Card)(() => ({
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    width: '100%',
    backgroundColor: CMPColors.light.background.main,
    height: 100,
    zIndex: 100,
    bottom: 0
}));

const ButtonContainer = styled(Box)(() => ({
    marginRight: pxToRem(80),
    justifySelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
}));

interface Props {
    handleSave: () => void;
    handleCancel: () => void;
    enableSaveButton: boolean;
}

function StickySaveBar(props: Props) {
    const { handleSave, handleCancel, enableSaveButton } = props;

    return (
        <Container elevation={16}>
            {!enableSaveButton && (
                <CMPTypography
                    sx={{
                        marginLeft: pxToRem(40),
                        color: CMPColors.light.text.textField.error,
                        justifySelf: 'flex-start'
                    }}
                >
                    {cmpTexts.SaveBarTexts.error}
                </CMPTypography>
            )}
            <ButtonContainer>
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    size={ButtonSize.LARGE}
                    onClick={handleCancel}
                    sx={{ width: '8vw' }}
                >
                    {SaveBarTexts.cancel}
                </Button>

                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    onClick={handleSave}
                    disabled={!enableSaveButton}
                    sx={{ width: '11vw' }}
                >
                    {SaveBarTexts.save}
                </Button>
            </ButtonContainer>
        </Container>
    );
}

export default StickySaveBar;
