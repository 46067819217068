import { Box, Grid, IconButton, styled } from '@mui/material';
import { get, isEmpty, isEqual, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AmazonLogo } from '../../../../assets/svg/amazonLogo.svg';
import { ReactComponent as AndroidLogo } from '../../../../assets/svg/androidLogo.svg';
import { ReactComponent as BrowserLogo } from '../../../../assets/svg/browserLogo.svg';
import { ReactComponent as LgLogo } from '../../../../assets/svg/lgLogo.svg';
import { ReactComponent as RokuLogo } from '../../../../assets/svg/RokuLogo.svg';
import { ReactComponent as ScreenSaverLogo } from '../../../../assets/svg/screenSaverLogo.svg';
import CMPColors from '../../../../components/colors';
import IconStylizer from '../../../../components/iconStylizer';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import WarningMessage from '../../../../components/warningMessage';
import cmpTexts from '../../../../locale/en';
import {
    PlatformSection,
    PropertyField,
    PropertyFieldValue
} from '../../../../redux/propertyValue/model';
import {
    evaluateMessagingCondition,
    evaluateValueCondition
} from '../../../../utils/propertiesUtil';
import { pxToRem } from '../../../../utils/stylesUtils';
import { ZoneToScreenSaverAssets } from '../manageZones';
import ScreenSaver from '../screenSaver';
import PlatformPageCard from './platformPageCard';
import { OpenInNewOutlined } from '@mui/icons-material';

const PlatformTab = styled(IconButton)(() => ({
    width: pxToRem(86),
    height: pxToRem(68),
    borderRadius: pxToRem(7),
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(7),
    marginBottom: pxToRem(16),
    '& .cmp_typography': {
        color: CMPColors.dark.text.primary
    },
    '&.selected': {
        background: CMPColors.light.background.button.secondary,
        '.cmp_typography': {
            color: CMPColors.brandColor
        }
    }
}));

const DEVICE_SUPPORTED_CONFIG_DOC_URL =
    'https://d35uwyuwpya61k.cloudfront.net/docs/device_supported_configuration.pdf';
export interface PlatformSpecificWarningMessage {
    [platform: string]: string;
}
interface PlatformPanelProps {
    platformSection: PlatformSection;
    propertyId: string;
    screenSaverSection?: PropertyField;
    updateSubmitPayload: (
        zoneId: string,
        pfId: string,
        key: string,
        value: any,
        isManualUpdate: boolean,
        parentKey?: string
    ) => void;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    setShowSaveBar: (showSaveBar: boolean) => void;
    zoneId: string;
    autoAffectedFieldIds: string[];
    platformSpecificWarningMessages?: PlatformSpecificWarningMessage;
    setWarningMessage?: (
        zoneId: string,
        platform: string,
        message: string
    ) => void;
    setHasError: (hasError: boolean) => void;
    zoneToScreenSaverAssets: ZoneToScreenSaverAssets;
    setZoneToScreenSaverAssets: (value: ZoneToScreenSaverAssets) => void;
    propertyLogoAssetId: string;
}

const PlatformPanel = (props: PlatformPanelProps) => {
    const {
        platformSection,
        propertyId,
        setShowSaveBar,
        updateSubmitPayload,
        shouldResetForm,
        setShouldResetForm,
        zoneId,
        autoAffectedFieldIds,
        platformSpecificWarningMessages,
        setWarningMessage,
        screenSaverSection,
        setHasError,
        zoneToScreenSaverAssets,
        setZoneToScreenSaverAssets,
        propertyLogoAssetId
    } = props;

    const platformNames = Object.keys(platformSection).filter(
        (name: string) => !isEmpty(platformSection[name])
    );

    const [selectedPlatform, setSelectedPlatform] = useState(platformNames[0]);
    const [openScreenSaverTab, setOpenScreenSaverTab] = useState(false);
    const [manuallyUpdatedChildField, setManuallyUpdatedChildField] =
        useState<PropertyField>();

    const onPlatformClick = (val: string) => {
        setOpenScreenSaverTab(false);
        setSelectedPlatform(val);
    };

    useEffect(() => {
        if (shouldResetForm) {
            setShouldResetForm(!shouldResetForm);
        }
    }, [shouldResetForm]);

    const getPlatformLogo = (platform: string) => {
        switch (platform) {
            case 'Android':
                return <AndroidLogo />;
            case 'FireTV':
                return <AmazonLogo />;
            case 'LG':
                return <LgLogo />;
            case 'Roku':
                return <RokuLogo />;
            default:
                return <BrowserLogo />;
        }
    };

    const platformPagesFieldIndex = platformSection[selectedPlatform].findIndex(
        (pf) => pf.key === 'pages'
    );

    const allPages =
        platformSection[selectedPlatform]?.[platformPagesFieldIndex]?.children;

    const showDeviceSupportedConfigSection = [
        'browser',
        'lg',
        'roku',
        'firetv'
    ].includes(selectedPlatform.toLowerCase());

    useEffect(() => {
        allPages?.forEach((pageField, pageIndex) => {
            pageField.children?.forEach((childField, childIndex) => {
                const evaluatedValue = evaluateValueCondition(
                    childField,
                    allPages
                );

                if (
                    !isNull(evaluatedValue) &&
                    !isEqual(evaluatedValue, childField.value)
                ) {
                    onPageChildFieldUpdate(
                        pageIndex,
                        pageField,
                        childIndex,
                        evaluatedValue,
                        false
                    );
                }
            });
        });
    }, [allPages]);

    useEffect(() => {
        if (manuallyUpdatedChildField) {
            const message =
                evaluateMessagingCondition(
                    manuallyUpdatedChildField,
                    allPages
                ) ?? '';

            setWarningMessage?.(zoneId, selectedPlatform, message);
            setManuallyUpdatedChildField(undefined);
        }
    }, [allPages, manuallyUpdatedChildField]);

    const onPageChildFieldUpdate = (
        pageIndex: number,
        pageField: PropertyField,
        childIndex: number,
        value: PropertyFieldValue,
        manual: boolean
    ) => {
        const fieldKey = `[${selectedPlatform}][${platformPagesFieldIndex}].children[${pageIndex}].children[${childIndex}]`;
        const fieldId = get(pageField.children, childIndex + '.id', '');

        updateSubmitPayload(zoneId, fieldId, fieldKey, value, manual);
        setShowSaveBar(true);

        const childField = pageField.children?.[childIndex];
        if (manual && childField) setManuallyUpdatedChildField(childField);
    };

    const onScreenSaverFieldUpdate = (
        index: number,
        value: PropertyFieldValue,
        propertyFieldId: string
    ) => {
        const fieldKey = `children[${index}]`;
        updateSubmitPayload(
            zoneId,
            propertyFieldId,
            fieldKey,
            value,
            true,
            'screen_saver'
        );
        setShowSaveBar(true);
    };

    return (
        <Box display="flex">
            <Box
                sx={{
                    background: CMPColors.dark.background.accordion.secondary,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: `${pxToRem(32)} ${pxToRem(8)}`,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: `${pxToRem(30)} 0 0 ${pxToRem(30)}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {platformNames.map((platformName) => (
                        <PlatformTab
                            key={platformName}
                            onClick={() => onPlatformClick(platformName)}
                            className={
                                selectedPlatform === platformName &&
                                !openScreenSaverTab
                                    ? 'selected'
                                    : ''
                            }
                        >
                            <IconStylizer
                                color={
                                    selectedPlatform === platformName &&
                                    !openScreenSaverTab
                                        ? CMPColors.light.icon.brand
                                        : CMPColors.light.icon.primary
                                }
                                backgroundColor={
                                    selectedPlatform === platformName &&
                                    !openScreenSaverTab
                                        ? CMPColors.light.icon.primary
                                        : CMPColors.light.icon.default
                                }
                            >
                                {getPlatformLogo(platformName)}
                            </IconStylizer>
                            <CMPTypography
                                sx={{
                                    lineHeight: pxToRem(19),
                                    letterSpacing: 0,
                                    margin: 0
                                }}
                                variation={TypographyVariation.TYPO_SCALE_14}
                                text={platformName}
                            />
                        </PlatformTab>
                    ))}
                </Box>
                {!isEmpty(screenSaverSection) &&
                    screenSaverSection?.access_details?.View && (
                        <PlatformTab
                            sx={{ gap: 0 }}
                            key="screenSaver"
                            onClick={() => setOpenScreenSaverTab(true)}
                            className={openScreenSaverTab ? 'selected' : ''}
                        >
                            <IconStylizer
                                color={
                                    openScreenSaverTab
                                        ? CMPColors.light.icon.brand
                                        : CMPColors.light.icon.primary
                                }
                                backgroundColor={
                                    openScreenSaverTab
                                        ? CMPColors.light.icon.brand
                                        : CMPColors.light.icon.default
                                }
                            >
                                <ScreenSaverLogo />
                            </IconStylizer>
                            <CMPTypography
                                sx={{
                                    letterSpacing: 0,
                                    margin: 0
                                }}
                                variation={TypographyVariation.TYPO_SCALE_14}
                                text={
                                    cmpTexts.screenSaver.screenSaverDisplayName
                                }
                            />
                        </PlatformTab>
                    )}
            </Box>
            <Box
                sx={{
                    background: CMPColors.light.background.modal,
                    borderRadius: `0 ${pxToRem(30)}  ${pxToRem(30)} 0`,
                    minHeight: pxToRem(800),
                    padding: pxToRem(10)
                }}
            >
                {!openScreenSaverTab ? (
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            padding: pxToRem(15),
                            paddingTop: pxToRem(20),
                            height: 'fit-content'
                        }}
                    >
                        <>
                            {showDeviceSupportedConfigSection && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginBottom: pxToRem(12),
                                        marginRight: pxToRem(30),
                                        display: 'flex',
                                        justifyContent: 'end'
                                    }}
                                >
                                    <Box
                                        component={'a'}
                                        href={DEVICE_SUPPORTED_CONFIG_DOC_URL}
                                        target="_blank"
                                        sx={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CMPTypography
                                            variation={
                                                TypographyVariation.TYPO_HYPERLINK_16
                                            }
                                            sx={{ marginRight: pxToRem(4) }}
                                        >
                                            {
                                                cmpTexts.platformPane
                                                    .supportedDeviceConfigTitle
                                            }
                                        </CMPTypography>
                                        <OpenInNewOutlined
                                            sx={{
                                                color: CMPColors.light.icon
                                                    .default
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {platformSpecificWarningMessages?.[
                                selectedPlatform
                            ] &&
                                autoAffectedFieldIds.some((id) =>
                                    id.startsWith(zoneId)
                                ) && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ margin: pxToRem(12) }}
                                    >
                                        <WarningMessage
                                            message={
                                                platformSpecificWarningMessages[
                                                    selectedPlatform
                                                ]
                                            }
                                        />
                                    </Grid>
                                )}

                            {platformPagesFieldIndex !== -1 &&
                                platformSection[selectedPlatform][
                                    platformPagesFieldIndex
                                ]?.children?.map((pageField, pageIndex) => {
                                    const canView =
                                        pageField.access_details?.View;
                                    if (!canView) return null;

                                    const zoneIdToFieldCombinations =
                                        pageField.children?.map(
                                            (c) => `${zoneId}:${c.id}`
                                        ) ?? [];

                                    const showWarningIcon =
                                        zoneIdToFieldCombinations.some((id) =>
                                            autoAffectedFieldIds.includes(id)
                                        );

                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            md={6}
                                            lg={4}
                                            xl={3}
                                            key={pageField.id}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: 'fit-content'
                                            }}
                                        >
                                            <PlatformPageCard
                                                platform={selectedPlatform}
                                                siblingPages={
                                                    platformSection[
                                                        selectedPlatform
                                                    ][platformPagesFieldIndex]
                                                        .children ?? []
                                                }
                                                onChildFieldUpdate={(
                                                    childIndex,
                                                    value
                                                ) =>
                                                    onPageChildFieldUpdate(
                                                        pageIndex,
                                                        pageField,
                                                        childIndex,
                                                        value,
                                                        true
                                                    )
                                                }
                                                propertyId={propertyId}
                                                pageField={pageField}
                                                showWarningIcon={
                                                    showWarningIcon
                                                }
                                            />
                                        </Grid>
                                    );
                                })}
                        </>
                    </Grid>
                ) : (
                    <Grid
                        container
                        sx={{
                            width: '100%',
                            padding: `${pxToRem(20)} ${pxToRem(50)}`,
                            height: 'fit-content',
                            overflow: 'hidden'
                        }}
                    >
                        <ScreenSaver
                            screenSaverData={
                                screenSaverSection as PropertyField
                            }
                            setHasError={setHasError}
                            onScreenSaverFieldUpdate={onScreenSaverFieldUpdate}
                            zoneToScreenSaverAssets={zoneToScreenSaverAssets}
                            setZoneToScreenSaverAssets={
                                setZoneToScreenSaverAssets
                            }
                            zoneId={zoneId}
                            propertyLogoAssetId={propertyLogoAssetId}
                        />
                    </Grid>
                )}
            </Box>
        </Box>
    );
};

export default PlatformPanel;
