import { Box, Dialog } from '@mui/material';
import React from 'react';
import { CloseIconComponent } from '../../components/closeIconComponent';
import { Asset } from '../../redux/assets/model';
import { pxToRem } from '../../utils/stylesUtils';
import CMPColors from '../../components/colors';

interface ViewAssetModalProps {
    asset: Asset;
    onClose?: () => void;
}

const ViewAssetModal = (props: ViewAssetModalProps): React.ReactElement => {
    return (
        <Dialog
            open={true}
            onClose={() => {
                props.onClose?.();
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: CMPColors.light.background.main
                }}
            >
                <CloseIconComponent onClick={() => props.onClose?.()} />

                <h3>{props.asset.name}</h3>

                {props.asset.type === 'IMAGE' ? (
                    <img
                        style={{
                            backgroundColor:
                                CMPColors.light.background.imageBackground,
                            width: '100%',
                            height: '85%',
                            padding: pxToRem(10)
                        }}
                        alt={''}
                        src={props.asset.url}
                    />
                ) : (
                    <video
                        src={props.asset.url}
                        autoPlay
                        loop
                        muted
                        style={{ width: '100%', height: '90%' }}
                    />
                )}
            </Box>
        </Dialog>
    );
};

export default ViewAssetModal;
