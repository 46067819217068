import styled from '@emotion/styled';
import { Box, Grid, SxProps } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../utils/stylesUtils';
import CMPColors from '../colors';

export interface ValidationTextsProps {
    sx?: SxProps;
    options: { name: string; validation: Validation }[];
    columns?: 1 | 2 | 3;
    show?: boolean;
}

export enum Validation {
    ERROR = 'ERROR',
    PASS = 'PASS',
    NONE = 'NONE'
}

const StyledGrid = styled(Grid)(() => ({
    '& li': {
        padding: `${pxToRem(8)} 0`,
        fontWeight: 500,
        fontSize: pxToRem(12),
        lineHeight: pxToRem(17)
    },
    '&.grid-class-error': {
        '& li': {
            '&::marker': {
                color: CMPColors.light.text.textField.error
            },
            color: CMPColors.light.text.textField.error
        }
    },
    '&.grid-class-pass': {
        '& li': {
            '&::marker': {
                color: CMPColors.light.text.textField.success
            },
            color: CMPColors.light.text.textField.success
        }
    },
    '&.grid-class-none': {
        '& li': {
            '&::marker': {
                color: CMPColors.light.text.tertiary
            },
            color: CMPColors.light.text.tertiary
        }
    }
}));

const ValidationTexts = (props: ValidationTextsProps) => {
    const { sx, columns = 2, options, show = false } = props;
    const convertedGridValue = 12 / columns;
    return (
        <Box sx={{ ...sx, display: show ? 'block' : 'none' }}>
            <ul style={{ paddingLeft: pxToRem(20) }}>
                <Grid container>
                    {options.map((m, i) => {
                        return (
                            <StyledGrid
                                key={i}
                                item
                                xs={convertedGridValue}
                                className={`grid-class-${m.validation.toLowerCase()}`}
                            >
                                <li>{m.name}</li>
                            </StyledGrid>
                        );
                    })}
                </Grid>
            </ul>
        </Box>
    );
};

export default ValidationTexts;
