import { IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { pxToRem, StylesDictionary } from '../../utils/stylesUtils';
import { RailHeadingConatinerProps } from './elementsInterface';
import CMPColors from '../../components/colors';
import { ReactComponent as DeleteIcon } from '../../assets/svg/trashBin.svg';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import IconStylizer from '../../components/iconStylizer';

const styles: StylesDictionary = {
    customLinkStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: CMPColors.light.text.hyperlink,
        textDecorationColor: CMPColors.light.text.hyperlink,
        marginLeft: 0.8,
        marginTop: 2
    }
};

export const LinkItemList = styled('div')(() => ({
    display: 'flex',
    color: CMPColors.light.text.hyperlink,
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold',
    marginLeft: '0.6vw',
    marginTop: 6
}));

export const TitleRail = styled('div')(() => ({
    flex: 2
}));

export const WidgetTypeName = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
}));

export const DeleteButtonContainer = styled('div')(() => ({
    display: 'flex'
}));

export const DeleteIconDiv = styled('div')(() => ({
    marginTop: 8
}));

const RailHeadingConatiner = (props: RailHeadingConatinerProps) => {
    const {
        itemDataName,
        itemDataId,
        itemDataWidgetTypeName,
        showRemove,
        handleElementRedirectClick,
        handleRemoveItem
    } = props;

    return (
        <LinkItemList>
            <TitleRail>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleElementRedirectClick}
                    underline="none"
                    sx={styles.customLinkStyle}
                >
                    {itemDataName}
                </Link>
            </TitleRail>
            {showRemove && (
                <DeleteButtonContainer>
                    <WidgetTypeName>
                        <CMPTypography
                            sx={{
                                marginLeft: pxToRem(10),
                                marginTop: pxToRem(15)
                            }}
                            variation={TypographyVariation.TYPO_SCALE_18}
                        >
                            {itemDataWidgetTypeName}
                        </CMPTypography>
                    </WidgetTypeName>
                    <DeleteIconDiv>
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleRemoveItem(itemDataId)}
                        >
                            <IconStylizer
                                sx={{
                                    marginLeft: 8
                                }}
                                color={CMPColors.light.icon.default}
                            >
                                <DeleteIcon />
                            </IconStylizer>
                        </IconButton>
                    </DeleteIconDiv>
                </DeleteButtonContainer>
            )}
        </LinkItemList>
    );
};

export default RailHeadingConatiner;
