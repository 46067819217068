import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { isEmpty } from 'lodash';
import { SxProps } from '@mui/material';
import Tooltip from '../../../../components/tooltip';

interface Props {
    tooltipMessage?: string;
    sx?: SxProps;
}

const PropertySectionToolTip = ({ tooltipMessage, sx }: Props) => {
    if (isEmpty(tooltipMessage)) {
        return null;
    }

    return (
        <Tooltip message={tooltipMessage as string}>
            <HelpOutlineIcon
                sx={{
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    ...sx
                }}
                onClick={(e) => e.preventDefault()}
            />
        </Tooltip>
    );
};

export default PropertySectionToolTip;
