import { styled } from '@mui/material/styles';
import { includes } from 'lodash';
import React from 'react';
import { RailAlias } from '../../redux/element/model';
import { pxToRem } from '../../utils/stylesUtils';
import { SwimlaneProps } from './elementsInterface';
import CMPColors from '../../components/colors';

export const ListContainer = styled('div')(() => ({
    marginBottom: 20,
    backgroundColor: '#ebf6f9',
    border: `${pxToRem(2)} solid #AED9E6`,
    borderLeft: `${pxToRem(2)} solid #AED9E6`,
    borderRadius: 2,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
}));

const SwimlaneWidgetLargeContainer = styled('div')(() => ({
    marginTop: 5,
    backgroundColor: 'rgba(23,23,37,0.16)',
    borderRadius: 2,
    marginRight: 20,
    width: 240,
    height: 145,
    border: `${pxToRem(2)} solid rgba(23,23,37,0.26)`
}));

export const SwimlaneWidgetContainer = styled('div')(() => ({
    height: 85,
    width: 231,
    border: `${pxToRem(2)} solid rgba(23,23,37,0.26)`,
    marginTop: 5,
    backgroundColor: 'rgba(23,23,37,0.16)',
    borderRadius: 2,
    marginRight: 16
}));

export const LinkPageItemList = styled('div')(() => ({
    color: CMPColors.light.text.secondary,
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: '1vw',
    marginRight: 65,
    width: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const SwimlaneWidgetItemList = styled('div')(() => ({
    width: 160,
    color: CMPColors.light.text.secondary,
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 11,
    marginTop: 12,
    marginRight: 65,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const ItemPageList = styled('div')(() => ({
    color: '#181818',
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 12,
    marginTop: 4,
    paddingRight: 2,
    width: 145,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const WidgetContainer = styled('div')(() => ({
    marginLeft: 8
}));

export const WidgetNameContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `${pxToRem(2)} solid #AED9E6`,
    marginLeft: 3
}));

export const WidgetLargeIconUrl = styled('img')(() => ({
    width: 42,
    height: 62
}));

export const WidgetIconUrl = styled('img')(() => ({
    width: 33.33,
    height: 33.33
}));

export const Container = styled('div')(() => ({
    display: 'flex'
}));

export const SwimlaneWidgetContainerdiv = styled('div')(() => ({
    display: 'flex'
}));

export const PlayCircleIconDiv = styled('div')(() => ({
    flex: 1,
    marginLeft: 16,
    marginTop: 34
}));

export const PlayCircleIconSwimlaneWidgetDiv = styled('div')(() => ({
    flex: 1,
    marginLeft: 15,
    marginTop: 24
}));

export const WidgetNamediv = styled('div')(() => ({
    flex: 4,
    marginTop: 42,
    whiteSpace: 'nowrap'
}));

export const SwimlaneWidgetNamediv = styled('div')(() => ({
    flex: 4,
    whiteSpace: 'nowrap'
}));

export default function SwimlaneRail(props: SwimlaneProps) {
    const { railDataArray, itemDataAlias, widgets, allowedTypeIds } = props;

    // Setting the rail minHeight to properly style the empty rails
    const minRailHeight = includes(
        [RailAlias.WidgetRail_350x284],
        itemDataAlias
    )
        ? 145
        : 85;

    return (
        <Container style={{ minHeight: minRailHeight }}>
            {/* @ts-ignore */}
            {railDataArray.map((widgetId: string, index: number) => {
                const widget = widgets.find((w) => w.id === widgetId);
                if (widget && includes(allowedTypeIds, widget.widget_type_id)) {
                    if (includes([RailAlias.WidgetRail_350x284], itemDataAlias))
                        return (
                            <SwimlaneWidgetLargeContainer key={index + ''}>
                                <SwimlaneWidgetContainerdiv>
                                    <PlayCircleIconDiv>
                                        <WidgetLargeIconUrl
                                            src={widget.icon_url}
                                        />
                                    </PlayCircleIconDiv>
                                    <WidgetNamediv>
                                        <LinkPageItemList>
                                            {widget.name}
                                        </LinkPageItemList>

                                        <ItemPageList>
                                            {widget.widget_type_name}
                                        </ItemPageList>
                                    </WidgetNamediv>
                                </SwimlaneWidgetContainerdiv>
                            </SwimlaneWidgetLargeContainer>
                        );
                    else
                        return (
                            <SwimlaneWidgetContainer key={index + ''}>
                                <SwimlaneWidgetContainerdiv>
                                    <PlayCircleIconSwimlaneWidgetDiv>
                                        <WidgetIconUrl src={widget.icon_url} />
                                    </PlayCircleIconSwimlaneWidgetDiv>
                                    <SwimlaneWidgetNamediv>
                                        <SwimlaneWidgetItemList>
                                            {widget.name}
                                        </SwimlaneWidgetItemList>

                                        <ItemPageList>
                                            {widget.widget_type_name}
                                        </ItemPageList>
                                    </SwimlaneWidgetNamediv>
                                </SwimlaneWidgetContainerdiv>
                            </SwimlaneWidgetContainer>
                        );
                }
                return null;
            })}
        </Container>
    );
}
