export interface PropertyType {
    name: string;
    display_name: string;
}
export interface GenericObj {
    [key: string]: any;
}

export interface AddGroupHelperPayload {
    groupName: string;
    organization_id: string;
}

interface UserAttachements {
    consultants: PropertyUser[] | null;
    integrators: PropertyUser[] | null;
    property_managers: PropertyUser[] | null;
}

interface PropertyUser {
    id: string;
    name: string;
}

export interface PropertyGroupInterface {
    property_group_id: string;
    property_group_name: string;
    org_id: string;
    properties: PropertyDetails[];
}

export interface PropertyDetails {
    id: string;
    name: string;
    description: string;
    property_group_id: string;
    smartbox_id: string;
    chassis_no: string;
    status: string;
    modified_by: string;
    modified_at: Date;
    attached_users: UserAttachements;
    type: string;
    zip_code: string | undefined;
    evolve2_enabled: boolean;
    property_type_display_name: string;
    is_part_of_dish_business_organization: boolean;
    can_migrate: boolean;
    can_reverse_migrate: boolean;
}

export const emptyPropertyDetails: PropertyDetails = {
    id: '',
    name: '',
    description: '',
    property_group_id: '',
    smartbox_id: '',
    chassis_no: '',
    status: '',
    modified_by: '',
    modified_at: new Date(),
    type: '',
    zip_code: undefined,
    evolve2_enabled: false,
    property_type_display_name: '',
    attached_users: {
        property_managers: null,
        integrators: null,
        consultants: null
    },
    is_part_of_dish_business_organization: false,
    can_migrate: false,
    can_reverse_migrate: false
};
