import { FormControlLabel, styled } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Box } from '@mui/system';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPColors from '../../../../components/colors';

const LiveSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(() => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 1.3,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(17px)',
            color: CMPColors.light.background.switch.primary,
            '& + .MuiSwitch-track': {
                backgroundColor: CMPColors.light.background.switch.secondary,
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: CMPColors.light.background.switch.secondary,
            border: `6px solid ${CMPColors.light.background.switch.primary}`
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        backgroundColor: CMPColors.light.background.switch.default,
        opacity: 1
    }
}));

interface LiveToggleProps {
    handleChange: (ChangeEvent: any) => void;
    checked: boolean;
    label: string;
}
const LiveToggle = (props: LiveToggleProps) => {
    const { checked, handleChange, label } = props;

    const StatusDot = styled(Box)(() => ({
        height: pxToRem(10),
        width: pxToRem(10),
        borderRadius: '50%',
        backgroundColor: checked
            ? CMPColors.light.background.switch.tertiary
            : CMPColors.light.background.switch.default,
        margin: `0 ${pxToRem(5)}`
    }));

    return (
        <FormControlLabel
            onChange={handleChange}
            checked={checked}
            control={<LiveSwitch />}
            sx={{ marginRight: 0 }}
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        fontSize: pxToRem(14)
                    }}
                >
                    <StatusDot />
                    {label}
                </Box>
            }
        />
    );
};

export default LiveToggle;
