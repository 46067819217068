import { PlayCircleOutline } from '@mui/icons-material';
import { Box, LinearProgress, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/svg/trashBin.svg';
import { DisableableBox } from '../../components/disableableBox';
import IconStylizer from '../../components/iconStylizer';
import cmpTexts from '../../locale/en';
import { AssetStatus } from '../../redux/assets/actionPayload';
import { pxToRem } from '../../utils/stylesUtils';
import { AssetDetails } from './uploadAssetModal';
import CMPTextField from '../../components/textFields/cmpTextField';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const VideoOverlay = styled(PlayCircleOutline)(() => ({
    position: 'absolute',
    left: 120,
    width: pxToRem(65),
    height: '100%',
    opacity: '40%',
    color: CMPColors.dark.text.primary,
    zIndex: 1
}));

const AssetDetailsContainer = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    background:
        CMPColors[isDarkMode ? 'dark' : 'light'].background
            .uploadAssetDetailsContainer,
    display: 'flex',
    width: '100%',
    height: '266px',
    maxWidth: pxToRem(306),
    borderRadius: pxToRem(8),
    padding: pxToRem(16),
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center'
}));

const MultiAssetDetailsContainer = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    background: CMPColors[isDarkMode ? 'dark' : 'light'].background.main,
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: pxToRem(8),
    padding: pxToRem(8),
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'start',
    overflowX: 'hidden',
    overflowY: 'scroll'
}));

const AssetNameContainer = styled(Box)(() => ({
    display: 'flex',
    gap: pxToRem(8),
    width: '100%'
}));

const MultiDeleteIcon = styled(DeleteIcon)(() => ({
    cursor: 'pointer',
    flexShrink: 0,
    marginRight: pxToRem(16)
}));

const SingleDeleteIcon = styled(DeleteIcon)(() => ({
    cursor: 'pointer',
    position: 'absolute',
    top: pxToRem(24),
    right: pxToRem(24),
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 16, 1))'
}));

const AssetUploadProgressBar = (props: {
    percent: number;
    status: AssetStatus;
}): React.ReactElement => {
    const { percent, status } = props;

    return (
        <Box
            sx={{
                width: '95%',
                display: 'flex',
                height: pxToRem(40),
                marginTop: pxToRem(20),
                flexDirection: 'column'
            }}
        >
            <LinearProgress
                variant="determinate"
                value={percent}
                sx={{
                    borderRadius: pxToRem(2),
                    '&.MuiLinearProgress-colorPrimary': {
                        background:
                            CMPColors.light.background.progressBar.primary
                    },
                    '& .MuiLinearProgress-barColorPrimary': {
                        background:
                            percent === 100
                                ? status === AssetStatus.SUCCESS
                                    ? CMPColors.light.background.progressBar
                                          .success
                                    : CMPColors.light.background.progressBar
                                          .error
                                : CMPColors.light.background.progressBar.default
                    }
                }}
            />
            <CMPTypography
                sx={{ alignSelf: 'center', marginTop: pxToRem(10) }}
                text={
                    percent === 100
                        ? status === AssetStatus.SUCCESS
                            ? 'Successfully Uploaded'
                            : 'Failed'
                        : 'Uploading...'
                }
            />
        </Box>
    );
};

const GetPreviewImage = (props: {
    file: File;
    isImage: boolean;
    previewElementStyle?: any;
    videoOverlayStyle?: any;
}) => {
    const {
        file,
        isImage,
        previewElementStyle = {},
        videoOverlayStyle = {}
    } = props;
    const objectUrl = useMemo(() => URL.createObjectURL(file), [file]);

    return isImage ? (
        <img alt={''} src={objectUrl} style={previewElementStyle} />
    ) : (
        <Box sx={{ ...previewElementStyle, position: 'relative' }}>
            <video src={`${objectUrl}#t=5`} style={previewElementStyle} />
            <VideoOverlay style={videoOverlayStyle} />
        </Box>
    );
};

interface PreviewAssetsProps {
    allowedFileTypes: string[];
    assets: AssetDetails[];
    assetUploadInProgress: boolean;
    isMultiFileUpload: boolean;
    isDarkMode: boolean;
    onDeleteAssetClick: (id: string) => void;
    onChangeAssetName: (id: string, name: string) => void;
    getAssetError: (id: string) => string;
}

const PreviewAssets = (props: PreviewAssetsProps): React.ReactElement => {
    const {
        allowedFileTypes,
        assets,
        assetUploadInProgress,
        isMultiFileUpload,
        isDarkMode,
        onDeleteAssetClick,
        onChangeAssetName,
        getAssetError
    } = props;

    if (!isMultiFileUpload && assets) {
        const asset = assets[0];
        const file = asset.file;
        const canPreviewFile =
            file.type.startsWith('image') || file.type.startsWith('video');

        if (!canPreviewFile) return <></>;

        const isImage = file.type.startsWith('image');

        const previewElementStyle = {
            maxWidth: pxToRem(306),
            maxHeight: pxToRem(190)
        };
        const error = getAssetError(asset.id);

        return (
            <AssetDetailsContainer isDarkMode={isDarkMode}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        background: CMPColors.dark.background.imageBackground,
                        height: pxToRem(190)
                    }}
                >
                    <GetPreviewImage
                        isImage={isImage}
                        previewElementStyle={previewElementStyle}
                        file={file}
                    />
                </Box>
                {assetUploadInProgress ? (
                    <AssetUploadProgressBar
                        percent={asset.uploadPercent}
                        status={asset.status}
                    />
                ) : (
                    <AssetNameContainer>
                        <CMPTextField
                            maxLength={50}
                            label={`${cmpTexts.assets.name}`}
                            required
                            onChange={(value) =>
                                onChangeAssetName(asset.id, value)
                            }
                            value={asset.name}
                            error={Boolean(error)}
                            helperText={
                                asset.status === AssetStatus.FAILED
                                    ? cmpTexts.assets.uploadRetryMessage
                                    : error
                            }
                            rowHeight={46}
                            disabled={
                                assetUploadInProgress ||
                                !allowedFileTypes.includes(file.type)
                            }
                            sx={{ paddingTop: pxToRem(20) }}
                        />

                        <CMPTypography
                            sx={{ paddingTop: pxToRem(28) }}
                            text={`.${file.name.split('.').pop()}`}
                        />
                        <DisableableBox disabled={assetUploadInProgress}>
                            <SingleDeleteIcon
                                onClick={() => {
                                    onDeleteAssetClick(asset.id);
                                }}
                            />
                        </DisableableBox>
                    </AssetNameContainer>
                )}
            </AssetDetailsContainer>
        );
    }

    return (
        <MultiAssetDetailsContainer isDarkMode={isDarkMode}>
            {assets.map((asset) => {
                const file = asset.file;
                const canPreviewFile =
                    file.type.startsWith('image') ||
                    file.type.startsWith('video');

                const isImage = file.type.startsWith('image');
                if (!canPreviewFile) return <></>;

                const previewElementStyle = {
                    maxWidth: pxToRem(120),
                    maxHeight: pxToRem(100)
                };

                const error = getAssetError(asset.id);
                const videoOverlayStyle = {
                    left: 27
                };

                return (
                    <Box
                        key={asset.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            gap: pxToRem(8)
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: pxToRem(120),
                                background:
                                    CMPColors.dark.background.imageBackground,
                                height: pxToRem(100),
                                margin: pxToRem(8),
                                borderRadius: pxToRem(7),
                                flexShrink: 0,
                                overflow: 'hidden'
                            }}
                        >
                            <GetPreviewImage
                                file={file}
                                isImage={isImage}
                                previewElementStyle={previewElementStyle}
                                videoOverlayStyle={videoOverlayStyle}
                            />
                        </Box>
                        {assetUploadInProgress ? (
                            <AssetUploadProgressBar
                                percent={asset.uploadPercent}
                                status={asset.status}
                            />
                        ) : (
                            <AssetNameContainer>
                                <CMPTextField
                                    label={`${cmpTexts.assets.name}`}
                                    required
                                    onChange={(value) =>
                                        onChangeAssetName(asset.id, value)
                                    }
                                    value={asset.name}
                                    error={Boolean(error)}
                                    helperText={
                                        asset.status === AssetStatus.FAILED
                                            ? cmpTexts.assets.uploadRetryMessage
                                            : error
                                    }
                                    disabled={
                                        assetUploadInProgress ||
                                        !allowedFileTypes.includes(asset.type)
                                    }
                                    sx={{
                                        flex: 1
                                    }}
                                />

                                <CMPTypography
                                    variation={
                                        TypographyVariation.TYPO_SCALE_16
                                    }
                                    sx={{
                                        paddingTop: pxToRem(30),
                                        color: CMPColors.light.text.tertiary
                                    }}
                                    text={`.${file.name.split('.').pop()}`}
                                />
                            </AssetNameContainer>
                        )}
                        <DisableableBox disabled={assetUploadInProgress}>
                            <IconStylizer color={CMPColors.light.icon.default}>
                                <MultiDeleteIcon
                                    onClick={() => onDeleteAssetClick(asset.id)}
                                />
                            </IconStylizer>
                        </DisableableBox>
                    </Box>
                );
            })}
        </MultiAssetDetailsContainer>
    );
};

export default PreviewAssets;
