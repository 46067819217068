import { Button, ButtonProps, SxProps } from '@mui/material';
import React, { CSSProperties } from 'react';
import { pxToRem } from '../utils/stylesUtils';
import CMPColors from './colors';
import { capitalize } from 'lodash';

export const enum ButtonVariation {
    TEXT = 'text',
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
    OUTLINED_BLACK = 'outlined_black'
}

export const enum ButtonSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    XSMALL = 'xsmall'
}

interface CustomButtonProps extends ButtonProps {
    buttonVariant?: ButtonVariation;
    buttonSize?: ButtonSize;
    children: string;
}

function CutomButton(props: CustomButtonProps) {
    const {
        children,
        buttonVariant = ButtonVariation.TEXT,
        disabled = false,
        buttonSize = ButtonSize.LARGE,
        sx,
        ...rest
    } = props;

    let mWidth = pxToRem(180);
    let height = pxToRem(44);
    let fontSize = pxToRem(16);

    switch (buttonSize) {
        case ButtonSize.LARGE:
            mWidth = pxToRem(180);
            break;
        case ButtonSize.MEDIUM:
            mWidth = pxToRem(160);
            break;
        case ButtonSize.SMALL:
            mWidth = pxToRem(120);
            break;
        case ButtonSize.XSMALL:
            mWidth = pxToRem(64);
            height = pxToRem(28);
            fontSize = pxToRem(12);
            break;
    }

    let style: CSSProperties & SxProps = {
        minWidth: mWidth,
        height,
        textTransform: 'capitalize',
        fontSize
    };

    switch (buttonVariant) {
        case ButtonVariation.TEXT:
            style = {
                ...style,
                color: CMPColors.light.text.button.secondary
            };
            break;

        case ButtonVariation.CONTAINED:
            style = {
                ...style,
                backgroundColor: CMPColors.light.background.button.primary,
                color: CMPColors.light.text.button.primary,
                fontWeight: `bold`,
                borderRadius: pxToRem(7)
            };
            break;

        case ButtonVariation.OUTLINED:
            style = {
                ...style,
                color: CMPColors.light.text.button.primary,
                border: `${pxToRem(2)} solid ${
                    CMPColors.dark.stroke.tertiary_btn
                }`,
                borderRadius: pxToRem(7),
                fontWeight: 800
            };
            break;

        case ButtonVariation.OUTLINED_BLACK:
            style = {
                ...style,
                color: CMPColors.light.text.button.tertiary,
                border: `${pxToRem(2)} solid ${
                    CMPColors.light.stroke.tertiary_btn
                }`,
                borderRadius: pxToRem(7),
                fontWeight: `bold`
            };
            break;
    }

    if (disabled) {
        switch (buttonVariant) {
            case ButtonVariation.TEXT:
                style = {
                    ...style,
                    opacity: 0.5
                };
                break;

            case ButtonVariation.CONTAINED:
                style = {
                    ...style,
                    opacity: 0.5,
                    boxShadow: `0 2px 8px 0 rgba(82,82,89,0.6)`
                };
                break;

            case ButtonVariation.OUTLINED_BLACK:
                style = {
                    ...style,
                    opacity: 0.5
                };
        }
    }

    //@ts-ignore
    style = {
        ...style,
        ...sx
    };

    return (
        <Button
            {...rest}
            disabled={disabled}
            style={style}
            variant={buttonVariant}
        >
            {capitalize(children.toLowerCase())}
        </Button>
    );
}

export default CutomButton;
