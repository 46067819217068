import React from 'react';
import { useDispatch } from 'react-redux';
import Button, {
    ButtonVariation,
    ButtonSize
} from '../../../../components/button';
import { CloseIconComponent } from '../../../../components/closeIconComponent';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent
} from '../../../../components/cmpDialog';
import { useUrlParams } from '../../../../hooks/useParam';
import cmpTexts from '../../../../locale/en';
import { deleteZone } from '../../../../redux/zones/action';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';

const DeleteZoneOrEventModal = (props: {
    setIsModalOpen: (isAddCommentModalOpen: boolean) => void;
    description: string;
    heading: string;
    zoneId: string;
}) => {
    const { setIsModalOpen, description, heading, zoneId } = props;

    const { property } = useUrlParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <CMPDialog onClose={handleClose}>
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                id="modal-modal-title"
                text={heading}
            />
            <CloseIconComponent onClick={handleClose} />
            <CMPDialogContent>
                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_18}
                    sx={{ marginBottom: pxToRem(20) }}
                    text={description}
                />
                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_18}
                    text={cmpTexts.zones.deleteSubheading}
                />
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => setIsModalOpen(false)}
                >
                    No, Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => {
                        dispatch(deleteZone({ propertyId: property, zoneId }));
                        setIsModalOpen(false);
                    }}
                >
                    Yes, Delete
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default DeleteZoneOrEventModal;
