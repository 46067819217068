import { Box, SxProps } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { ReactComponent as InfoIcon } from '../assets/svg/info.svg';
import { pxToRem } from '../utils/stylesUtils';
import IconStylizer from './iconStylizer';
import { Theme } from './textFields/cmpTextField';
import CMPTypography, { TypographyVariation } from './textFields/cmpTypography';
import CMPColors from './colors';

const CMPErrorHelperText = ({
    text,
    sx,
    theme = Theme.LIGHT
}: {
    text: string | undefined;
    sx?: SxProps;
    theme?: Theme;
}) => {
    if (isEmpty(text)) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: pxToRem(5),
                marginTop: pxToRem(8),
                ...sx
            }}
        >
            <IconStylizer
                color={
                    CMPColors[theme === Theme.LIGHT ? 'light' : 'dark'].icon
                        .error
                }
                height={pxToRem(15)}
                width={pxToRem(16)}
                sx={{ display: 'flex' }}
            >
                <InfoIcon />
            </IconStylizer>

            <CMPTypography
                sx={{
                    color: CMPColors[theme === Theme.LIGHT ? 'light' : 'dark']
                        .text.textField.error
                }}
                text={text ?? ''}
                variation={TypographyVariation.TYPO_SCALE_12}
            />
        </Box>
    );
};

export default CMPErrorHelperText;
