/* 
  refrence link: https://www.figma.com/design/AuQvgsPcBY1Mj3TtHhjKIU/CMP-Canvas-Library?node-id=3815-20984&t=H1uElPtiMNzi7weu-0
 */
const CMPColors = {
    brandColor: '#F01446',
    brandColorNegative: '#171725',
    loader: '#0400002e',
    liveChannelPlay: '#384AFF',
    light: {
        background: {
            main: '#F5F5F6',
            modal: '#FFFFFF',
            imageBackground: '#C4C4CA80',
            smartboxAlert: '#CE4C00',
            uploadAssetDetailsContainer: '#F5F5F6',
            scrollbar: {
                scrollbar_meter_pill: '#C4C4CA',
                primary: '#FFFFFF'
            },
            accordion: {
                primary: '#292938',
                secondary: '#C4C4CA'
            },
            button: {
                primary: '#F01446',
                secondary: '#FFFFFF',
                tertiary: '#FFFFFF'
            },
            navbar: {
                default: '#171725',
                active: '#171725'
            },
            separator: '#C4C4CA',
            orgFilter: '#00678C',
            textField: '#07070B1F',
            markdownCode: '#F1F1F1',
            chip: {
                user: '#612655',
                organization: '#ADA0A5',
                general: '#026574',
                propertyGroup: '#0C8E7D',
                property: '#ABD9C5',
                onstreamDesign: '#BA5139',
                evolveM: '#F6BD6A',
                page: '#00678C',
                rail: '#7BAEC1',
                tool: '#FAE5AC',
                asset: '#C4C4CA',
                success: '#00873F',
                failure: '#931621',
                default: '#525261',
                propertyConfig: '#BA5139'
            },
            manageDevicesCard: {
                primary: '#EEEEEE',
                secondary: '#E0E0E0'
            },
            tabs: {
                primary: '#FFFFFF',
                secondary: '#525261',
                tertiary: '#171725'
            },
            switch: {
                primary: '#FFFFFF',
                secondary: '#00873F',
                tertiary: '#F01446',
                default: '#C4C4CA'
            },
            progressBar: {
                primary: '#FFFFFF',
                success: '#00873F',
                error: '#F4B4B9',
                default: '#171725'
            },
            dropdown: {
                primary: '#FFFFFF',
                focused: '#DCDCDF',
                selected: '#E1E1E5'
            }
        },
        text: {
            primary: '#000010', // headers and breadcrumbs
            secondary: '#292938', // paragraphs
            tertiary: '#525261',
            hyperlink: '#00678C',
            textField: {
                error: '#931621',
                success: '#00873F',
                default: '#525261',
                active: '#46237A',
                disabled: '#C4C4CA',
                placeholder: '#171725'
            },
            button: {
                primary: '#FFFFFF',
                secondary: '#171725',
                tertiary: '#171725',
                textWithIcon: '#F01446'
            },
            navbar: {
                default: '#FFFFFF',
                active: '#F01446'
            },
            alert: '#FFFFFF',
            accordion: '#FFFFFF',
            chip: {
                primary: '#171725',
                secondary: '#FFFFFF'
            },
            dropdown: {
                primary: '#171725',
                secondary: '#525261',
                focused: '#525261',
                error: '#F4B4B9'
            }
        },
        icon: {
            primary: '#FFFFFF',
            secondary: '#171725',
            error: '#931621',
            default: '#525261',
            hover: '#292938',
            brand: '#F01446',
            alert: '#FFFFFF',
            white: '#FFFFFF',
            black: '#171725'
        },
        stroke: {
            tertiary_btn: '#171725',
            error: '#931621',
            default: '#525261',
            hover: '#292938',
            complete: '#171725',
            active: '#46237A',
            disabled: '#C4C4CA',
            success: '#00873F',
            markdownCode: '#E1E1E1'
        },
        toast: {
            info: '#0057F5',
            error: '#931621',
            success: '#00873F',
            warning: '#EAB700'
        }
    },
    dark: {
        background: {
            main: '#292938',
            modal: '#171725',
            imageBackground: '#52526180',
            lightBg: '#525261',
            adhocBg: '#000010',
            leftPanel: '#151524',
            hero300x166: '#120F0F',
            uploadAssetDetailsContainer: '#F5F5F6',
            scrollbar: {
                scrollbar_meter_pill: '#C4C4CA',
                primary: '#FFFFFF'
            },
            accordion: {
                primary: '#FFFFFF',
                secondary: '#525261'
            },
            button: {
                primary: '#F01446',
                secondary: '#FFFFFF',
                tertiary: '#07070B1F'
            },
            tabs: {
                primary: '#FFFFFF',
                secondary: '#525261'
            },
            navbar: {
                default: '#171725',
                active: '#171725'
            },
            separator: '#525261',
            orgFilter: '#AECCF9',
            textField: '#171725',
            chip: {
                user: '#612655',
                organization: '#ADA0A5',
                general: '#026574',
                propertyGroup: '#0C8E7D',
                property: '#ABD9C5',
                onstreamDesign: '#BA5139',
                evolveM: '#F6BD6A',
                page: '#00678C',
                rail: '#7BAEC1',
                tool: '#FAE5AC',
                success: '#D5F4CD',
                failure: '#F4B4B9'
            }
        },
        text: {
            primary: '#FFFFFF', // headers and breadcrumbs
            secondary: '#F5F5F6', // paragraphs
            tertiary: '#C4C4CA',
            hyperlink: '#A6E7FF',
            textField: {
                error: '#F4B4B9',
                success: '#D5F4CD',
                default: '#C4C4CA',
                active: '#C9B4E9',
                disabled: '#525261',
                placeholder: '#C4C4CA'
            },
            button: {
                primary: '#FFFFFF',
                secondary: '#171725',
                tertiary: '#FFFFFF',
                textWithIcon: '#F01446'
            },
            navbar: {
                default: '#FFFFFF',
                active: '#F01446'
            },
            alert: '#171725',
            accordion: '#171725',
            chip: {
                primary: '#171725',
                secondary: '#FFFFFF'
            },
            dropdown: {
                primary: '#FFFFFF',
                focused: '#C4C4CA',
                selected: '#E1E1E5',
                error: '#931621'
            }
        },
        icon: {
            error: '#F4B4B9',
            default: '#C4C4CA',
            hover: '#F5F5F6',
            brand: '#F01446',
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
            alert: '#171725',
            white: '#FFFFFF',
            black: '#171725'
        },
        stroke: {
            tertiary_btn: '#FFFFFF',
            error: '#F4B4B9',
            default: '#C4C4CA',
            hover: '#F5F5F6',
            complete: '#FFFFFF',
            active: '#C8B4E9',
            disabled: '#525261',
            success: '#00873F'
        },
        toast: {
            info: '#AECCF9',
            error: '#F4B4B9',
            success: '#D5F4CD',
            earning: '#FAE397'
        }
    }
};
export default CMPColors;
