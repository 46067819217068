import WarningIcon from '@mui/icons-material/ReportProblem';
import { Box } from '@mui/material';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import CMPTypography from './textFields/cmpTypography';
import CMPColors from './colors';

interface WarningMessageProps {
    message: string;
}

const WarningMessage = (props: WarningMessageProps): React.ReactElement => {
    return (
        <Box
            sx={{
                background: CMPColors.light.toast.warning,
                padding: pxToRem(16),
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <WarningIcon />
            <CMPTypography
                sx={{
                    lineHeight: pxToRem(24),
                    marginLeft: pxToRem(8)
                }}
                text={props.message}
            />
        </Box>
    );
};

export default WarningMessage;
