import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface PropertySectionInfoTextProps {
    description: string;
    size: 'small' | 'normal';
}

const PropertySectionInfoText = (props: PropertySectionInfoTextProps) => {
    const { description, size = 'normal' } = props;
    return (
        <CMPTypography
            sx={{
                color: CMPColors.light.text.primary,
                padding: `${pxToRem(12)} 0 0`
            }}
            variation={
                size === 'small'
                    ? TypographyVariation.TYPO_SCALE_14
                    : TypographyVariation.TYPO_SCALE_18
            }
            text={description}
        />
    );
};

export default PropertySectionInfoText;
