import { Delete } from '@mui/icons-material';
import { Box, ListSubheader, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import {
    DEFAULT_ALLOWED_IMAGE_FILE_TYPES,
    DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE
} from '../../../../components/fileDropZone';
import { useSnackbar } from '../../../../components/snackbar';
import Tooltip from '../../../../components/tooltip';
import { Asset } from '../../../../redux/assets/model';
import {
    selectAssetOperationLoadingState,
    selectMessage
} from '../../../../redux/assets/reducer';
import { LoadingStatus } from '../../../../redux/common/state';
import { MediaConfigType, MediaType } from '../../../../redux/element/model';
import { search } from '../../../../utils/searchUtil';
import { dataSizeConverter } from '../../../../utils/sizeConverter';
import { pxToRem } from '../../../../utils/stylesUtils';
import UploadAssetModal from '../../../assets/uploadAssetModal';
import CMPDropDown from '../../../../components/cmpDropDown';
import { ReactComponent as Upload } from '../../../../assets/svg/upload.svg';
import { ReactComponent as VideoPlayOverlay } from '../../../../assets/svg/videoPlayOverlay.svg';
import IconStylizer from '../../../../components/iconStylizer';
import CMPTextField, {
    TextVariation
} from '../../../../components/textFields/cmpTextField';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(20),
    justifyContent: 'left',
    flexWrap: 'wrap',
    flexGrow: 'unset'
}));

const LogoContainer = styled(Box)(() => ({
    width: pxToRem(200),
    height: pxToRem(100),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: pxToRem(4),
    background: 'rgba(170, 170, 170, 0.20)',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: pxToRem(0.4),
    position: 'relative',
    padding: `${pxToRem(10)} ${pxToRem(10)}`,
    marginLeft: pxToRem(10)
}));

const MenuItemContainer = styled(Box)(() => ({
    textAlign: 'center',
    width: '94%'
}));

const StyledDeleteIcon = styled(Delete)(() => ({
    position: 'absolute',
    right: 3,
    top: 3,
    fontSize: pxToRem(18),
    color: CMPColors.light.text.primary,
    cursor: 'pointer'
}));

const AssetPreviewContainer = styled(Box)(() => ({
    width: pxToRem(200),
    height: pxToRem(120),
    padding: `${pxToRem(2)} ${pxToRem(2)}`,
    borderRadius: pxToRem(20),
    background: 'rgba(170, 170, 170, 0.20)',
    display: 'flex',
    justifyContent: 'center'
}));

const Video = styled('video')(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: pxToRem(20)
}));

const Image = styled('img')(() => ({
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: pxToRem(20)
}));

interface PropertyAssetSelectorProps {
    assets: Asset[];
    onChange: (assetId: string) => void;
    selectedAssetId: string;
    assetSelectionAllowed?: boolean;
    mediaConfigs?: MediaConfigType;
    label: string;
    hidePreview?: boolean;
    tooltipMessage?: string;
    error?: boolean;
    required?: boolean;
}

const PropertyAssetSelector = (props: PropertyAssetSelectorProps) => {
    const {
        assets,
        onChange,
        selectedAssetId,
        assetSelectionAllowed = false,
        mediaConfigs,
        label,
        hidePreview = false,
        tooltipMessage,
        error,
        required
    } = props;

    const { showSnackbar } = useSnackbar();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const selectedAsset = assets.find((asset) => asset.id === selectedAssetId);
    const displayedAssets = searchText
        ? assets.filter((asset) => search(asset.name, searchText))
        : assets;
    const message = useSelector(selectMessage);
    const uploadLoader = useSelector(selectAssetOperationLoadingState);

    useEffect(() => {
        if (uploadLoader === LoadingStatus.DONE) {
            showSnackbar(message);
        }
    }, [uploadLoader]);

    return (
        <Container>
            {!hidePreview && (
                <Box>
                    <LogoContainer>
                        {isEmpty(selectedAsset) ? (
                            <CMPTypography
                                variation={TypographyVariation.TYPO_SCALE_12}
                                text="No Logo Uploaded"
                            />
                        ) : (
                            <>
                                <Tooltip
                                    message={dataSizeConverter(
                                        selectedAsset?.size as number
                                    )}
                                >
                                    <img
                                        src={selectedAsset?.url}
                                        alt={selectedAsset?.name}
                                        style={{
                                            width: pxToRem(150),
                                            height: pxToRem(80),
                                            borderRadius: pxToRem(4)
                                        }}
                                    />
                                </Tooltip>

                                {assetSelectionAllowed && (
                                    <StyledDeleteIcon
                                        onClick={() => onChange('')}
                                    />
                                )}
                            </>
                        )}
                    </LogoContainer>
                    {!isEmpty(selectedAsset) && (
                        <CMPTypography
                            variation={TypographyVariation.TYPO_SCALE_16}
                            sx={{
                                color: CMPColors.light.text.primary,
                                textAlign: 'center',
                                marginTop: pxToRem(5),
                                width: pxToRem(200),
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                            text={`${
                                selectedAsset?.name
                            }.${selectedAsset?.file_extension.toLowerCase()}`}
                        />
                    )}
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'flex-start',
                    gap: pxToRem(20),
                    flexWrap: 'wrap'
                }}
            >
                <CMPDropDown
                    label={label}
                    error={error}
                    required={required}
                    tooltipMessage={tooltipMessage}
                    placeholder="Select"
                    value=""
                    onChange={onChange}
                    dropdownStyles={{
                        maxHeight: 576,
                        width: pxToRem(520),
                        '&::-webkit-scrollbar': {
                            width: pxToRem(7),
                            backgroundColor:
                                CMPColors.light.background.scrollbar.primary
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '8px',
                            '-webkit-box-shadow':
                                'inset 0 0 4px rgba(0,0,0,.3)',
                            backgroundColor:
                                CMPColors.light.background.scrollbar
                                    .scrollbar_meter_pill
                        }
                    }}
                    disableAutoFocusItem
                    autoFocus
                    sx={{ width: pxToRem(520) }}
                    disabled={!assetSelectionAllowed}
                >
                    <ListSubheader>
                        <CMPTextField
                            variation={TextVariation.SEARCH}
                            enableDebouce
                            sx={{
                                paddingTop: pxToRem(12),
                                paddingBottom: pxToRem(14)
                            }}
                            placeholder="Search Assets"
                            autoFocus
                            onChange={setSearchText}
                            onKeyDown={(e: any) => {
                                if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                }
                            }}
                            value={searchText}
                        />
                    </ListSubheader>
                    {displayedAssets.map((asset) => {
                        return (
                            <MenuItem
                                key={asset.id}
                                value={asset.id}
                                sx={{
                                    display: 'inline-flex',
                                    width: '50%',
                                    marginBottom: pxToRem(10),
                                    justifyContent: 'center',
                                    ':hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <MenuItemContainer>
                                    <Tooltip
                                        message={dataSizeConverter(asset?.size)}
                                    >
                                        <AssetPreviewContainer>
                                            {asset.type.toLowerCase() ===
                                            MediaType.Video.toLowerCase() ? (
                                                <>
                                                    <Video
                                                        src={`${asset.url}#t=1`}
                                                    />
                                                    <VideoPlayOverlay
                                                        width={pxToRem(80)}
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '18%'
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <Image
                                                    alt={asset.name}
                                                    src={asset.url}
                                                />
                                            )}
                                        </AssetPreviewContainer>
                                    </Tooltip>
                                    <CMPTypography
                                        variation={
                                            TypographyVariation.TYPO_SCALE_16
                                        }
                                        sx={{
                                            color: CMPColors.light.text.primary,
                                            marginTop: pxToRem(5),
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                        text={`${
                                            asset.name
                                        }.${asset.file_extension.toLowerCase()}`}
                                    />
                                </MenuItemContainer>
                            </MenuItem>
                        );
                    })}
                </CMPDropDown>

                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    buttonSize={ButtonSize.MEDIUM}
                    startIcon={
                        <IconStylizer color={CMPColors.light.icon.secondary}>
                            <Upload />
                        </IconStylizer>
                    }
                    onClick={() => setShowUploadModal(true)}
                    disabled={!assetSelectionAllowed}
                    sx={{
                        lineHeight: 1,
                        fontWeight: 600,
                        marginTop: pxToRem(7)
                    }}
                >
                    Upload Asset
                </Button>
            </Box>
            {showUploadModal && (
                <UploadAssetModal
                    setIsModalOpen={setShowUploadModal}
                    onAssetUploaded={(asset: Asset) => {
                        onChange(asset.id);
                    }}
                    allowedFileTypes={
                        mediaConfigs?.allowedFileTypes ??
                        DEFAULT_ALLOWED_IMAGE_FILE_TYPES
                    }
                    assetUploadInfoMessage={
                        mediaConfigs?.fileUploadInfoMessage ??
                        DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE
                    }
                />
            )}
        </Container>
    );
};

export default PropertyAssetSelector;
