import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { cloneDeep, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import StyledPaper from '../../../components/styledPaper';
import cmpTexts from '../../../locale/en';
import {
    PropertyField,
    PropertyFieldValue,
    PropertyFieldValueType
} from '../../../redux/propertyValue/model';
import { propertyFieldsValidationSchema } from '../../../utils/propertiesUtil';
import { pxToRem } from '../../../utils/stylesUtils';
import PropertySectionComplex from './components/propertySectionComplex';
import PropertySectionFAQ from './components/propertySectionFAQ';
import CMPTypography, {
    TypographyVariation
} from '../../../components/textFields/cmpTypography';
import CMPColors from '../../../components/colors';

export const DividerLine = styled(Divider)(() => ({
    marginTop: pxToRem(20),
    marginBottom: pxToRem(20)
}));

interface PropertySectionProps {
    setShowSaveBar: (showSaveBar: boolean) => void;
    setHasError: (setHasError: boolean) => void;
    showSaveBar: boolean;
    propertyConfig: PropertyField[];
    updateSubmitPayload: (
        pfId: string,
        value: PropertyFieldValue,
        isManualUpdate: boolean
    ) => void;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    manuallyUpdatedFieldIds: string[];
}

function PropertySection(props: PropertySectionProps) {
    const {
        setShowSaveBar,
        showSaveBar,
        setHasError,
        propertyConfig,
        updateSubmitPayload,
        shouldResetForm,
        setShouldResetForm,
        manuallyUpdatedFieldIds
    } = props;

    const [faqError, setFaqError] = useState(false);

    const formik = useFormik<PropertyField[]>({
        initialValues: propertyConfig,
        enableReinitialize: true,
        validationSchema: propertyFieldsValidationSchema,
        onSubmit: (values) => {}
    });

    const getPropertyFieldError = (
        sectionIndex: number,
        childIndex: number
    ): string => {
        const errorMessage = get(
            formik.errors,
            `[${sectionIndex}].children[${childIndex}].value`,
            ''
        );
        return errorMessage;
    };

    const onComplexFieldUpdate = (
        indexDefault: number,
        index: number,
        value: PropertyFieldValue,
        isManualUpdate: boolean
    ) => {
        formik.setFieldValue(
            `[${indexDefault}].children[${index}].value`,
            value
        );
        const propertyFieldId = get(
            formik.values,
            `[${indexDefault}].children[${index}].id`,
            ''
        );

        updateSubmitPayload(propertyFieldId, value, isManualUpdate);
        setShowSaveBar(true);
    };

    useEffect(() => {
        if (shouldResetForm) {
            formik.resetForm();
            setShouldResetForm(!shouldResetForm);
        }
    }, [shouldResetForm]);

    useEffect(() => {
        setHasError(faqError || Object.keys(formik.errors).length > 0);
    }, [formik.errors, faqError]);

    const { values } = formik;

    return (
        <>
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                sx={{
                    color: CMPColors.light.text.primary,
                    marginBottom: pxToRem(25),
                    textAlign: 'start'
                }}
            >
                {cmpTexts.PropertySectionTexts.property}
            </CMPTypography>
            <StyledPaper>
                {values.map((pf: PropertyField, indexDefault: number) => {
                    const canView = pf.access_details?.View;

                    if (!canView) return null;

                    switch (pf.value_type) {
                        case PropertyFieldValueType.FAQ:
                            return (
                                <React.Fragment key={pf.id}>
                                    <PropertySectionFAQ
                                        onFieldUpdate={(value: any): void => {
                                            formik.setFieldValue(
                                                `[${indexDefault}].value`,
                                                value
                                            );
                                            const propertyFieldId = pf.id;
                                            updateSubmitPayload(
                                                propertyFieldId,
                                                value,
                                                true
                                            );
                                        }}
                                        setShowSaveBar={setShowSaveBar}
                                        fieldData={cloneDeep(pf)}
                                        showSaveBar={showSaveBar}
                                        setHasError={setFaqError}
                                    />
                                    <DividerLine />
                                </React.Fragment>
                            );
                        case PropertyFieldValueType.Complex:
                            return (
                                <React.Fragment key={pf.id}>
                                    <PropertySectionComplex
                                        section={pf}
                                        allSectionsFields={formik.values}
                                        onFieldUpdate={(
                                            index: number,
                                            value: any,
                                            isManualUpdate: boolean
                                        ): void =>
                                            onComplexFieldUpdate(
                                                indexDefault,
                                                index,
                                                value,
                                                isManualUpdate
                                            )
                                        }
                                        getPropertyFieldError={(
                                            index: number
                                        ) =>
                                            getPropertyFieldError(
                                                indexDefault,
                                                index
                                            )
                                        }
                                        manuallyUpdatedFieldIds={
                                            manuallyUpdatedFieldIds
                                        }
                                    />
                                    <DividerLine />
                                </React.Fragment>
                            );
                        default:
                            return null;
                    }
                })}
            </StyledPaper>
        </>
    );
}

export default PropertySection;
