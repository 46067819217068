import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '../../components/accordion';
import Button from '../../components/button';
import ConfirmationModal from '../../components/confirmationModal';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import {
    deleteElement,
    fetchAllWidgetTypes,
    purgeElementLive
} from '../../redux/elementsList/action';
import {
    ElementCategory,
    ElementsResponse,
    WidgetType
} from '../../redux/elementsList/model';
import {
    selectAllWidgetTypes,
    selectIsElementAttached,
    selectOpenElementDeleteModal
} from '../../redux/elementsList/reducer';
import { selectAddElementAllowed } from '../../redux/userOperations/reducer';
import { pxToRem } from '../../utils/stylesUtils';
import AddWidgetModal from './addNewElement';
import { ElementAccordinProps } from './elementsInterface';
import ElementsTable from './elementsTable';
import { WidgetAlias } from '../../redux/element/model';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const AddWidgetContainer = styled('div')(() => ({
    height: pxToRem(80),
    width: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `${pxToRem(1)} solid rgba(0, 0, 0, .125)`
}));

const ElementsAccordion = (props: ElementAccordinProps) => {
    const { elementsData } = props;

    const dispatch = useDispatch();
    const { property } = useUrlParams();
    const navigate = useNavigate();
    const isAddElementAllowed = useSelector(selectAddElementAllowed);

    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [expandFirstAccordion, setExpandFirstAccordion] = useState(true);
    const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedElement, setSelectedElement] = useState({
        id: '',
        type: ''
    });
    const [deleteModalTexts, setDeleteModalTexts] = useState({
        title: '',
        description: '',
        yesButton: '',
        noButton: ''
    });
    const allWidgetTypes = useSelector(selectAllWidgetTypes);
    const addableWidgetTypeList = allWidgetTypes.filter(
        (wt) =>
            wt.type === ElementCategory.WIDGET &&
            wt.access_details?.Add &&
            wt.alias !== WidgetAlias.WidgetLiveChannel
    );
    const addableRailTypeList = allWidgetTypes.filter(
        (wt) => wt.type === ElementCategory.RAIL && wt.access_details?.Add
    );
    const addablePageTypeList = allWidgetTypes.filter(
        (wt) => wt.type === ElementCategory.PAGE && wt.access_details?.Add
    );
    const openElementDeleteModal = useSelector(selectOpenElementDeleteModal);
    const isElementAttachedToDevice = useSelector(selectIsElementAttached);
    const [isDeleteAllowed, setDeleteAllowed] = useState(true);
    const [selectedElementCategory, setSelectedElementCategory] = useState(
        ElementCategory.WIDGET
    );

    useEffect(() => {
        dispatch(fetchAllWidgetTypes(property));
    }, []);

    const getWidgetTypeListBasedOnCategory = (
        elementCategory: ElementCategory
    ): WidgetType[] => {
        return elementCategory === ElementCategory.PAGE
            ? addablePageTypeList
            : elementCategory === ElementCategory.RAIL
            ? addableRailTypeList
            : addableWidgetTypeList;
    };

    useEffect(() => {
        if (openElementDeleteModal) {
            if (
                isElementAttachedToDevice &&
                selectedElement.type === ElementCategory.PAGE
            ) {
                setDeleteAllowed(false);
                setDeleteModalTexts({
                    title: cmpTexts.ElementsAccordiontext.deleteLivePage.title,
                    description:
                        cmpTexts.ElementsAccordiontext.deleteLivePage
                            .description,
                    yesButton:
                        cmpTexts.ElementsAccordiontext.deleteLivePage.yesButton,
                    noButton: ''
                });
            } else if (isElementAttachedToDevice) {
                setDeleteAllowed(true);
                setDeleteModalTexts({
                    title: `${
                        cmpTexts.ElementsAccordiontext.deleteLiveElement.title1
                    } ${selectedElement?.type || 'Element'} ${
                        cmpTexts.ElementsAccordiontext.deleteLiveElement.title2
                    }`,
                    description: `${
                        cmpTexts.ElementsAccordiontext.deleteLiveElement
                            .description1
                    } ${selectedElement?.type || 'Element'} ${
                        cmpTexts.ElementsAccordiontext.deleteLiveElement
                            .description2
                    }`,
                    yesButton: cmpTexts.ElementsAccordiontext.button[0],
                    noButton: cmpTexts.ElementsAccordiontext.button[1]
                });
            } else {
                setDeleteAllowed(true);
                setDeleteModalTexts({
                    title: cmpTexts.ElementsAccordiontext.elementModalHeading,
                    description:
                        cmpTexts.ElementsAccordiontext.elementModalBody,
                    yesButton: cmpTexts.ElementsAccordiontext.button[0],
                    noButton: cmpTexts.ElementsAccordiontext.button[1]
                });
            }
            setOpenDeleteModal(true);
            dispatch(purgeElementLive());
        }
    }, [
        openElementDeleteModal,
        dispatch,
        selectedElement,
        isElementAttachedToDevice
    ]);

    const handleChange =
        (panel: string, ind: number) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            console.log('element Accordion', panel, event, newExpanded);
            if (expanded[panel] !== undefined) {
                const exp: { [key: string]: boolean } = {};
                exp[panel] = !expanded[panel];
                setExpanded({ ...expanded, ...exp });
            } else {
                const exp: { [key: string]: boolean } = {};
                exp[panel] = ind === 0 ? false : true;
                setExpanded({ ...expanded, ...exp });
            }
            if (ind === 0) setExpandFirstAccordion(false);
        };

    const handleClick = (elementCategory: ElementCategory) => {
        setSelectedElementCategory(elementCategory);
        const elementTypeList =
            getWidgetTypeListBasedOnCategory(elementCategory);

        if (isEmpty(elementTypeList)) return;

        if (elementTypeList.length === 1) {
            navigate(`/properties/${property}/elements/create`, {
                state: {
                    widget: {
                        widgetType: elementTypeList[0],
                        type: 'PAGE'
                    },
                    origin: 'element'
                }
            });
        } else {
            setShowAddWidgetModal(true);
        }
    };

    const handleDeleteModal = () => setOpenDeleteModal(false);

    const handleOpenModal = (id: string, type: string) => {
        console.log('$$$ element id', id, ' element type', type);
        setSelectedElement({ id, type });
    };

    const yesClick = () => {
        if (isDeleteAllowed) {
            dispatch(
                deleteElement(
                    selectedElement.id,
                    selectedElement.type,
                    property
                )
            );
            handleDeleteModal();
        } else {
            handleDeleteModal();
        }
    };

    const canAddElement = (elementCategory: ElementCategory): boolean => {
        return (
            isAddElementAllowed &&
            !isEmpty(getWidgetTypeListBasedOnCategory(elementCategory))
        );
    };

    if (isNil(elementsData)) {
        return null;
    }

    return (
        <>
            {elementsData.map((row: ElementsResponse, ind: number) => (
                <Accordion
                    key={row.element_category_name}
                    expanded={
                        expanded[row.element_category_name] ||
                        (expandFirstAccordion && ind === 0)
                    }
                    onChange={handleChange(row.element_category_name, ind)}
                    style={{ width: '92%' }}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <CMPTypography variation={TypographyVariation.HEADER4}>
                            {row.display_name}
                        </CMPTypography>
                        <CMPTypography
                            variation={TypographyVariation.TYPO_SCALE_12}
                            sx={{
                                marginLeft: pxToRem(10),
                                display: 'flex',
                                alignItems: 'center',
                                fontStyle: 'italic'
                            }}
                        >
                            ({row.helper_text})
                        </CMPTypography>
                    </AccordionSummary>
                    {canAddElement(
                        row.element_category_name as ElementCategory
                    ) ? (
                        <AddWidgetContainer>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() =>
                                    handleClick(
                                        row.element_category_name as ElementCategory
                                    )
                                }
                                sx={{
                                    fontWeight: 'bold',
                                    color: CMPColors.light.text.button
                                        .textWithIcon
                                }}
                            >
                                {`CREATE NEW ${
                                    row.element_category_name ===
                                    ElementCategory.WIDGET
                                        ? 'TOOL'
                                        : row.element_category_name.toUpperCase()
                                }`}
                            </Button>
                        </AddWidgetContainer>
                    ) : !row.elements?.length ? (
                        <AddWidgetContainer>
                            {cmpTexts.ElementsAccordiontext.noWidget}
                        </AddWidgetContainer>
                    ) : null}
                    <AccordionDetails>
                        {row.elements?.length ? (
                            <ElementsTable
                                key={row.element_category_name}
                                elements={row.elements}
                                handleOpenModal={handleOpenModal}
                                elementType={
                                    row.element_category_name as ElementCategory
                                }
                            />
                        ) : null}
                    </AccordionDetails>
                    {showAddWidgetModal && (
                        <AddWidgetModal
                            setShowModal={setShowAddWidgetModal}
                            selectedElementCategory={selectedElementCategory}
                            widgetTypeList={getWidgetTypeListBasedOnCategory(
                                selectedElementCategory
                            )}
                        />
                    )}
                </Accordion>
            ))}

            {openDeleteModal && (
                <ConfirmationModal
                    title={deleteModalTexts?.title}
                    description={deleteModalTexts?.description}
                    onClose={handleDeleteModal}
                    onPositiveButtonClick={yesClick}
                    positiveButtonText={deleteModalTexts?.yesButton}
                    negativeButtonText={deleteModalTexts?.noButton}
                />
            )}
        </>
    );
};

export default ElementsAccordion;
