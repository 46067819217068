import React from 'react';
import Button, { ButtonSize, ButtonVariation } from './button';
import CMPDialog, { CMPDialogActions, CMPDialogContent } from './cmpDialog';
import { isEmpty } from 'lodash';
import CMPTypography, { TypographyVariation } from './textFields/cmpTypography';
import { pxToRem } from '../utils/stylesUtils';

interface Props {
    onClose: () => void;
    title?: string;
    description?: string;
    onPositiveButtonClick?: () => void;
    positiveButtonText?: string;
    negativeButtonText?: string;
}

const ConfirmationModal = (props: Props) => {
    const {
        title,
        description,
        onClose,
        positiveButtonText = 'Yes',
        negativeButtonText = 'No',
        onPositiveButtonClick: yesClick
    } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <CMPDialog maxWidth="sm" onClose={handleClose}>
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                sx={{ padding: 0, marginBottom: pxToRem(16) }}
                text={title}
            />
            <CMPDialogContent>{description}</CMPDialogContent>

            <CMPDialogActions>
                {!isEmpty(negativeButtonText) && (
                    <Button
                        buttonVariant={ButtonVariation.OUTLINED_BLACK}
                        onClick={handleClose}
                        buttonSize={ButtonSize.LARGE}
                    >
                        {negativeButtonText}
                    </Button>
                )}
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.LARGE}
                    onClick={yesClick}
                >
                    {positiveButtonText}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default ConfirmationModal;
