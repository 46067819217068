import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useClearCache } from 'react-clear-cache';
import ClearBin from '../../assets/svg/clearBin.svg';
import Button, { ButtonVariation } from '../../components/button';
import CMPTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const Container = styled('div')(() => ({
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: CMPColors.light.background.main,
    minHeight: '100vh'
}));

const ContainerGrid = styled(Grid)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
}));

const ImageContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'flex-end',
    alignItems: 'center'
}));

const RightContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: '30vh',
    alignItems: 'flex-start'
}));

export default function ErrorBoundaryFallback() {
    const { emptyCacheStorage } = useClearCache();

    const onClearCacheClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        emptyCacheStorage();
        localStorage.clear();
        window.location.reload();
    };
    return (
        <Container>
            <ImageContainer>
                <img src={ClearBin} alt="Error" />
            </ImageContainer>
            <RightContainer>
                <Grid>
                    <ContainerGrid item xs={10}>
                        <CMPTypography
                            variation={TypographyVariation.HEADER2}
                            sx={{ marginBottom: pxToRem(20) }}
                        >
                            {CMPTexts.errorBoundaryFallback.title}
                        </CMPTypography>
                        <CMPTypography
                            variation={TypographyVariation.TYPO_SCALE_14}
                            sx={{
                                textAlign: 'start',
                                marginTop: '1vh',
                                marginBottom: '3vh'
                            }}
                        >
                            {CMPTexts.errorBoundaryFallback.description}
                        </CMPTypography>
                        <Button
                            buttonVariant={ButtonVariation.CONTAINED}
                            onClick={onClearCacheClick}
                        >
                            {CMPTexts.errorBoundaryFallback.buttonText}
                        </Button>
                    </ContainerGrid>
                </Grid>
            </RightContainer>
        </Container>
    );
}
