// @ts-nocheck
import { createTheme } from '@mui/material';
import CMPColors from '../components/colors';

export const theme = createTheme({
    typography: {
        fontFamily: '"Proxima Nova", Helvetica, sans-serif'
    },
    palette: {
        primary: {
            main: CMPColors.brandColor
        },
        secondary: {
            main: CMPColors.brandColorNegative,
            light: CMPColors.light.background.main
        },
        companyBlack: {
            main: CMPColors.brandColorNegative
        }
    }
});
