import AddIcon from '@mui/icons-material/Add';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { useFormik } from 'formik';
import { set } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonVariation } from '../../../components/button';
import FullScreenCircularLoader from '../../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../../components/snackbar';
import { LoadingStatus } from '../../../redux/common/state';
import { getPropertyDetails } from '../../../redux/propertylist/action';
import { selectAddZoneAllowed } from '../../../redux/userOperations/reducer';
import {
    getAllZones,
    resetZonesData,
    resetZonesOperationLoader
} from '../../../redux/zones/action';
import {
    EventZone,
    StandardZone,
    VisibilityType,
    ZoneFields,
    ZoneType
} from '../../../redux/zones/model';
import {
    selectAllZones,
    selectMessage,
    selectZoneLoader,
    selectZoneOperationLoadingState
} from '../../../redux/zones/reducer';
import { pxToRem } from '../../../utils/stylesUtils';
import PlatformPanel from './components/platformPanel';
import ZonesAndEventsOperationModal from './components/zonesAndEventsOperationModal';
import ZonesAndEventsTab, {
    ZoneSpecificWarningMessages
} from './zonesAndEventsTab';
import { ScreenSaverContentValue } from '../../../redux/propertyValue/model';
import CMPTypography, {
    TypographyStyles
} from '../../../components/textFields/cmpTypography';
import CMPColors from '../../../components/colors';

interface TabPanelProps {
    children?: React.ReactNode;
    tabValue: ZoneType;
    value: ZoneType;
}

export enum Mode {
    Create = 'create',
    Edit = 'edit',
    View = 'view'
}

interface ManageZoneProps {
    propertyId: string;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    setShowSaveBar: (showSaveBar: boolean) => void;
    onUpdateZoneFields: (zoneFields: ZoneFields) => void;
    setHasError: (hasError: boolean) => void;
    propertyLogoAssetId: string;
}

export interface ZoneToScreenSaverAssets {
    [zoneId: string]: ExtendedScreenSaverContentValue;
}

interface ExtendedScreenSaverContentValue extends ScreenSaverContentValue {
    selected: boolean;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, tabValue, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== tabValue}
            id={`simple-tabpanel-${tabValue}`}
            {...other}
        >
            {value === tabValue && <CMPTypography>{children}</CMPTypography>}
        </Box>
    );
};

export const CustomTab = styled(Tab)(() => ({
    textTransform: 'capitalize',
    ...TypographyStyles.TYPO_SCALE_16
}));

const ManageZones = (props: ManageZoneProps) => {
    const dispatch = useDispatch();
    const {
        propertyId,
        onUpdateZoneFields,
        setShouldResetForm,
        shouldResetForm,
        setShowSaveBar,
        setHasError,
        propertyLogoAssetId
    } = props;

    const { showSnackbar } = useSnackbar();
    const emptyZone: StandardZone = {
        id: '',
        property_id: propertyId,
        description: '',
        visibility_type: VisibilityType.Private,
        type: ZoneType.Standard,
        rooms_attached: [],
        name: ''
    };

    const emptyEvent: EventZone = {
        id: '',
        property_id: propertyId,
        description: '',
        visibility_type: VisibilityType.Private,
        type: ZoneType.Event,
        rooms_attached: [],
        name: '',
        start_time: moment().valueOf(),
        end_time: moment().add(1, 'day').valueOf()
    };

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [zoneType, setZoneType] = useState(ZoneType.Default);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const [autoAffectedPageFieldIds, setAutoAffectedPageFieldIds] = useState<
        string[]
    >([]);
    const [updatedZoneFileds, setUpdatedZoneFields] = useState<ZoneFields>({});
    const [zoneSpecificWarningMessages, setZoneSpecificWarningMessages] =
        useState<ZoneSpecificWarningMessages>({});
    const [zoneToScreenSaverAssets, setZoneToScreenSaverAssets] =
        useState<ZoneToScreenSaverAssets>({});

    const operationLoader = useSelector(selectZoneOperationLoadingState);
    const loader = useSelector(selectZoneLoader);
    const message = useSelector(selectMessage);
    const canAddZone = useSelector(selectAddZoneAllowed);

    useEffect(() => {
        if (operationLoader === LoadingStatus.DONE) {
            dispatch(getPropertyDetails(propertyId));
            dispatch(resetZonesOperationLoader());
            setAutoAffectedPageFieldIds([]);
            setZoneSpecificWarningMessages({});
            showSnackbar(message);
            setZoneToScreenSaverAssets({});
            setUpdatedZoneFields({});
        } else if (operationLoader === LoadingStatus.FAILED) {
            dispatch(resetZonesOperationLoader());
            showSnackbar(message, 'error');
        }
        setShowSaveBar(false);
    }, [operationLoader]);

    useEffect(() => {
        dispatch(getAllZones(propertyId));

        return () => {
            dispatch(resetZonesData());
        };
    }, []);

    const zoneData = useSelector(selectAllZones);

    const formik = useFormik({
        initialValues: zoneData,
        enableReinitialize: true,
        onSubmit: (values) => {}
    });

    const zoneValues = formik.values;

    useEffect(() => {
        if (shouldResetForm) {
            formik.resetForm();
            setShouldResetForm(!shouldResetForm);
            setAutoAffectedPageFieldIds([]);
            setZoneSpecificWarningMessages({});
            setZoneToScreenSaverAssets({});
            setUpdatedZoneFields({});
        }
    }, [shouldResetForm]);

    const updateSubmitPayload = (
        zoneId: string,
        property_field_id: string,
        key: string,
        values: any,
        isManualUpdate: boolean,
        parentKey: string = 'platforms'
    ) => {
        setAutoAffectedPageFieldIds((ids) => {
            const affectedFieldIdWithZone = `${zoneId}:${property_field_id}`;

            return isManualUpdate
                ? ids.filter((id) => id !== affectedFieldIdWithZone)
                : [...ids, affectedFieldIdWithZone];
        });

        let keys = '';

        if (zoneType === ZoneType.Default) {
            keys = `[default][${parentKey}]${key}.value`;
        }
        if (zoneType === ZoneType.Event) {
            const i = formik.values.event.findIndex((e) => e.id === zoneId);
            keys = `[event][${i}][${parentKey}]${key}.value`;
        }
        if (zoneType === ZoneType.Standard) {
            const i = formik.values.standard.findIndex((e) => e.id === zoneId);
            keys = `[standard][${i}][${parentKey}]${key}.value`;
        }

        setUpdatedZoneFields((oldZoneFields) => {
            const fields = { ...oldZoneFields };
            if (zoneId && !fields[zoneId]) {
                fields[zoneId] = [];
            }

            const fieldToUpdateIndex = fields[zoneId].findIndex(
                (ele) => ele.property_field_id === property_field_id
            );
            if (fieldToUpdateIndex > -1) {
                fields[zoneId][fieldToUpdateIndex].values = values;
            } else {
                fields[zoneId] = [
                    ...fields[zoneId],
                    { property_field_id, values }
                ];
            }
            if (keys) formik.setFieldValue(keys, values);
            return fields;
        });
    };

    useEffect(() => {
        onUpdateZoneFields(updatedZoneFileds);
    }, [updatedZoneFileds]);

    const onSetWarningMessage = (
        zoneId: string,
        platform: string,
        message: string
    ) => {
        const updatedZoneSpecificWarningMessages = {
            ...zoneSpecificWarningMessages
        };
        set(
            updatedZoneSpecificWarningMessages,
            `${zoneId}.${platform}`,
            message
        );
        setZoneSpecificWarningMessages(updatedZoneSpecificWarningMessages);
    };

    const getButton = () => {
        if (zoneType === ZoneType.Default || !canAddZone) return null;

        return (
            <Button
                startIcon={<AddIcon />}
                sx={{
                    width: pxToRem(141),
                    ...TypographyStyles.HEADER6
                }}
                onClick={() => {
                    setIsOpenModal(true);
                }}
                buttonVariant={ButtonVariation.CONTAINED}
            >
                {zoneType === ZoneType.Standard ? 'Add Zone' : 'Add Event'}
            </Button>
        );
    };

    return (
        <Box>
            {(loader === LoadingStatus.INITIATED ||
                operationLoader === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: pxToRem(32)
                }}
            >
                <Tabs
                    onChange={(
                        event: React.SyntheticEvent,
                        value: ZoneType
                    ) => {
                        setZoneType(value);
                    }}
                    value={zoneType}
                    TabIndicatorProps={{
                        style: { display: 'none' }
                    }}
                    sx={{
                        border: `2px solid ${CMPColors.light.stroke.default}`,
                        maxWidth: 'fit-content',
                        borderRadius: pxToRem(4),
                        '&.MuiTabs-root': { minHeight: pxToRem(31) },
                        '& .MuiButtonBase-root.MuiTab-root': {
                            minWidth: pxToRem(76),
                            minHeight: pxToRem(31),
                            padding: `${pxToRem(4)} ${pxToRem(10)}`,
                            '&.Mui-selected': {
                                color: CMPColors.dark.text.primary,
                                background:
                                    CMPColors.light.background.tabs.secondary
                            },
                            borderRight: `2px solid ${CMPColors.light.stroke.default}`,
                            '&:last-child': {
                                borderRight: 'none'
                            }
                        },
                        marginTop: pxToRem(12)
                    }}
                >
                    <CustomTab label={'default'} value={ZoneType.Default} />
                    <CustomTab label={'zones'} value={ZoneType.Standard} />
                    <CustomTab label={'event'} value={ZoneType.Event} />
                </Tabs>
                {getButton()}
            </Box>
            <CustomTabPanel value={zoneType} tabValue={ZoneType.Default}>
                {zoneValues?.default?.platforms && (
                    <PlatformPanel
                        platformSection={zoneValues.default.platforms}
                        screenSaverSection={zoneValues.default.screen_saver}
                        propertyId={propertyId}
                        zoneId={zoneValues.default.id}
                        updateSubmitPayload={updateSubmitPayload}
                        setShowSaveBar={setShowSaveBar}
                        shouldResetForm={shouldResetForm}
                        setShouldResetForm={setShouldResetForm}
                        autoAffectedFieldIds={autoAffectedPageFieldIds}
                        platformSpecificWarningMessages={
                            zoneSpecificWarningMessages[zoneValues.default.id]
                        }
                        setWarningMessage={onSetWarningMessage}
                        setHasError={setHasError}
                        zoneToScreenSaverAssets={zoneToScreenSaverAssets}
                        setZoneToScreenSaverAssets={setZoneToScreenSaverAssets}
                        propertyLogoAssetId={propertyLogoAssetId}
                    />
                )}
            </CustomTabPanel>
            <CustomTabPanel value={zoneType} tabValue={ZoneType.Standard}>
                <ZonesAndEventsTab
                    propertyId={propertyId}
                    zoneData={zoneValues.standard}
                    selectedTab={zoneType}
                    updateSubmitPayload={updateSubmitPayload}
                    setShouldResetForm={setShouldResetForm}
                    shouldResetForm={shouldResetForm}
                    setShowSaveBar={setShowSaveBar}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    autoAffectedFieldIds={autoAffectedPageFieldIds}
                    warningMessages={zoneSpecificWarningMessages}
                    setWarningMessage={onSetWarningMessage}
                    setHasError={setHasError}
                    zoneToScreenSaverAssets={zoneToScreenSaverAssets}
                    setZoneToScreenSaverAssets={setZoneToScreenSaverAssets}
                    propertyLogoAssetId={propertyLogoAssetId}
                />
            </CustomTabPanel>
            <CustomTabPanel value={zoneType} tabValue={ZoneType.Event}>
                <ZonesAndEventsTab
                    propertyId={propertyId}
                    zoneData={zoneValues.event}
                    updateSubmitPayload={updateSubmitPayload}
                    selectedTab={zoneType}
                    setShouldResetForm={setShouldResetForm}
                    shouldResetForm={shouldResetForm}
                    setShowSaveBar={setShowSaveBar}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    autoAffectedFieldIds={autoAffectedPageFieldIds}
                    warningMessages={zoneSpecificWarningMessages}
                    setWarningMessage={onSetWarningMessage}
                    setHasError={setHasError}
                    zoneToScreenSaverAssets={zoneToScreenSaverAssets}
                    setZoneToScreenSaverAssets={setZoneToScreenSaverAssets}
                    propertyLogoAssetId={propertyLogoAssetId}
                />
            </CustomTabPanel>
            {isOpenModal && (
                <ZonesAndEventsOperationModal
                    setIsModalOpen={setIsOpenModal}
                    zoneType={zoneType}
                    actionType={Mode.Create}
                    zone={
                        zoneType === ZoneType.Standard ? emptyZone : emptyEvent
                    }
                />
            )}
        </Box>
    );
};

export default React.memo(ManageZones);
