import { useFormik } from 'formik';
import React, { useState } from 'react';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import {
    AttachedRoomInfo,
    EventZone,
    StandardZone,
    ZoneType
} from '../../../../redux/zones/model';
import { pxToRem } from '../../../../utils/stylesUtils';
import { Mode } from '../manageZones';
import { zoneValidationSchema } from '../zoneUtils';
import ZoneRoomSelectorSection from './zoneRoomSelectorSection';
import { CreateUpdateZone } from '../../../../redux/zones/actionPayload';
import { useDispatch } from 'react-redux';
import { editZone } from '../../../../redux/zones/action';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent
} from '../../../../components/cmpDialog';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

const ViewRoomsModal = (props: {
    setIsModalOpen: (isAddCommentModalOpen: boolean) => void;
    actionType: Mode;
    zone: StandardZone | EventZone;
}) => {
    const { setIsModalOpen, zone } = props;
    const dispatch = useDispatch();
    const [deviceIds, setDeviceIds] = useState<string[]>([]);
    const [isRoomOverlapping, setIsRoomOverlapping] = useState(false);
    const [isRoomsEdited, setIsRoomsEdited] = useState(false);

    const formik = useFormik({
        initialValues: zone,
        validationSchema: zoneValidationSchema([]),
        onSubmit: () => {}
    });

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const onSaveClick = () => {
        const createPayload: CreateUpdateZone = {
            rooms_attached: deviceIds,
            propertyId: formik.values.property_id,
            type: formik.values.type,
            visibility_type: formik.values.visibility_type,
            description: formik.values.description,
            name: formik.values.name,
            zoneId: formik.values.id
        };

        if (zone.type === ZoneType.Standard) {
            dispatch(editZone(createPayload));
        } else {
            createPayload.start_time = (formik.values as EventZone).start_time;
            createPayload.end_time = (formik.values as EventZone).end_time;
            dispatch(editZone(createPayload));
        }
    };

    return (
        <CMPDialog onClose={handleClose}>
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                sx={{ padding: 0, marginBottom: pxToRem(16) }}
                text="View Rooms"
            />
            <CMPDialogContent>
                <CMPTypography
                    sx={{
                        marginBottom: pxToRem(4)
                    }}
                    variation={TypographyVariation.HEADER4}
                    text="Zone Name"
                />

                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_16}
                    text={formik.values.name}
                />

                <CMPTypography
                    sx={{
                        marginTop: pxToRem(16)
                    }}
                    variation={TypographyVariation.TYPO_SCALE_18}
                    text="Select Rooms*"
                />

                <ZoneRoomSelectorSection
                    isEditMode={true}
                    zone={formik.values}
                    onSelectRooms={(isEdited, hasError, deviceIds) => {
                        setIsRoomOverlapping(hasError);
                        setDeviceIds(deviceIds);
                        setIsRoomsEdited(isEdited);
                    }}
                />

                <CMPTypography variation={TypographyVariation.TYPO_SCALE_12}>
                    *Required Field
                </CMPTypography>
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => {
                        onSaveClick();
                        setIsModalOpen(false);
                    }}
                    disabled={
                        !formik.isValid || isRoomOverlapping || !isRoomsEdited
                    }
                >
                    Save
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

const ViewRooms = (props: {
    rooms: AttachedRoomInfo[];
    zone: EventZone | StandardZone;
}) => {
    const { rooms, zone } = props;
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    return (
        <>
            <CMPTypography
                sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    color: CMPColors.light.text.hyperlink,
                    textDecoration: 'underline',
                    userSelect: 'none'
                }}
                handleClick={() => setIsViewModalOpen(true)}
            >
                {rooms.length}
            </CMPTypography>
            {isViewModalOpen && (
                <ViewRoomsModal
                    setIsModalOpen={setIsViewModalOpen}
                    actionType={Mode.Edit}
                    zone={zone}
                />
            )}
        </>
    );
};

export default ViewRooms;
