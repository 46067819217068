import React from 'react';
import { styled, SxProps } from '@mui/material';
import { pxToRem, StylesDictionaryCssProps } from '../../utils/stylesUtils';
import { className } from './cmpTextField';
import CMPColors from '../colors';

export interface CMPTypographyProps {
    sx?: SxProps;
    variation?: TypographyVariation;
    text?: string;
    id?: string;
    classNames?: string[];
    children?: React.ReactNode;
    handleClick?: () => void;
}

export enum TypographyVariation {
    HEADER1 = 'HEADER1',
    HEADER2 = 'HEADER2',
    HEADER3 = 'HEADER3',
    HEADER4 = 'HEADER4',
    HEADER5 = 'HEADER5',
    HEADER6 = 'HEADER6',
    HEADER7 = 'HEADER7',
    HEADER8 = 'HEADER8',
    TYPO_SCALE_18 = 'TYPO_SCALE_18',
    TYPO_SCALE_16 = 'TYPO_SCALE_16',
    TYPO_HYPERLINK_16 = 'TYPO_HYPERLINK_16',
    TYPO_SCALE_14 = 'TYPO_SCALE_14',
    TYPO_SCALE_12 = 'TYPO_SCALE_12'
}

export const TypographyStyles: StylesDictionaryCssProps = {
    HEADER1: {
        fontSize: pxToRem(44),
        fontWeight: 800,
        lineHeight: pxToRem(50)
    },
    HEADER2: {
        fontSize: pxToRem(28),
        fontWeight: 800,
        lineHeight: '28px'
    },
    HEADER3: {
        fontSize: pxToRem(22),
        fontWeight: 700,
        lineHeight: pxToRem(22)
    },
    HEADER4: {
        fontSize: pxToRem(20),
        fontWeight: 700,
        lineHeight: pxToRem(20)
    },
    HEADER5: {
        fontSize: pxToRem(18),
        fontWeight: 800,
        lineHeight: pxToRem(32)
    },
    HEADER6: {
        fontSize: pxToRem(16),
        fontWeight: 700,
        lineHeight: pxToRem(20)
    },
    HEADER7: {
        fontSize: pxToRem(14),
        fontWeight: 800,
        lineHeight: pxToRem(16)
    },
    HEADER8: {
        fontSize: pxToRem(8),
        fontWeight: 700,
        lineHeight: pxToRem(12)
    },
    TYPO_SCALE_18: {
        fontSize: pxToRem(18),
        fontWeight: 500,
        lineHeight: pxToRem(24)
    },
    TYPO_SCALE_16: {
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: pxToRem(21)
    },
    TYPO_HYPERLINK_16: {
        fontSize: pxToRem(16),
        fontWeight: 400,
        lineHeight: pxToRem(16),
        textDecoration: 'underline',
        color: CMPColors.light.text.hyperlink,
        letterSpacing: 0
    },
    TYPO_SCALE_14: {
        fontSize: pxToRem(14),
        fontWeight: 500,
        lineHeight: pxToRem(19)
    },
    TYPO_SCALE_12: {
        fontSize: pxToRem(12),
        fontWeight: 500,
        lineHeight: pxToRem(17)
    }
};

const Header1 = styled('h1')(() => ({
    margin: 0,
    ...TypographyStyles.HEADER1
}));

const Header2 = styled('h2')(() => ({
    ...TypographyStyles.HEADER2,
    margin: 0
}));

const Header3 = styled('h3')(() => ({
    ...TypographyStyles.HEADER3,
    margin: 0
}));

const Header4 = styled('h4')(() => ({
    ...TypographyStyles.HEADER4,
    margin: 0
}));

const Header5 = styled('h5')(() => ({
    ...TypographyStyles.HEADER5,
    margin: 0
}));

const Header6 = styled('h6')(() => ({
    ...TypographyStyles.HEADER6,
    margin: 0
}));
const Header7 = styled('h6')(() => ({
    ...TypographyStyles.HEADER7,
    margin: 0
}));
const Header8 = styled('h6')(() => ({
    ...TypographyStyles.HEADER8,
    margin: 0
}));

const TypographicScale18 = styled('p')(() => ({
    ...TypographyStyles.TYPO_SCALE_18,
    margin: 0
}));

const TypographicScale16 = styled('p')(() => ({
    ...TypographyStyles.TYPO_SCALE_16,
    margin: 0
}));

const TypographicHyperlink16 = styled('p')(() => ({
    ...TypographyStyles.TYPO_HYPERLINK_16,
    margin: 0
}));

const TypographicScale14 = styled('p')(() => ({
    ...TypographyStyles.TYPO_SCALE_14,
    margin: 0
}));

const TypographicScale12 = styled('p')(() => ({
    ...TypographyStyles.TYPO_SCALE_12,
    margin: 0
}));

const CMPTypography = (props: CMPTypographyProps) => {
    const {
        variation,
        text,
        sx,
        id,
        classNames = [],
        children,
        handleClick
    } = props;
    const childrenExist = React.Children.toArray(children).length > 0;
    switch (variation) {
        case TypographyVariation.HEADER1:
            return (
                <Header1
                    id={id}
                    className={className(['cmp_typography', ...classNames])}
                    onClick={handleClick}
                    sx={{ ...sx }}
                >
                    {childrenExist ? children : text}
                </Header1>
            );
        case TypographyVariation.HEADER2:
            return (
                <Header2
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header2>
            );
        case TypographyVariation.HEADER3:
            return (
                <Header3
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header3>
            );
        case TypographyVariation.HEADER4:
            return (
                <Header4
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header4>
            );
        case TypographyVariation.HEADER5:
            return (
                <Header5
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header5>
            );
        case TypographyVariation.HEADER6:
            return (
                <Header6
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header6>
            );
        case TypographyVariation.HEADER7:
            return (
                <Header7
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header7>
            );
        case TypographyVariation.HEADER8:
            return (
                <Header8
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </Header8>
            );
        case TypographyVariation.TYPO_SCALE_18:
            return (
                <TypographicScale18
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </TypographicScale18>
            );

        case TypographyVariation.TYPO_SCALE_12:
            return (
                <TypographicScale12
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </TypographicScale12>
            );
        case TypographyVariation.TYPO_SCALE_14:
            return (
                <TypographicScale14
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </TypographicScale14>
            );
        case TypographyVariation.TYPO_HYPERLINK_16:
            return (
                <TypographicHyperlink16
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </TypographicHyperlink16>
            );
        case TypographyVariation.TYPO_SCALE_16:
        default:
            return (
                <TypographicScale16
                    id={id}
                    sx={{ ...sx }}
                    onClick={handleClick}
                    className={className(['cmp_typography', ...classNames])}
                >
                    {childrenExist ? children : text}
                </TypographicScale16>
            );
    }
};

export default CMPTypography;
