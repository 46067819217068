import {
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { isNull, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
    ElementCategory,
    ElementsData,
    ElementToDeviceAttachment,
    ElementToElementAttachment
} from '../../redux/elementsList/model';
import { pxToRem } from '../../utils/stylesUtils';
import ElementActions from './elementAction';
import ElementAttachments from './elementAttachments';
import CMPColors from '../../components/colors';

interface ElementTableProps {
    elements: ElementsData[];
    handleOpenModal: (id: string, type: string) => void;
    elementType: ElementCategory;
}

const styles = {
    link: {
        cursor: 'pointer',
        color: CMPColors.light.text.hyperlink
    },
    enabledIcon: {
        color: CMPColors.light.icon.black
    },
    tableHeader: {
        fontSize: `${pxToRem(15)}`,
        fontWeight: 'bold'
    },
    tableTextCell: {
        fontSize: `${pxToRem(15)}`
    }
};

const elementsToElementsTableHeaders = [
    'Name',
    'Type',
    'Attached To',
    'Modified Date',
    'Modified By',
    ''
];

const elementToDevicesTableHeaders = [
    'Name',
    'Type',
    'Devices',
    'Modified Date',
    'Modified By',
    ''
];

const elementsWithOutAttachments = [
    'Name',
    'Type',
    'Modified Date',
    'Modified By',
    ''
];

export const GetElementHyperlink = ({ element }: { element: ElementsData }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Link
            color="inherit"
            onClick={() =>
                element.type === ElementCategory.PAGE
                    ? navigate(`${location.pathname}/${element.id}/canvas`)
                    : navigate(location.pathname + `/${element.id}`, {
                          state: {
                              widget: element,
                              origin: 'element'
                          }
                      })
            }
            style={styles.link}
        >
            {element.name}
        </Link>
    );
};

const ElementsTable = (props: ElementTableProps) => {
    const { elements, handleOpenModal, elementType } = props;
    const elementsOrderedByName = orderBy(elements, ['name']);

    const showAttachedContent = elementsOrderedByName.some(
        (e) => !isNull(e.attached_to)
    );

    const getColumnHeaders = () => {
        const renderHeaders = (headers: string[]) =>
            headers.map((header: string, index: number) => (
                <TableCell key={index} sx={styles.tableHeader}>
                    {header}
                </TableCell>
            ));

        if (!showAttachedContent)
            return renderHeaders(elementsWithOutAttachments);

        switch (elementType) {
            case ElementCategory.RAIL:
            case ElementCategory.WIDGET:
                return renderHeaders(elementsToElementsTableHeaders);
            default:
                return renderHeaders(elementToDevicesTableHeaders);
        }
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>{getColumnHeaders()}</TableRow>
                    </TableHead>
                    <TableBody>
                        {elementsOrderedByName.map((element: ElementsData) => {
                            return (
                                <TableRow key={element.id}>
                                    <TableCell
                                        style={{
                                            width: '30%'
                                        }}
                                    >
                                        <GetElementHyperlink
                                            element={element}
                                        />
                                    </TableCell>
                                    <TableCell style={styles.tableTextCell}>
                                        {element.widget_type_name}
                                    </TableCell>

                                    {showAttachedContent && (
                                        <TableCell style={styles.tableTextCell}>
                                            <ElementAttachments
                                                attachedTo={
                                                    element.attached_to as
                                                        | ElementToElementAttachment
                                                        | ElementToDeviceAttachment
                                                }
                                                typeOfWidgetsAttached={
                                                    elementType
                                                }
                                                name={element.name}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell style={styles.tableTextCell}>
                                        {moment(
                                            element.modified_date
                                        ).calendar()}
                                    </TableCell>
                                    <TableCell style={styles.tableTextCell}>
                                        {element.modified_by}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: '5%',
                                            padding: pxToRem(10)
                                        }}
                                    >
                                        <ElementActions
                                            accessDetails={
                                                element.access_details
                                            }
                                            element={element}
                                            handleOpenModal={handleOpenModal}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ElementsTable;
