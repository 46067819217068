import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import useQuery from '../../hooks/useQuery';
import CMPTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';
import {
    forgotPasswordSubmit,
    setForgotPasswordSubmitStatus,
    setLoginStatus,
    userLogout
} from '../../redux/login/action';
import {
    ForgotPasswordSubmitStatus,
    LoginStatus
} from '../../redux/login/model';
import {
    selectError,
    selectForgotPasswordSubmitStatus
} from '../../redux/login/reducer';
import { changePasswordValidationSchema } from '../../utils/loginUtils';
import {
    ButtonContainer,
    FormWrapper,
    RedirectionLink
} from '../../components/cmpAuthWrapper';
import CMPTextField, {
    TextVariation
} from '../../components/textFields/cmpTextField';
import SuccessForm from './successForm';
import { Validation } from '../../components/textFields/cmpValidationTexts';
import CMPValidationTextField from '../../components/textFields/cmpTextfieldWithValidations';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const ChangePassword = () => {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const email: string | null = query.get('email');
    const forgotPasswordStatus = useSelector(selectForgotPasswordSubmitStatus);
    const changePasswordError = useSelector(selectError);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        dispatch(userLogout());
        dispatch(setLoginStatus(LoginStatus.NONE));
    }, []);

    useEffect(() => {
        if (
            forgotPasswordStatus ===
            ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_DONE
        ) {
            formik.handleReset('e');
            setShowSuccess(true);
            dispatch(setForgotPasswordSubmitStatus());
            console.log('Reset Password Successfully');
        } else if (
            forgotPasswordStatus ===
            ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_FAILED
        ) {
            formik.handleReset('e');
            showSnackbar(changePasswordError, 'error');
            dispatch(setForgotPasswordSubmitStatus());
            console.error('Could not reset Password - ', changePasswordError);
        }
    }, [forgotPasswordStatus]);

    const formik = useFormik({
        initialValues: {
            email: email ?? '',
            securityCode: '',
            password: '',
            repeatPassword: ''
        },
        validationSchema: changePasswordValidationSchema,
        onReset: () => {},
        onSubmit: () => {}
    });

    const handleClick = () => {
        console.log('Redirecting to Login...');
        navigate('/'); //to redirect to login page
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (Object.keys(formik.errors).length === 0) {
            dispatch(
                forgotPasswordSubmit(
                    formik.values.email,
                    formik.values.securityCode,
                    formik.values.password
                )
            );
            console.log(`Resetting Password for ${formik.values.email}...`);
        } else {
            showSnackbar(CMPTexts.changePassword.passwordResetError, 'error');
            console.log('Form is not filled correctly');
        }
    };

    if (showSuccess) {
        return (
            <SuccessForm
                title={CMPTexts.changePassword.successCard.title}
                description={CMPTexts.changePassword.successCard.description}
                buttonText={CMPTexts.login.login}
                onClick={() => navigate('/')}
            />
        );
    }

    return (
        <>
            {forgotPasswordStatus ===
                ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_INITIATED && (
                <FullScreenCircularLoader />
            )}

            <FormWrapper onSubmit={handleSubmit}>
                <CMPTypography
                    variation={TypographyVariation.HEADER2}
                    sx={{
                        letterSpacing: pxToRem(-0.20000000298023224),
                        marginBottom: pxToRem(32)
                    }}
                >
                    {CMPTexts.changePassword.title}
                </CMPTypography>
                <CMPTextField
                    disabled={Boolean(email)}
                    id="outlined-basic"
                    label={CMPTexts.login.textFieldEmail}
                    value={formik.values.email}
                    onChange={() => null}
                    error={Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                />
                <CMPTextField
                    id="outlined-basic"
                    label={CMPTexts.changePassword.textFieldSecurityCode}
                    required
                    value={formik.values.securityCode}
                    onChange={(val) =>
                        formik.setFieldValue('securityCode', val)
                    }
                    error={Boolean(formik.errors.securityCode)}
                    helperText={formik.errors.securityCode}
                />
                <CMPValidationTextField
                    variation={TextVariation.PASSWORD}
                    id="password"
                    onBlur={formik.handleBlur}
                    required
                    label={CMPTexts.changePassword.textFieldNewPassword}
                    value={formik.values.password}
                    onChange={(val) => formik.setFieldValue('password', val)}
                    error={
                        Boolean(formik.errors.password) &&
                        Boolean(formik.touched.password)
                    }
                    options={[
                        {
                            name: 'One Lowercase Character',
                            validation: /[a-z]/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Uppercase Character',
                            validation: /[A-Z]/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Number',
                            validation: /\d/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: '8 Characters Minimum',
                            validation: /.{8,}/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Special Character',
                            validation: /[!@#$%^&*()\-=+{};:,<.>]/.test(
                                formik.values.password
                            )
                                ? Validation.PASS
                                : Validation.ERROR
                        }
                    ]}
                />
                <CMPValidationTextField
                    variation={TextVariation.PASSWORD}
                    id="repeatPassword"
                    required
                    label={CMPTexts.changePassword.textFieldConfirmPassword}
                    value={formik.values.repeatPassword}
                    onChange={(val) =>
                        formik.setFieldValue('repeatPassword', val)
                    }
                    onBlur={formik.handleBlur}
                    error={
                        Boolean(formik.errors.repeatPassword) &&
                        Boolean(formik.touched.repeatPassword)
                    }
                    options={[
                        {
                            name: 'Passwords Must Match',
                            validation:
                                formik.values.password.length > 0 &&
                                formik.values.password ===
                                    formik.values.repeatPassword
                                    ? Validation.PASS
                                    : Validation.ERROR
                        }
                    ]}
                />

                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_14}
                    sx={{
                        marginTop: pxToRem(8),
                        color: CMPColors.light.text.tertiary
                    }}
                >
                    {CMPTexts.common.requiredText}
                </CMPTypography>

                <ButtonContainer>
                    <Button
                        type="submit"
                        buttonVariant={ButtonVariation.CONTAINED}
                        disabled={!(formik.isValid && formik.dirty)}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {CMPTexts.changePassword.button}
                    </Button>
                    <RedirectionLink onClick={handleClick}>
                        {CMPTexts.forgotPassword.returnToLogin}
                    </RedirectionLink>
                </ButtonContainer>
            </FormWrapper>
        </>
    );
};

export default ChangePassword;
