import React from 'react';
import { Box, Grid } from '@mui/material';
import { head, isEmpty } from 'lodash';
import { useState } from 'react';
import CMPDropDown from '../../../components/cmpDropDown';
import { MediaType } from '../../../redux/element/model';
import {
    PropertyField,
    PropertyFieldValue,
    PropertyFieldValueType
} from '../../../redux/propertyValue/model';
import { pxToRem } from '../../../utils/stylesUtils';
import PropertySectionInfoText from '../propertyDetails/components/propertySectionInfoText';
import ScreenSaverSelector from './components/screenSaverSelector';
import { ZoneToScreenSaverAssets } from './manageZones';
import { DisableableBox } from '../../../components/disableableBox';
import PropertySectionHeader from '../propertyDetails/components/propertySectionHeader';

const AssetPreview = ({ assetUrl, type }: AssetPreviewDetails) => {
    const getRandomPosition = () => {
        const top = Math.random() * 55;
        const left = Math.random() * 55;
        return { top: `${top}%`, left: `${left}%` };
    };

    if (isEmpty(assetUrl)) return null;

    return type === MediaType.Image ? (
        <img
            src={assetUrl as string}
            alt="Preview"
            style={{
                position: 'absolute',
                top: getRandomPosition().top,
                left: getRandomPosition().left,
                width: '45%',
                aspectRatio: '16 / 9',
                objectFit: 'contain',
                borderRadius: '8px'
            }}
        />
    ) : (
        <video
            src={assetUrl as string}
            muted
            autoPlay
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: pxToRem(20)
            }}
        />
    );
};

export interface AssetPreviewDetails {
    assetUrl: string | null;
    type: MediaType;
}

interface ScreenSaverProps {
    screenSaverData: PropertyField;
    setHasError: (hasError: boolean) => void;
    onScreenSaverFieldUpdate: (
        index: number,
        value: PropertyFieldValue,
        propertyFieldId: string
    ) => void;
    zoneToScreenSaverAssets: ZoneToScreenSaverAssets;
    setZoneToScreenSaverAssets: (value: ZoneToScreenSaverAssets) => void;
    zoneId: string;
    propertyLogoAssetId: string;
}

const ScreenSaver = (props: ScreenSaverProps) => {
    const {
        screenSaverData,
        setHasError,
        onScreenSaverFieldUpdate,
        zoneToScreenSaverAssets,
        setZoneToScreenSaverAssets,
        zoneId,
        propertyLogoAssetId
    } = props;

    const activationTime = screenSaverData.children?.find(
        (child) => child.value_type === PropertyFieldValueType.DropDown
    );

    const activationTimeIndex = screenSaverData.children?.findIndex(
        (child) => child.value_type === PropertyFieldValueType.DropDown
    );

    const screenSaverContent = screenSaverData.children?.find(
        (child) =>
            child.value_type === PropertyFieldValueType.ScreenSaverContent
    );

    const screenSaverContentIndex = screenSaverData.children?.findIndex(
        (child) =>
            child.value_type === PropertyFieldValueType.ScreenSaverContent
    );

    const [previewAssetDetails, setPreviewAssetDetails] =
        useState<AssetPreviewDetails>({
            assetUrl: null,
            type: MediaType.Image
        });

    const infoText = head(
        screenSaverData.children!.filter(
            (child) => child.value_type === PropertyFieldValueType.InfoMessage
        )
    );

    return (
        <>
            <Grid item>
                <PropertySectionHeader
                    header={screenSaverData.name}
                    tooltipMessage={screenSaverData.configuration?.toolTip}
                />
            </Grid>
            {infoText && (
                <Grid item xs={12} sx={{ marginBottom: pxToRem(16) }}>
                    <PropertySectionInfoText
                        description={infoText?.name ?? ''}
                        size={
                            infoText.configuration?.infoStyle === 'SMALL'
                                ? 'small'
                                : 'normal'
                        }
                    />
                </Grid>
            )}
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <Box
                        sx={{
                            backgroundColor: 'black',
                            aspectRatio: '16 / 9',
                            borderRadius: pxToRem(20),
                            position: 'relative'
                        }}
                    >
                        <AssetPreview
                            assetUrl={previewAssetDetails.assetUrl}
                            type={previewAssetDetails.type}
                        />
                    </Box>
                </Grid>
                {activationTime && (
                    <Grid item xs={4} sx={{ marginTop: pxToRem(16) }}>
                        <CMPDropDown
                            required
                            disabled={!activationTime?.access_details?.Edit}
                            label={activationTime.name}
                            tooltipMessage={
                                activationTime?.configuration?.toolTip
                            }
                            error={false}
                            options={activationTime?.configuration?.options}
                            value={activationTime.value}
                            onChange={(value) => {
                                onScreenSaverFieldUpdate(
                                    activationTimeIndex ?? 0,
                                    value,
                                    activationTime.id
                                );
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            {screenSaverContent && (
                <DisableableBox
                    disabled={!screenSaverContent.access_details?.Edit}
                >
                    <ScreenSaverSelector
                        screenSaverContent={screenSaverContent}
                        setHasError={setHasError}
                        onAssetClick={setPreviewAssetDetails}
                        onScreenSaverFieldUpdate={(value: PropertyFieldValue) =>
                            onScreenSaverFieldUpdate(
                                screenSaverContentIndex ?? 1,
                                value,
                                screenSaverContent.id
                            )
                        }
                        zoneToScreenSaverAssets={zoneToScreenSaverAssets}
                        setZoneToScreenSaverAssets={setZoneToScreenSaverAssets}
                        zoneId={zoneId}
                        propertyLogoAssetId={propertyLogoAssetId}
                    />
                </DisableableBox>
            )}
            <Grid item xs={12}>
                <PropertySectionInfoText
                    description="*Required Field"
                    size="small"
                />
            </Grid>
        </>
    );
};

export default ScreenSaver;
