import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { includes, isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove
} from 'react-sortable-hoc';
import CmpTexts from '../../locale/en';
import {
    ContentField,
    FieldType,
    RailAlias,
    WidgetDropDownResponse
} from '../../redux/element/model';
import { ElementCategory } from '../../redux/elementsList/model';
import { selectWidgets } from '../../redux/elementsList/reducer';
import { getCharacterLimit } from '../../utils/configureElementUtil';
import { StylesDictionary, pxToRem } from '../../utils/stylesUtils';
import { StyledTextField } from './elementFormGenerator';
import {
    DragHandleProps,
    RailGeneratorProps,
    SortableItemProps,
    SortableListProps
} from './elementsInterface';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

export const ButtonContainer = styled('div')(() => ({
    color: '#181818',
    textDecoration: 'underline',
    position: 'absolute',
    bottom: 0,
    top: '100%',
    marginTop: 3,
    right: 35,
    transform: 'translateY(-50%)'
}));

export const ListContainer = styled('div')(() => ({
    marginTop: 5,
    marginBottom: 15,
    backgroundColor: '#ebf6f9',
    border: `${pxToRem(2)} solid #AED9E6`,
    borderRadius: 2,
    marginRight: 30
}));

export const ArrowIconButton = styled('div')(() => ({
    color: '#181818',
    textDecoration: 'underline',
    position: 'absolute',
    bottom: 0,
    top: '100%',
    right: 0,
    transform: 'translateY(-50%)'
}));

export const ItemList = styled('div')(() => ({
    color: CMPColors.light.text.hyperlink,
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold',
    marginLeft: 12,
    marginTop: 4
}));

export const LinkItemList = styled('div')(() => ({
    color: CMPColors.light.text.hyperlink,
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold'
}));

export const StyledFormControl = styled(FormControl)(() => ({
    width: '80vw',
    marginTop: '2vh',
    marginBottom: 75,
    marginLeft: 34
}));

export const WidgetContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    marginTop: '1vh',
    overflowX: 'hidden'
}));

export const Container = styled('div')(() => ({
    border: '1px solid #979797',
    borderRadius: 4,
    marginTop: '4vh'
}));

export const WidgetNameContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

export const CustomLink = styled(Link)(() => ({
    cursor: 'pointer',
    color: CMPColors.light.text.hyperlink,
    textDecorationColor: CMPColors.light.text.hyperlink,
    marginLeft: 10
}));

export const ReorderSharp = styled(DragIndicatorIcon)(() => ({
    color: 'rgba(23,23,37,0.54)'
}));

export const RailIconUrl = styled('img')(() => ({
    width: 36,
    height: 40,
    marginRight: pxToRem(4)
}));

export const WidgetNameDiv = styled('div')(() => ({
    marginTop: 2,
    marginLeft: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const ArrowDropDownIconStyle = styled(ArrowDropDownIcon)(() => ({
    fontSize: 30
}));

export const ArrowDropUpIconStyle = styled(ArrowDropUpIcon)(() => ({
    fontSize: 30
}));

export const AddButton = styled(Button)(() => ({
    width: 231,
    minWidth: 231,
    height: 89,
    backgroundColor: '#ebf6f9',
    border: `${pxToRem(2)} solid #AED9E6`,
    color: CMPColors.light.text.hyperlink,
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderRadius: 2,
    marginTop: 26,
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: '#ebf6f9'
    }
}));

export const AddIconWidget = styled(AddIcon)(() => ({
    width: 28,
    height: 70
}));

export const RailWidgetDiv = styled('div')(() => ({
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center'
}));

export const ReorderSharpdiv = styled('div')(() => ({
    marginTop: 8
}));

export const PlayCircleDiv = styled('div')(() => ({
    marginTop: 8
}));

export const WidgetCount = styled('div')(() => ({
    fontSize: 14,
    fontWeight: 500,
    color: '#979797'
}));

const styles: StylesDictionary = {
    customLinkStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: CMPColors.light.text.hyperlink,
        textDecorationColor: CMPColors.light.text.hyperlink,
        '&:hover': {
            textDecoration: 'underline',
            textDecorationThickness: 2
        }
    },
    widgetShowInLine: {
        overflow: 'hidden',
        flexWrap: 'wrap'
    },
    widgetShowAll: {
        overflow: 'auto',
        flexWrap: 'nowrap'
    },
    addButtonLarge: {
        width: 280,
        height: 155
    },
    addButtonRegular: {
        width: 240,
        height: 100
    },
    widgetLarge: {
        width: 280,
        height: 155
    },
    widgetRegular: {
        height: 100,
        width: 240
    },
    widgetDeleteIcon: {
        color: '#000000'
        // marginTop: 4
    }
};

const DragHandle = SortableHandle((props: DragHandleProps) => {
    const {
        formik,
        widgetAlias,
        index,
        mwtIndex,
        itemDataName,
        itemIconUrl,
        itemDataWidgetTypeName,
        itemDataId,
        setShowWarningModal,
        isEdited,
        showRemove
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const multiWidgetContentField = formik?.values.content_fields.findIndex(
        (cf) => cf.type === FieldType.MultiWidgetType
    );
    const handleRemoveItem = (removedItem: string) => {
        console.log('removing element for multiwidget drop down...');
        props.setIsEdited(true);
        formik?.setFieldValue(
            `content_fields[${multiWidgetContentField}].values`,
            isArray(formik?.values?.content_fields[mwtIndex]?.values) &&
                (
                    formik?.values?.content_fields[mwtIndex]?.values as string[]
                ).filter((item: string) => item !== removedItem)
        );
    };

    const handleElementRedirect = () => {
        const splitPathname = location.pathname.split('/');
        splitPathname.pop();
        splitPathname.push(props.itemDataId);

        navigate(splitPathname.join('/'), {
            state: {
                widget: {
                    widget_type_name: itemDataWidgetTypeName,
                    type: ElementCategory.WIDGET
                },
                origin: ElementCategory.RAIL
            }
        });
    };

    const handleElementRedirectClick = () => {
        if (isEdited && setShowWarningModal) {
            handleElementRedirect();
        } else if (location.pathname !== undefined && location) {
            const splitPathname = location.pathname.split('/');
            splitPathname.pop();
            splitPathname.push(props.itemDataId);
            navigate(splitPathname.join('/'), {
                state: {
                    widget: {
                        widget_type_name: itemDataWidgetTypeName,
                        type: ElementCategory.WIDGET
                    },
                    origin: ElementCategory.RAIL
                }
            });
        }
    };

    return (
        <ListContainer key={index + ''}>
            <WidgetNameContainer>
                <LinkItemList>
                    <RailWidgetDiv
                        sx={
                            includes(
                                [
                                    RailAlias.HeroCarousel,
                                    RailAlias.WidgetRail_350x284
                                ],
                                widgetAlias
                            )
                                ? styles.widgetLarge
                                : styles.widgetRegular
                        }
                    >
                        <ReorderSharpdiv>
                            <ReorderSharp />
                        </ReorderSharpdiv>
                        <PlayCircleDiv>
                            <RailIconUrl src={itemIconUrl} />
                        </PlayCircleDiv>
                        <WidgetNameDiv>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={handleElementRedirectClick}
                                underline="none"
                                sx={styles.customLinkStyle}
                            >
                                {itemDataName}
                            </Link>

                            <CMPTypography
                                variation={TypographyVariation.TYPO_SCALE_16}
                                sx={{
                                    color: '#171726',
                                    marginTop: pxToRem(2),
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden !important',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {'Type: ' + itemDataWidgetTypeName}
                            </CMPTypography>
                        </WidgetNameDiv>

                        {showRemove && (
                            <Box sx={styles.widgetDeleteIcon}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => handleRemoveItem(itemDataId)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        )}
                    </RailWidgetDiv>
                </LinkItemList>
            </WidgetNameContainer>
        </ListContainer>
    );
});

const SortableItem = SortableElement((props: SortableItemProps) => {
    const {
        formik,
        widgetAlias,
        itemData,
        itemDataId,
        itemIconUrl,
        itemDataName,
        itemDataWidgetTypeName,
        index,
        mwtIndex,
        itemListIndex,
        items,
        multiWidgetItem,
        isEdited,
        canEditWidget,
        setIsEdited,
        setShowWarningModal
    } = props;

    return (
        <div>
            <WidgetCount> {`Widget ${itemListIndex}`}</WidgetCount>
            <DragHandle
                formik={formik}
                widgetAlias={widgetAlias}
                itemListIndex={itemListIndex}
                itemData={itemData}
                itemDataId={itemDataId}
                itemIconUrl={itemIconUrl}
                itemDataName={itemDataName}
                itemDataWidgetTypeName={itemDataWidgetTypeName}
                key={index + ''}
                index={index}
                mwtIndex={mwtIndex}
                items={items}
                multiWidgetItem={multiWidgetItem}
                isEdited={isEdited}
                showRemove={canEditWidget}
                setIsEdited={setIsEdited}
                setShowWarningModal={setShowWarningModal}
            />
        </div>
    );
});

const SortableListContainer = SortableContainer((props: SortableListProps) => {
    const {
        formik,
        multiwidget,
        setMultiWidgetItem,
        isEdited,
        showInLine,
        mwtIndex,
        setIsEdited,
        setShowAddElement,
        setRedirectParams,
        setShowWarningModal
    } = props;

    const widgets = useSelector(selectWidgets);

    const handleAddWidget = () => {
        setShowAddElement?.(true);
    };
    const widgetAlias = formik.values.alias;

    const multiWidgetTypeCF = formik.values.content_fields[mwtIndex];
    const canEditWidget = multiWidgetTypeCF?.access_details?.Edit ?? false;

    return (
        <WidgetContainer
            sx={showInLine ? styles.widgetShowInLine : styles.widgetShowAll}
        >
            {isArray(multiWidgetTypeCF.values) &&
                multiWidgetTypeCF.access_details?.View &&
                (multiWidgetTypeCF.values as string[]).map(
                    (itemD: string, index: number) => {
                        const widget = widgets.find((w) => w.id === itemD);
                        const shouldRender =
                            widget &&
                            multiWidgetTypeCF.allowed_type_id.includes(
                                widget.widget_type_id
                            );
                        return (
                            shouldRender && (
                                <SortableItem
                                    formik={formik}
                                    widgetAlias={widgetAlias}
                                    key={index + ''}
                                    index={index}
                                    mwtIndex={mwtIndex}
                                    itemListIndex={index + 1}
                                    itemData={widget}
                                    itemDataId={widget.id}
                                    canEditWidget={canEditWidget}
                                    disabled={
                                        !multiWidgetTypeCF?.access_details
                                            ?.SortWidget
                                    }
                                    itemIconUrl={widget.icon_url}
                                    itemDataName={widget.name}
                                    itemDataWidgetTypeName={
                                        widget.widget_type_name
                                    }
                                    items={multiwidget}
                                    multiWidgetItem={setMultiWidgetItem}
                                    isEdited={Boolean(isEdited)}
                                    setIsEdited={setIsEdited}
                                    setRedirectParams={setRedirectParams}
                                    setShowWarningModal={setShowWarningModal}
                                />
                            )
                        );
                    }
                )}

            {canEditWidget && (
                <AddButton
                    sx={
                        includes(
                            [
                                RailAlias.HeroCarousel,
                                RailAlias.WidgetRail_350x284
                            ],
                            widgetAlias
                        )
                            ? styles.addButtonLarge
                            : styles.addButtonRegular
                    }
                    onClick={handleAddWidget}
                >
                    <AddIconWidget />
                    {CmpTexts.railGenerator.Widget}
                </AddButton>
            )}
        </WidgetContainer>
    );
});

const RailGenerator = (props: RailGeneratorProps) => {
    const {
        formik,
        setIsEdited,
        setShowAddElement,
        setRedirectParams,
        setShowWarningModal
    } = props;
    const [multiwidget, setMultiWidgetItem] = React.useState<
        WidgetDropDownResponse[]
    >([]);
    const [selectedWidgetIds, setSelectedWidgetIds] = useState<string[]>([]);
    const [showInLine, setShowInLine] = useState(false);

    // this is a patchy code written as doing it the write way would require bigger refactor,
    // this will be implemented property once we start working on cavas design
    // Here we are finding the index of ** MultiWidgetType content field **
    const mwtIndex = formik?.values?.content_fields.findIndex(
        (cf: ContentField) => cf.type === FieldType.MultiWidgetType
    );

    useEffect(() => {
        if (
            formik.values.content_fields[mwtIndex]?.children?.length &&
            formik.values.content_fields[mwtIndex].values
        ) {
            const selectedWidgets = formik.values.content_fields[
                mwtIndex
            ]?.children?.filter((c: WidgetDropDownResponse) => {
                const value = String(
                    formik.values.content_fields[mwtIndex].values
                );
                return value.includes(c.id);
            });

            if (selectedWidgets) {
                const values: any =
                    formik.values.content_fields[mwtIndex].values;

                const sortedValues = values.map((i: string) =>
                    selectedWidgets.find((j: any) => j.id === i)
                );
                setMultiWidgetItem(sortedValues);
            }
        }
        return () => {
            setMultiWidgetItem([]);
            setSelectedWidgetIds(['']);
        };
    }, []);

    useEffect(() => {
        if (selectedWidgetIds.length !== 0)
            formik.setFieldValue(
                `content_fields[${mwtIndex}].values`,
                selectedWidgetIds
            );
    }, [selectedWidgetIds]);

    const onSortEnd = ({
        oldIndex,
        newIndex
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        console.log(
            'changing order of selected elements of multiwidget drop down...'
        );
        setIsEdited(true);
        const arrayCheckedvalue = isArray(
            formik?.values?.content_fields[mwtIndex]?.values
        )
            ? (formik.values.content_fields[mwtIndex].values as string[])
            : [];
        const arrayUpdatedValue = arrayMove(
            arrayCheckedvalue,
            oldIndex,
            newIndex
        );
        formik.setFieldValue(
            `content_fields[${mwtIndex}].values`,
            arrayUpdatedValue
        );

        console.log('updated array', arrayUpdatedValue);
    };

    const handleClick = () => {
        setShowInLine(true);
    };

    const handleShowLessClick = () => {
        setShowInLine(false);
    };

    return (
        <>
            {formik.values.content_fields.map((cf, index) => {
                if (cf.type === FieldType.Text)
                    return (
                        <>
                            <StyledTextField
                                key={index}
                                name={`content_fields[${index}].values`}
                                value={
                                    formik.values.content_fields[index].values
                                        ?.toString()
                                        .trimStart() || ''
                                }
                                label={cf.is_required ? `${cf.name}*` : cf.name}
                                variant="outlined"
                                disabled={!cf.access_details?.Edit}
                                onChange={(e: React.SyntheticEvent) => {
                                    setIsEdited(true);
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                multiline={Boolean(
                                    cf?.configuration?.useAsTitle ||
                                        cf?.configuration?.rowLength
                                )}
                                rows={
                                    cf?.configuration?.useAsTitle
                                        ? 4
                                        : cf?.configuration?.rowLength
                                        ? cf?.configuration?.rowLength
                                        : 0
                                }
                                fullWidth
                                helperText={
                                    formik?.errors?.content_fields &&
                                    formik?.errors?.content_fields[index]
                                }
                                error={Boolean(
                                    formik?.errors?.content_fields &&
                                        formik?.errors?.content_fields[index]
                                )}
                                maxLength={cf?.configuration?.charLimit ?? 50}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {getCharacterLimit(
                                                cf?.configuration?.charLimit,
                                                formik.values.content_fields[
                                                    index
                                                ].values
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <CMPTypography
                                variation={TypographyVariation.TYPO_SCALE_14}
                                sx={{
                                    fontStyle: 'italic',
                                    letterSpacing: 0,
                                    color: CMPColors.light.text.secondary,
                                    textAlign: 'right'
                                }}
                            >
                                {cf.description}
                            </CMPTypography>
                        </>
                    );
                return null;
            })}
            <Container>
                <CMPTypography
                    variation={TypographyVariation.HEADER3}
                    sx={{
                        marginLeft: pxToRem(34),
                        marginTop: pxToRem(20)
                    }}
                >
                    {CmpTexts.railGenerator.RailContents}
                </CMPTypography>

                <StyledFormControl>
                    <SortableListContainer
                        formik={formik}
                        multiwidget={multiwidget}
                        mwtIndex={mwtIndex}
                        setIsEdited={setIsEdited}
                        isEdited={Boolean(props.isEdited)}
                        setMultiWidgetItem={setMultiWidgetItem}
                        showInLine={showInLine}
                        onSortEnd={onSortEnd}
                        useDragHandle={true}
                        axis="xy"
                        distance={2}
                        setShowAddElement={setShowAddElement}
                        setRedirectParams={setRedirectParams}
                        setShowWarningModal={setShowWarningModal}
                    />

                    {isArray(formik.values.content_fields[mwtIndex].values) &&
                        (
                            formik.values.content_fields[mwtIndex]
                                .values as string[]
                        ).length > 3 &&
                        !showInLine && (
                            <div onClick={handleClick}>
                                <ButtonContainer>
                                    {CmpTexts.railGenerator.showAll}
                                </ButtonContainer>
                                <ArrowIconButton>
                                    <ArrowDropDownIconStyle />
                                </ArrowIconButton>
                            </div>
                        )}
                    {isArray(formik.values.content_fields[mwtIndex].values) &&
                        (
                            formik.values.content_fields[mwtIndex]
                                .values as string[]
                        ).length > 3 &&
                        showInLine && (
                            <div onClick={handleShowLessClick}>
                                <ButtonContainer>
                                    {CmpTexts.railGenerator.showInLine}
                                </ButtonContainer>
                                <ArrowIconButton>
                                    <ArrowDropUpIconStyle />
                                </ArrowIconButton>
                            </div>
                        )}
                </StyledFormControl>
            </Container>
        </>
    );
};

export default RailGenerator;
