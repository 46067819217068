import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button, { ButtonVariation, ButtonSize } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent
} from '../../components/cmpDialog';
import {
    hideGenericCommentsModal,
    removeQueuedActions,
    updateComments
} from '../../redux/propertyValue/action';
import {
    selectQueuedActions,
    selectShowGenericCommentModal
} from '../../redux/propertyValue/reducer';
import CMPTextField from '../../components/textFields/cmpTextField';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import { pxToRem } from '../../utils/stylesUtils';

const initialValues = {
    comment: ''
};

const CommentsModal = () => {
    const formik = useFormik({
        initialValues: initialValues,
        validateOnMount: true,
        validateOnChange: true,
        validationSchema: Yup.object().shape({
            comment: Yup.string().test(
                'word-count',
                'Please write at least five words.',
                (value) => {
                    if (!value) return false;
                    return value.trim().split(/\s+/).length > 4;
                }
            )
        }),
        onSubmit: () => {}
    });

    const dispatch = useDispatch();
    const queuedActions = useSelector(selectQueuedActions);
    const showGenericCommentModal = useSelector(selectShowGenericCommentModal);

    const handleClose = () => {
        dispatch(hideGenericCommentsModal());
        dispatch(removeQueuedActions());
        formik.setValues(initialValues);
    };

    const onSaveClick = () => {
        dispatch(updateComments(formik.values.comment));
        queuedActions.forEach((action: any) => dispatch(action));
        handleClose();
    };

    if (!showGenericCommentModal) {
        return null;
    }

    return (
        <CMPDialog onClose={handleClose} maxWidth="sm">
            <CMPTypography
                variation={TypographyVariation.HEADER2}
                sx={{ padding: 0, marginBottom: pxToRem(16) }}
                text="Add Comments"
            />
            <CMPDialogContent>
                <CMPTextField
                    maxLength={200}
                    onChange={(value) => formik.setFieldValue('comment', value)}
                    id="comment"
                    name="comment"
                    rows={4}
                    rowHeight={40}
                    value={formik.values.comment}
                    sx={{ width: '99.5%' }}
                    error={Boolean(formik.errors.comment)}
                    label="Comment"
                    required
                    helperText={formik.errors.comment}
                />
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    onClick={handleClose}
                    buttonSize={ButtonSize.MEDIUM}
                >
                    Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={onSaveClick}
                    disabled={!formik.isValid}
                >
                    Save
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default CommentsModal;
