import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/trashBin.svg';
import { AccordionDetails, Box, FormHelperText, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import React from 'react';
import cmpTexts from '../../../locale/en';
import { IndividualFaq } from '../../../redux/propertyValue/model';
import { pxToRem } from '../../../utils/stylesUtils';
import CMPTextField from '../../../components/textFields/cmpTextField';
import CMPTypography, {
    TypographyVariation
} from '../../../components/textFields/cmpTypography';
import CMPColors from '../../../components/colors';

interface Props {
    id: number;
    expanded: boolean;
    faqErrors: boolean;
    accordionOnCLick: any;
    item: IndividualFaq;
    handleDelete: any;
    handleChange: any;
    handleBlur: any;
    errorQuestionText: string;
    errorAnswerText: string;
    dragHandle: React.ReactNode;
    viewOnly: boolean;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1.5px solid ${CMPColors.dark.stroke.complete}`,
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(() => ({
    backgroundColor: CMPColors.light.background.accordion.primary,
    color: CMPColors.dark.text.primary,
    flexDirection: 'row-reverse',
    '&.disable-accordion-style': {
        backgroundColor: 'grey'
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: CMPColors.dark.text.primary,
        transform: 'rotate(90deg)',
        position: 'absolute',
        left: 30
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)'
    },
    '& .MuiAccordionSummary-content': {
        paddingLeft: pxToRem(40)
    }
}));

const IndividualFAQComponent = (props: Props) => {
    const {
        id,
        expanded,
        faqErrors,
        accordionOnCLick,
        item,
        handleDelete,
        handleChange,
        handleBlur,
        errorQuestionText,
        errorAnswerText,
        dragHandle,
        viewOnly
    } = props;
    return (
        <>
            <Accordion
                key={`accordion-${id}`}
                expanded={expanded}
                sx={
                    faqErrors
                        ? {
                              border: `${CMPColors.light.stroke.error} solid 1px`
                          }
                        : {}
                }
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id={`panel-header-${id}`}
                    onClick={accordionOnCLick(id)}
                    className={viewOnly ? 'disable-accordion-style' : ''}
                    sx={{ height: pxToRem(56) }}
                >
                    {!viewOnly && dragHandle}
                    <CMPTypography
                        variation={TypographyVariation.HEADER4}
                        sx={{ flex: 1, marginTop: pxToRem(3) }}
                        text={item.question ? item.question : 'Question'}
                    />
                    {!viewOnly && <DeleteIcon onClick={handleDelete(id)} />}
                </AccordionSummary>
                <AccordionDetails id={`panel-details-${id}`}>
                    <Box key={`Question-${id}`}>
                        <CMPTypography
                            sx={{ flex: 1, marginTop: pxToRem(3) }}
                            text="Question"
                        />

                        <CMPTextField
                            fullWidth={true}
                            value={item.question}
                            name={`faq[${id}].question`}
                            maxLength={200}
                            onChange={handleChange(id, 'question')}
                            placeholder={cmpTexts.faqSection.question}
                            onBlur={handleBlur}
                            helperText={errorQuestionText}
                            error={Boolean(faqErrors)}
                            disabled={viewOnly}
                        />
                    </Box>
                    <Box key={`Answer-${id}`}>
                        <CMPTypography
                            sx={{ flex: 1, marginTop: pxToRem(3) }}
                            text="Answer"
                        />
                        <CMPTextField
                            fullWidth={true}
                            rows={4}
                            value={item.answer}
                            name={`faq[${id}].answer`}
                            maxLength={999}
                            onChange={handleChange(id, 'answer')}
                            onBlur={handleBlur}
                            placeholder={cmpTexts.faqSection.answer}
                            helperText={errorAnswerText}
                            error={faqErrors}
                            disabled={viewOnly}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
            {faqErrors && (
                <FormHelperText error={faqErrors}>
                    <CMPTypography
                        sx={{ color: CMPColors.light.text.textField.error }}
                        variation={TypographyVariation.TYPO_SCALE_12}
                    >
                        {cmpTexts.faqSection.incomplete}
                    </CMPTypography>
                </FormHelperText>
            )}
        </>
    );
};

export default IndividualFAQComponent;
