import React from 'react';
import CMPTexts from '../../locale/en';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectMaintenanceBackgroumdImage } from '../../redux/maintenance/reducer';
import { useSelector } from 'react-redux';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const Container = styled('div')(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CMPColors.light.background.main,
    flexGrow: 1
}));

function UnderMaintenance() {
    const imageUrl = useSelector(selectMaintenanceBackgroumdImage);
    return (
        <Container style={{ minHeight: '50vh' }}>
            <Grid
                container
                style={{
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item>
                            <img
                                style={{ maxHeight: '40vh' }}
                                src={imageUrl}
                                alt="CMP is under Maintenance"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CMPTypography
                                variation={TypographyVariation.HEADER6}
                                text={CMPTexts.underMaintenance.title}
                            />
                            <CMPTypography
                                sx={{ whiteSpace: 'pre-line' }}
                                text={CMPTexts.underMaintenance.description}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UnderMaintenance;
