import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import CMPTexts from '../../locale/en';
import { ReactComponent as Upload } from '../../assets/svg/upload.svg';
import {
    assetOperationLoaderReset,
    getAssetLists,
    resetLoader
} from '../../redux/assets/action';
import {
    selectAssetOperationLoadingState,
    selectAssets,
    selectAssetsLoader,
    selectMessage
} from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import { pxToRem } from '../../utils/stylesUtils';
import AssetsTable from './assetsTable';
import UploadAssetModal, { MULTI_UPLOAD_FILE_LIMIT } from './uploadAssetModal';
import { Asset } from '../../redux/assets/model';
import { MediaType } from '../../redux/element/model';
import { getPropertyDetails } from '../../redux/propertylist/action';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import { shallowSearch } from '../../utils/searchUtil';
import CMPTextField, {
    TextVariation
} from '../../components/textFields/cmpTextField';
import { Box } from '@mui/material';
import CMPColors from '../../components/colors';

const AssetsWrapper = styled('div')(() => ({
    margin: `0 ${pxToRem(43)}`,
    textAlign: 'left',
    width: '100%'
}));

const InfoHeading = styled('div')(() => ({
    fontWeight: 500,
    position: 'relative'
}));
const InfoText = styled('span')(() => ({
    position: 'absolute',
    bottom: pxToRem(6),
    paddingLeft: pxToRem(10),
    color: CMPColors.light.text.secondary
}));

export const filterAssetsByType = (assets: Asset[], type: MediaType) => {
    switch (type) {
        case MediaType.Video:
        case MediaType.Image:
            return assets.filter(
                (a) => a.type.toLowerCase() === type.toLowerCase()
            );
        case MediaType.Image_Video:
            return assets.filter(
                (a) =>
                    a.type.toLowerCase() === MediaType.Image.toLowerCase() ||
                    a.type.toLowerCase() === MediaType.Video.toLowerCase()
            );
    }
};

const Assets = () => {
    const { showSnackbar } = useSnackbar();
    const { property } = useUrlParams();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const assetOperationLoader = useSelector(selectAssetOperationLoadingState);
    const loader = useSelector(selectAssetsLoader);
    const message = useSelector(selectMessage);
    const assets = useSelector(selectAssets);
    const [filteredAssets, setFilteredAssets] = useState<Asset[]>(assets);

    const onSearch = (searchText: string) => {
        setSearchText(searchText);
    };

    useEffect(() => {
        const filteredAssetsList = searchText
            ? shallowSearch(assets, searchText, ['name'])
            : assets;
        setFilteredAssets(filteredAssetsList);
    }, [searchText, assets]);

    useEffect(() => {
        if (loader === LoadingStatus.DONE) {
            dispatch(resetLoader());
        } else if (loader === LoadingStatus.FAILED) {
            showSnackbar(message, 'error');
            dispatch(resetLoader());
        }
    }, [loader]);

    useEffect(() => {
        if (assetOperationLoader === LoadingStatus.DONE) {
            dispatch(getAssetLists(property));
            dispatch(getPropertyDetails(property));
            dispatch(assetOperationLoaderReset());
            showSnackbar(message);
        } else if (assetOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(message, 'error');
            dispatch(assetOperationLoaderReset());
        }
    }, [assetOperationLoader]);

    return (
        <Fragment>
            {((!isUploadModalOpen &&
                assetOperationLoader === LoadingStatus.INITIATED) ||
                loader === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            <AssetsWrapper>
                <CMPTypography
                    variation={TypographyVariation.HEADER2}
                    sx={{ marginTop: pxToRem(20), marginBottom: pxToRem(20) }}
                >
                    {CMPTexts.assets.heading}
                </CMPTypography>
                <InfoHeading>
                    <InfoOutlinedIcon
                        sx={{ color: CMPColors.light.icon.default }}
                    />
                    <InfoText>{CMPTexts.assets.info}</InfoText>
                </InfoHeading>
                <Box
                    sx={{
                        padding: `${pxToRem(30)} 0`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        maxWidth: '95%',
                        alignItems: 'flex-end'
                    }}
                >
                    <CMPTextField
                        variation={TextVariation.SEARCH}
                        enableDebouce
                        sx={{
                            width: '50%'
                        }}
                        placeholder={'Search For Assets'}
                        onChange={onSearch}
                    />
                    <Button
                        buttonVariant={ButtonVariation.CONTAINED}
                        startIcon={<Upload />}
                        onClick={() => setIsUploadModalOpen(true)}
                    >
                        {CMPTexts.assets.uploadAssetButtonText}
                    </Button>
                </Box>
                {isUploadModalOpen && (
                    <UploadAssetModal
                        filesLimit={MULTI_UPLOAD_FILE_LIMIT}
                        setIsModalOpen={setIsUploadModalOpen}
                    />
                )}
                <AssetsTable property={property} assets={filteredAssets} />
            </AssetsWrapper>
        </Fragment>
    );
};

export default Assets;
