import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import PropertySectionToolTip from './propertySectionTooltip';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPColors from '../../../../components/colors';

interface Props {
    checked: boolean;
    handleChange: (value: boolean) => void;
    label: string | undefined;
    disabled?: boolean;
    tooltipMessage?: string;
}

const PropertySectionCheckBox = (props: Props) => {
    const {
        checked,
        handleChange,
        label,
        disabled = false,
        tooltipMessage
    } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <FormControlLabel
                className="checkBox"
                sx={{
                    color: CMPColors.light.text.button.secondary,
                    marginRight: pxToRem(6)
                }}
                control={
                    <Checkbox
                        disabled={disabled}
                        checked={checked}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                }
                label={label}
            />
            <PropertySectionToolTip
                tooltipMessage={tooltipMessage}
                sx={{ fontSize: pxToRem(20) }}
            />
        </Box>
    );
};

export default PropertySectionCheckBox;
