import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import CMPErrorHelperText from '../cmpErrorHelperText';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { VisibilityOffOutlined } from '@mui/icons-material';
import {
    BaseTextFieldProps,
    className,
    CMPTextFieldContainer,
    StyledTextField,
    TextVariation,
    Theme,
    TooltipComponent
} from './cmpTextField';
import CMPColors from '../colors';

export interface PasswordTextFieldProps extends BaseTextFieldProps {
    variation?: TextVariation.PASSWORD;
    error?: boolean;
    helperText?: string;
    tooltipMessage?: string;
    label?: string;
    required?: boolean;
    id?: string;
    shrink?: boolean;
    maxLength?: number;
    supportingTexts?: string;
    removeAutoFill?: boolean;
}

const PasswordTextFIeld = (props: PasswordTextFieldProps) => {
    const {
        onChange,
        label,
        fullWidth = true,
        helperText,
        error = false,
        required = false,
        sx,
        id,
        disabled,
        shrink = true,
        theme = Theme.LIGHT,
        value,
        tooltipMessage,
        backgroundTransparent,
        onFocus,
        onBlur,
        removeAutoFill
    } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <CMPTextFieldContainer
            sx={{ width: fullWidth ? '100%' : 'auto', ...sx }}
        >
            <StyledTextField
                sx={{
                    background: backgroundTransparent
                        ? 'rgba(0, 0, 16, 0.5)'
                        : theme === Theme.DARK
                        ? CMPColors.dark.background.textField
                        : 'inherit'
                }}
                value={value}
                variant="outlined"
                id={id}
                className={className([
                    theme === Theme.DARK
                        ? 'cmp-text-field text-theme-dark'
                        : 'cmp-text-field text-theme-light',
                    label ? '' : 'no-dropdown-label',
                    showPassword ? '' : 'cmp-hide-text'
                ])}
                type={
                    removeAutoFill ? 'text' : showPassword ? 'text' : 'password'
                }
                label={
                    <>
                        {label}
                        <TooltipComponent
                            theme={theme}
                            tooltipMessage={tooltipMessage}
                        />
                    </>
                }
                required={required}
                InputProps={{
                    endAdornment: !disabled && (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{ fontSize: 2 }}
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <VisibilityOutlinedIcon
                                        fontSize="small"
                                        sx={{
                                            color: CMPColors[
                                                theme === Theme.LIGHT
                                                    ? 'light'
                                                    : 'dark'
                                            ].icon.default
                                        }}
                                    />
                                ) : (
                                    <VisibilityOffOutlined
                                        fontSize="small"
                                        sx={{
                                            color: CMPColors[
                                                theme === Theme.LIGHT
                                                    ? 'light'
                                                    : 'dark'
                                            ].icon.default
                                        }}
                                    />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{ shrink: shrink }}
                fullWidth={fullWidth}
                onChange={(e) => onChange(e.target.value.trimStart() as string)}
                error={error}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {<CMPErrorHelperText text={helperText} theme={theme} />}
        </CMPTextFieldContainer>
    );
};

export default PasswordTextFIeld;
