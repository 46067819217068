import { ReactComponent as EditIcon } from '../../../../assets/svg/pencil.svg';
import { Box, Card, Grid, MenuItem, styled } from '@mui/material';
// import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import { DisableableBox } from '../../../../components/disableableBox';
import Tooltip from '../../../../components/tooltip';
import cmpTexts from '../../../../locale/en';
import { ElementListResponse } from '../../../../redux/elementsList/model';
import { selectPageList } from '../../../../redux/elementsList/reducer';
import { PropertyField } from '../../../../redux/propertyValue/model';
import { evaluateEditabilityCondition } from '../../../../utils/propertiesUtil';
import { pxToRem } from '../../../../utils/stylesUtils';
import LiveToggle from '../../propertyDetails/components/liveToggle';
import PropertySectionCheckBox from '../../propertyDetails/components/propertySectionCheckBox';
import PreviewButton from '../../preview/previewButton';
import WarningIcon from '@mui/icons-material/ReportProblem';
import CMPDropDown, {
    DropdownVariation
} from '../../../../components/cmpDropDown';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';
import IconStylizer from '../../../../components/iconStylizer';

interface PlatformPageCardProps {
    platform: string;
    pageField: PropertyField;
    siblingPages: PropertyField[];
    onChildFieldUpdate: (childIndex: number, value: any) => void;
    propertyId: string;
    showWarningIcon: boolean;
}

export enum TileTypes {
    SELECTABLE_PAGE = 'SELECTABLE_PAGE',
    PAGE = 'PAGE',
    TV_GUIDE_PAGE = 'TV_GUIDE_PAGE',
    SELECTABLE_PAGE_WITH_TITLE = 'SELECTABLE_PAGE_WITH_TITLE'
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const NameBadge = styled(Box)(() => ({
    cursor: 'pointer',
    color: CMPColors.dark.text.primary,
    background: CMPColors.dark.background.lightBg,
    marginRight: pxToRem(10),
    padding: pxToRem(6),
    borderRadius: '50%',
    width: pxToRem(30),
    height: pxToRem(30),
    fontWeight: 'bold',
    textAlign: 'center'
}));

const PlatformPageCard = (props: PlatformPageCardProps) => {
    const {
        platform,
        pageField,
        onChildFieldUpdate,
        propertyId,
        siblingPages,
        showWarningIcon
    } = props;

    const [selectedPage, setSelectedPage] = useState<ElementListResponse>();
    const pageList = useSelector(selectPageList);
    const tileType = pageField.configuration?.tileType;

    if (pageField.children === null) pageField.children = [];

    const pageElementFiledIndex = pageField.children.findIndex(
        (p: PropertyField) => p.key === 'pageElement'
    );
    const pageVisibilityFieldIndex = pageField.children.findIndex(
        (p: PropertyField) => p.key === 'isPageEnabled'
    );
    const pageTitleIndex = pageField.children.findIndex(
        (p: PropertyField) => p.key === 'pageTitle'
    );

    const pageElementConfig: PropertyField | null =
        pageElementFiledIndex !== -1
            ? pageField.children[pageElementFiledIndex]
            : null;

    const pageVisibilityConfig: PropertyField | null =
        pageVisibilityFieldIndex !== -1
            ? pageField.children[pageVisibilityFieldIndex]
            : null;

    const location = useLocation();
    const navigate = useNavigate();

    const CheckBoxWithLabel = (pf: PropertyField, disableCheckBox: boolean) => {
        const canEdit = pf?.access_details?.Edit ?? false;
        return (
            <DisableableBox
                disabled={
                    (disableCheckBox && isPageSelectionDisabled) || !canEdit
                }
            >
                <PropertySectionCheckBox
                    checked={Boolean(pf?.value)}
                    handleChange={handleCheckbox(pf?.key as string)}
                    label={pf?.name}
                />
            </DisableableBox>
        );
    };

    const autoScrollIndex = pageField.children?.findIndex(
        (i) => i.key === 'enableAutoScroll'
    );
    const miniGuideIndex = pageField.children?.findIndex(
        (i) => i.key === 'enableMiniGuide'
    );

    const getPlatformPageCard = (tileType: string) => {
        switch (tileType) {
            case TileTypes.SELECTABLE_PAGE:
            case TileTypes.SELECTABLE_PAGE_WITH_TITLE:
                return (
                    <>
                        {renderPageSelectDropdown && (
                            <Grid item xs={12}>
                                {canChangePage ? (
                                    <CMPDropDown
                                        value={pageElementConfig?.value}
                                        onChange={handlePageChange}
                                        label="Page"
                                        dropdownStyles={{
                                            maxHeight:
                                                ITEM_HEIGHT * 4.5 +
                                                ITEM_PADDING_TOP,
                                            width: 250
                                        }}
                                        sx={{
                                            width: '100%'
                                        }}
                                        disabled={isPageSelectionDisabled}
                                    >
                                        {filteredPageList?.map(
                                            (val: ElementListResponse) => {
                                                return (
                                                    <MenuItem
                                                        key={val.id}
                                                        value={val.id}
                                                    >
                                                        {val.name}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </CMPDropDown>
                                ) : (
                                    <CMPTypography
                                        variation={
                                            TypographyVariation.TYPO_SCALE_18
                                        }
                                        text={
                                            filteredPageList.find(
                                                (fp) =>
                                                    fp.id ===
                                                    pageElementConfig?.value
                                            )?.name
                                        }
                                    />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <CMPTypography
                                variation={TypographyVariation.TYPO_SCALE_12}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: pxToRem(40)
                                }}
                            >
                                {selectedPage?.modified_by && (
                                    <Tooltip message={selectedPage.modified_by}>
                                        <NameBadge>
                                            {getNameShortForm(
                                                selectedPage.modified_by
                                            )}
                                        </NameBadge>
                                    </Tooltip>
                                )}
                                {`Page last edited ${moment(
                                    selectedPage?.modified_date
                                ).calendar({
                                    sameElse: `M/D/YY [at] h:mm A`
                                })}`}
                            </CMPTypography>
                        </Grid>
                        {selectedPage && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    paddingTop: pxToRem(8),
                                    paddingLeft: pxToRem(16),
                                    flex: 1,
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    startIcon={
                                        <IconStylizer
                                            sx={{
                                                height: '28px'
                                            }}
                                            height={pxToRem(25)}
                                            width={pxToRem(22)}
                                        >
                                            <EditIcon />
                                        </IconStylizer>
                                    }
                                    buttonVariant={ButtonVariation.CONTAINED}
                                    onClick={handlePageLinkClick}
                                    buttonSize={ButtonSize.SMALL}
                                    sx={{
                                        height: pxToRem(40),
                                        width: pxToRem(100),
                                        borderRadius: pxToRem(4)
                                    }}
                                >
                                    {cmpTexts.propertyDetails.edit}
                                </Button>

                                <PreviewButton
                                    pageId={selectedPage.id}
                                    propertyId={propertyId}
                                    platform={platform}
                                    label={cmpTexts.preview.preview}
                                />
                            </Box>
                        )}
                    </>
                );
            case TileTypes.TV_GUIDE_PAGE:
                return (
                    <>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    paddingTop: pxToRem(28),
                                    paddingLeft: pxToRem(28)
                                }}
                            >
                                {autoScrollIndex &&
                                    pageField?.children?.[autoScrollIndex] &&
                                    CheckBoxWithLabel(
                                        pageField?.children?.[autoScrollIndex],
                                        true
                                    )}
                            </Box>
                        </Grid>
                        <Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    display: 'flex',
                                    width: '100%',
                                    height: '36%',
                                    background:
                                        CMPColors.light.background
                                            .manageDevicesCard.secondary,
                                    paddingLeft: pxToRem(46),
                                    alignItems: 'center'
                                }}
                            >
                                {miniGuideIndex &&
                                    pageField?.children?.[miniGuideIndex] &&
                                    CheckBoxWithLabel(
                                        pageField?.children?.[miniGuideIndex],
                                        false
                                    )}
                            </Box>
                        </Box>
                    </>
                );
            case TileTypes.PAGE:
            default:
                return (
                    <>
                        <Grid item xs={12}>
                            <CMPTypography
                                variation={TypographyVariation.TYPO_SCALE_18}
                                text={pageField.description}
                            />
                        </Grid>
                    </>
                );
        }
    };

    useEffect(() => {
        setSelectedPage(
            pageList?.find(
                (p: ElementListResponse) => p.id === pageElementConfig?.value
            )
        );
    }, [pageList, pageElementConfig]);

    const handlePageLinkClick = () => {
        if (!selectedPage) return;

        navigate(`${location.pathname}/elements/${selectedPage.id}/canvas`);
    };

    const handlePageChange = (value: string) => {
        if (pageElementFiledIndex !== -1)
            onChildFieldUpdate(pageElementFiledIndex, value);
    };

    const handleChangeName = (value: string) => {
        onChildFieldUpdate(pageTitleIndex, value);
    };

    const handlePageVisibilityChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (pageVisibilityFieldIndex === -1) return;

        if (!e.target.checked && tileType === TileTypes.TV_GUIDE_PAGE) {
            const keyIndex = pageField?.children?.findIndex(
                (p: PropertyField) => p.key === 'enableAutoScroll'
            );
            if (keyIndex !== undefined && keyIndex > -1)
                onChildFieldUpdate(keyIndex, false);
        }
        onChildFieldUpdate(pageVisibilityFieldIndex, e.target.checked);
    };

    const handleCheckbox = (key: string) => (value: boolean) => {
        const keyIndex = pageField.children?.findIndex(
            (p: PropertyField) => p.key === key
        );

        if (keyIndex !== undefined || keyIndex === -1)
            onChildFieldUpdate(keyIndex, value);
    };

    const isPageSelectionDisabled =
        pageVisibilityConfig != null && !pageVisibilityConfig.value;

    const pageFilter =
        pageField?.children?.find((child) => child.key === 'pageType')?.value ??
        false;

    const filteredPageList = pageList.filter(
        (page) => page.widget_type_id === pageFilter
    );

    const pageFilterTitle = pageField.children?.find(
        (child) => child.key === 'pageTitle'
    );

    const renderPageSelectDropdown =
        (pageField?.children?.findIndex(
            (child) => child.key === 'pageElement'
        ) ?? -1) > -1;

    const canChangePage = pageField.access_details?.PageSelection;

    const getNameShortForm = (name: string) => {
        let shortForm = '';
        name.trim()
            .split(' ')
            .filter(Boolean)
            .slice(0, 2)
            .forEach((i) => (shortForm += i[0]));

        return shortForm.toUpperCase();
    };

    const renderPageVisibilityToggle = (
        pageVisibilityConfig: PropertyField
    ): React.ReactElement => {
        const [canEdit, message] = evaluateEditabilityCondition(
            pageVisibilityConfig,
            siblingPages
        );

        return (
            <DisableableBox disabled={!canEdit} disableReason={message}>
                <LiveToggle
                    handleChange={handlePageVisibilityChange}
                    checked={Boolean(pageVisibilityConfig.value)}
                    label={pageVisibilityConfig.value ? 'LIVE' : 'OFF'}
                />
            </DisableableBox>
        );
    };

    if (!tileType) return <></>;

    return (
        <Card
            sx={{
                margin: pxToRem(12),
                padding: `${pxToRem(12)} ${pxToRem(20)}`,
                background:
                    CMPColors.light.background.manageDevicesCard.primary,
                height: pxToRem(230),
                borderRadius: pxToRem(14),
                position: 'relative',
                boxShadow: 'none',
                width: '100%'
            }}
        >
            <Grid container spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        padding: pxToRem(16),
                        paddingRight: 0,
                        paddingBottom: 0,
                        flex: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: '1',
                            alignItems: 'center'
                        }}
                    >
                        {tileType === TileTypes.SELECTABLE_PAGE_WITH_TITLE && (
                            <CMPDropDown
                                variation={DropdownVariation.DROPDOWN_NO_BORDER}
                                onChange={handleChangeName}
                                sx={{
                                    marginRight: pxToRem(10),
                                    top: -2,
                                    width: '88%',
                                    '&.no-dropdown-label.drop-down-container': {
                                        '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input':
                                            {
                                                '& .MuiTypography-root.MuiTypography-body1 ':
                                                    {
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        'text-overflow':
                                                            'ellipsis',
                                                        display: 'block',
                                                        maxWidth: '90%'
                                                    },
                                                padding: 0,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }
                                    }
                                }}
                                value={pageFilterTitle?.value}
                            >
                                {pageFilterTitle?.configuration?.options?.map(
                                    (val: string) => {
                                        return (
                                            <MenuItem
                                                sx={{ padding: pxToRem(10) }}
                                                key={val}
                                                value={val}
                                            >
                                                <CMPTypography
                                                    variation={
                                                        TypographyVariation.HEADER5
                                                    }
                                                    sx={{
                                                        color: CMPColors.light
                                                            .text.primary,
                                                        textOverflow:
                                                            'ellipsis',
                                                        width: 'calc(100% - 30px)',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {val}
                                                </CMPTypography>
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </CMPDropDown>
                        )}
                        {tileType !== TileTypes.SELECTABLE_PAGE_WITH_TITLE && (
                            <CMPTypography
                                variation={TypographyVariation.HEADER5}
                                sx={{
                                    color: CMPColors.light.text.primary
                                }}
                            >
                                {pageField.name}
                            </CMPTypography>
                        )}
                        {showWarningIcon && (
                            <WarningIcon
                                sx={{
                                    fill: CMPColors.light.toast.warning,
                                    marginLeft: pxToRem(6)
                                }}
                                fontSize={'small'}
                            />
                        )}
                    </Box>
                    {pageVisibilityConfig != null &&
                        renderPageVisibilityToggle(pageVisibilityConfig)}
                </Box>
                {getPlatformPageCard(tileType)}
            </Grid>
        </Card>
    );
};

export default PlatformPageCard;
