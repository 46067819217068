import { DragIndicator } from '@mui/icons-material';
import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import React from 'react';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPColors from '../../../../components/colors';

const DragHandle = SortableHandle((props: { position: 'top' | 'center' }) => {
    return (
        <Tooltip title={'Hold and Drag'}>
            <DragIndicator
                sx={{
                    color: CMPColors.dark.icon.primary,
                    alignSelf: 'center',
                    cursor: 'move',
                    position: 'absolute',
                    top: props.position === 'top' ? '5%' : '50%',
                    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 16, 1))',
                    paddingLeft: pxToRem(5),
                    zIndex: 1
                }}
            />
        </Tooltip>
    );
});

interface SortableWidgetItemProps {
    children: React.ReactNode;
    enableSort?: boolean;
    sx?: SxProps<Theme>;
    useDragHandle?: boolean;
    dragHandlePosition?: 'top' | 'center';
}

export const SortableWidgetItem = SortableElement(
    ({ children, sx, enableSort }: SortableWidgetItemProps) => {
        return (
            <Box sx={{ display: 'flex', ...sx, position: 'relative' }}>
                {enableSort && <DragHandle position="top" />}
                {children}
            </Box>
        );
    }
);

interface SortableWidgetContainerProps {
    children: React.ReactNode;
}

export const SortableWidgetContainer = SortableContainer(
    ({ children }: SortableWidgetContainerProps) => {
        return <Box display="flex">{children}</Box>;
    }
);

export const SortableRailContainer = SortableContainer(
    ({ children }: SortableWidgetContainerProps) => {
        return (
            <Box display="flex" flexDirection="column">
                {children}
            </Box>
        );
    }
);

export const SortableRailItem = SortableElement(
    ({ children, sx, enableSort = true }: SortableWidgetItemProps) => {
        return (
            <Box
                sx={{
                    ...sx,
                    position: 'relative'
                }}
            >
                {enableSort && <DragHandle position="center" />}
                {children}
            </Box>
        );
    }
);
