import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as EditIcon } from '../../../assets/svg/pencil.svg';
import DoneIcon from '@mui/icons-material/Done';
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';
import { Box, FormHelperText, Grid, IconButton, Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { DisableableBox } from '../../../components/disableableBox';
import FullScreenCircularLoader from '../../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../../components/snackbar';
import StickySaveBar from '../../../components/stickySaveBar';
import { useUrlParams } from '../../../hooks/useParam';
import { selectAssetOperationLoadingState } from '../../../redux/assets/reducer';
import { LoadingStatus } from '../../../redux/common/state';
import { fetchElements } from '../../../redux/elementsList/action';
import {
    getPropertyDetails,
    purgeRedirectToPropertyId,
    registerProperty,
    resetPropertOperationsLoader
} from '../../../redux/propertylist/action';
import { PropertyDetails } from '../../../redux/propertylist/model';
import {
    selectCurrentPropertyDetails,
    selectPropertyMessage,
    selectPropertyOperationLoader
} from '../../../redux/propertylist/reducer';
import {
    getFieldConfigValues,
    resetGetFieldConfigValuesResponse,
    resetPropertyValueLoader,
    resetPropertyValueOperationLoader,
    updatePropertyMultipleFieldValue
} from '../../../redux/propertyValue/action';
import {
    emptyPropertyFieldValues,
    PropertyField
} from '../../../redux/propertyValue/model';
import {
    selectPropertyField,
    selectPropertyThemeAndAssets,
    selectPropertyValueLoader,
    selectPropertyValueOperationLoader
} from '../../../redux/propertyValue/reducer';
import { selectPropertyEditAllowed } from '../../../redux/userOperations/reducer';
import { updateZoneFields } from '../../../redux/zones/action';
import { ZoneFields } from '../../../redux/zones/model';
import { removeEmojis } from '../../../utils/stringUtils';
import { pxToRem } from '../../../utils/stylesUtils';
import usePropertyFieldUpdatePayloadCreator from '../hooks/usePropertyFieldPayloadCreator';
import SmartBoxDetailsForm from '../smartBoxDetailsForm';
import ManageZones from '../zones/manageZones';
import PropertyDetailsNavigationPrompt from './propertyDetailsNavigationPrompt';
import PropertyThemeAndAssetsSection from './propertyThemeAndAssetSection';
import CMPTypography, {
    TypographyVariation
} from '../../../components/textFields/cmpTypography';
import cmpTexts from '../../../locale/en';
import CMPColors from '../../../components/colors';
import IconStylizer from '../../../components/iconStylizer';

const propertyDetailsValidationSchema = Yup.object().shape({
    smartbox_id: Yup.string().trim().required('*Required'),
    zip_code: Yup.string().required('*Required').min(5, 'Must be 5 digits'),
    name: Yup.string()
        .required('*Required')
        .trim()
        .min(3, 'Should be atleast 3 characters')
});

const PageContainerGrid = styled(Grid)(() => ({
    width: '100%',
    backgroundColor: CMPColors.light.background.main,
    padding: `${pxToRem(24)} ${pxToRem(48)} ${pxToRem(48)}`
}));

const PropertyNameSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const PropertyNameInput = styled(Input)(() => ({
    fontSize: '1.5rem',
    textAlign: 'start',
    width: pxToRem(400),
    height: pxToRem(28)
}));

const InputSmartBox = styled(Input)(() => ({
    fontSize: '1.1rem',
    display: 'flex',
    flex: 1
}));

const SmartBoxAlert = styled(Box)(() => ({
    width: '98%',
    marginTop: pxToRem(32),
    marginBottom: pxToRem(32)
}));

const VerticalRulesIcon = styled(HorizontalRuleSharpIcon)(() => ({
    boxSizing: 'border-box',
    height: `${pxToRem(23)}`,
    width: `${pxToRem(1)}`,
    border: `${pxToRem(1)} solid ${CMPColors.light.stroke.default}`
}));

const CursorPointer = styled('div')(() => ({
    cursor: 'pointer',
    paddingLeft: 5,
    paddingRight: 5
}));

const IconMainDiv = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const SmartBoxSection = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
}));

const SmartBoxBorder = styled('div')(() => ({
    display: 'flex',
    borderBottom: `${pxToRem(2)} solid`,
    marginBottom: pxToRem(7),
    fontSize: '1.1rem',
    marginLeft: pxToRem(13),
    flex: 1,
    maxWidth: 'fit-content'
}));

const PropertyNameBorder = styled('div')(() => ({
    borderBottom: `${pxToRem(2)} solid`,
    marginLeft: pxToRem(10),
    display: 'flex',
    alignItems: 'center'
}));

const PropertyNameUpdate = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    height: 'max-content'
}));

const PropertyNameEditIcon = styled('div')(() => ({
    marginLeft: '1rem',
    marginTop: 3,
    cursor: 'pointer'
}));

const SmartBoxIdBeforeEdit = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    paddingBottom: pxToRem(15)
}));

const EditSmartBoxZipCodeContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
}));

const BoxIdUpdatedValue = styled('div')(() => ({
    fontSize: '1.1rem',
    margin: `0 ${pxToRem(12)}`,
    display: 'flex',
    flex: 0.6,
    minWidth: 300,
    alignItems: 'center',
    justifyContent: 'right'
}));

const SmartboxIdView = styled('div')(() => ({
    fontSize: '1.1rem',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

const BoxIdEditIcon = styled('div')(() => ({
    marginLeft: '1.2vw',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
}));

const BoxIdSection = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    marginRight: '1vw'
}));

const PropertyNameColor = styled('div')(() => ({
    color: CMPColors.brandColor,
    marginLeft: '0.6vw'
}));

const SmartBoxMargin = styled('div')(() => ({
    fontSize: '1.1rem',
    marginLeft: '0.7vw',
    display: 'flex',
    justifyContent: 'right'
}));

const SmartboxSubContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    marginRight: '1vw'
}));

const SmartboxInputContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1
}));

const CreateEditIcon = styled(EditIcon)(() => ({
    width: `${pxToRem(20)}`
}));

const IconPropertyMainDiv = styled(Box)(() => ({
    display: 'flex',
    height: '1.5rem',
    maxHeight: '2rem',
    '-webkit-align-items': 'center',
    '-webkit-box-align': 'center',
    '-ms-flex-align': 'center',
    alignItems: 'center'
}));

const LabledInputBox = (props: {
    label: string;
    value: string;
    onClick: () => void;
    showEditBox: boolean;
    enableEditCondition: boolean;
    canEdit?: boolean;
    minLength?: number;
    maxLength?: number;
    onChange: (e: React.ChangeEvent<any>) => void;
    onEnter: (e: React.KeyboardEvent) => void;
    inputBoxName: string;
    saveIconClick: (e: React.SyntheticEvent) => void;
    cancelIconClick: () => void;
    validation: Object | Object[] | string | number | undefined;
}): JSX.Element => {
    const {
        showEditBox,
        label,
        value,
        onClick,
        inputBoxName,
        minLength,
        maxLength,
        onChange,
        onEnter,
        enableEditCondition,
        saveIconClick,
        cancelIconClick,
        validation,
        canEdit = false
    } = props;

    const [dataValue, setDataValue] = useState(value);
    useEffect(() => {
        if (value) {
            setDataValue(value);
        }
    }, [value]);

    return (
        <>
            {!showEditBox && (
                <SmartBoxIdBeforeEdit>
                    <BoxIdSection>
                        <BoxIdUpdatedValue>{label}</BoxIdUpdatedValue>
                        <SmartboxSubContainer>
                            <SmartboxIdView>{dataValue}</SmartboxIdView>
                            <BoxIdEditIcon>
                                {canEdit && (
                                    <IconStylizer
                                        color={CMPColors.light.icon.default}
                                    >
                                        <CreateEditIcon onClick={onClick} />
                                    </IconStylizer>
                                )}
                            </BoxIdEditIcon>
                        </SmartboxSubContainer>
                    </BoxIdSection>
                </SmartBoxIdBeforeEdit>
            )}
            {showEditBox && (
                <>
                    <SmartBoxSection>
                        <SmartBoxMargin>{label}</SmartBoxMargin>
                        <SmartBoxBorder>
                            <SmartboxInputContainer>
                                <InputSmartBox
                                    required
                                    id={inputBoxName}
                                    name={inputBoxName}
                                    inputProps={{
                                        minLength: minLength,
                                        maxLength: maxLength
                                    }}
                                    value={value.trimStart()}
                                    onChange={onChange}
                                    disableUnderline={true}
                                    onKeyPress={onEnter}
                                />
                            </SmartboxInputContainer>

                            {enableEditCondition && (
                                <IconMainDiv>
                                    <CursorPointer
                                        sx={
                                            validation
                                                ? {
                                                      opacity: 0.5,
                                                      pointerEvents: 'none'
                                                  }
                                                : {}
                                        }
                                    >
                                        <DoneIcon
                                            onClick={saveIconClick}
                                            sx={{
                                                color: CMPColors.light.icon
                                                    .default
                                            }}
                                        />
                                    </CursorPointer>
                                    <VerticalRulesIcon />
                                    <CursorPointer>
                                        <ClearIcon
                                            onClick={cancelIconClick}
                                            sx={{
                                                color: CMPColors.light.icon
                                                    .default
                                            }}
                                        />
                                    </CursorPointer>
                                </IconMainDiv>
                            )}
                        </SmartBoxBorder>
                    </SmartBoxSection>
                    <FormHelperText
                        error={Boolean(validation)}
                        sx={{ textAlign: 'end' }}
                    >
                        {validation}
                    </FormHelperText>
                </>
            )}
        </>
    );
};

function OnStreamDesign() {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { property } = useUrlParams();
    const [showRegisterAlert, setShowRegisterAlert] = useState(false);
    const propertyDetails = useSelector(selectCurrentPropertyDetails);
    const propertyField = useSelector(selectPropertyField);
    const themesAndAssets = useSelector(selectPropertyThemeAndAssets);
    const propertyValueLoader = useSelector(selectPropertyValueLoader);
    const assetOperationLoader = useSelector(selectAssetOperationLoadingState);
    const propertyOperationLoader = useSelector(selectPropertyOperationLoader);
    const propertyFieldValueOperationLoader = useSelector(
        selectPropertyValueOperationLoader
    );
    const propertyOperationMessage = useSelector(selectPropertyMessage);
    const editPropertyAllowed = useSelector(selectPropertyEditAllowed);
    const [showSaveBar, setShowSaveBar] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorByKey, setErrorByKey] = useState<{ [key: string]: boolean }>(
        {}
    );

    const [propertyLogoAssetId, setPropertyLogoAssetId] = useState<string>('');
    const [shouldResetForm, setShouldResetForm] = useState(false);
    const [propertyNameEditMode, setPropertyNameEditMode] = useState(false);
    const [smartboxIdEditMode, setSmartboxIdEditMode] = useState(false);
    const [zipCodeEditMode, setZipCodeEditMode] = useState(false);
    const [zoneFields, setUpdateZoneFields] = useState<ZoneFields>({});
    const propertyFieldPayloadCreator = usePropertyFieldUpdatePayloadCreator();

    const propertyDetailsFormik = useFormik<PropertyDetails>({
        enableReinitialize: true,
        initialValues: propertyDetails,
        validationSchema: propertyDetailsValidationSchema,
        onSubmit: (values) => {}
    });

    useEffect(() => {
        dispatch(getFieldConfigValues(property));
        dispatch(fetchElements(property));
        dispatch(purgeRedirectToPropertyId());

        return () => {
            dispatch(resetGetFieldConfigValuesResponse());
        };
    }, []);

    // solves auto-scrolling to page bottom
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (propertyFieldValueOperationLoader === LoadingStatus.DONE) {
            showSnackbar(cmpTexts.OnStreamDesignTexts.fieldUpdateSuccess);
            dispatch(getFieldConfigValues(property));
            dispatch(resetPropertyValueOperationLoader());
        } else if (propertyFieldValueOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(
                cmpTexts.OnStreamDesignTexts.filedUpdateFailed,
                'error'
            );
            dispatch(getFieldConfigValues(property));
            dispatch(resetPropertyValueOperationLoader());
        }
        handleResetStateVariables();
    }, [propertyFieldValueOperationLoader]);

    useEffect(() => {
        if (propertyOperationLoader === LoadingStatus.DONE) {
            resetPropertyDetailsEditMode();
            dispatch(getPropertyDetails(property));
            showSnackbar(cmpTexts.PropertyTexts.proopertyEditSuccess);
            dispatch(resetPropertOperationsLoader());
        } else if (propertyOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(propertyOperationMessage, 'error');
            dispatch(resetPropertOperationsLoader());
        }
    }, [propertyOperationLoader]);

    useEffect(() => {
        if (propertyValueLoader === LoadingStatus.DONE) {
            dispatch(resetPropertyValueLoader());
        } else if (propertyValueLoader === LoadingStatus.FAILED) {
            navigate('/properties');
            dispatch(resetPropertyValueLoader());
        }
    }, [propertyValueLoader]);

    useEffect(() => {
        if (
            !isEmpty(propertyDetails.id) &&
            isEmpty(propertyDetails.smartbox_id)
        ) {
            setShowRegisterAlert(true);
        } else {
            setShowRegisterAlert(false);
        }
    }, [propertyDetails]);

    useEffect(() => {
        setHasError(Object.keys(errorByKey).some((key) => errorByKey[key]));
    }, [JSON.stringify(errorByKey)]);

    const handleError = (hasError: boolean, key: string) => {
        setErrorByKey((prevProps) => ({
            ...prevProps,
            [key]: hasError
        }));
    };

    const originalPropertyLogoAssetId =
        themesAndAssets
            ?.find((pf) => pf.key === 'assets')
            ?.children?.find(
                (child) => (child.value_type as string) === 'Image'
            )?.value ?? '';

    useEffect(() => {
        setPropertyLogoAssetId(originalPropertyLogoAssetId as string);
    }, [originalPropertyLogoAssetId]);

    function handleResetStateVariables() {
        setShowSaveBar(false);
        setPropertyLogoAssetId(originalPropertyLogoAssetId as string);
        propertyFieldPayloadCreator.clearPayload();
    }

    function handleSave() {
        if (!isEmpty(propertyFieldPayloadCreator.payload)) {
            dispatch(
                updatePropertyMultipleFieldValue({
                    property_id: property,
                    fields: propertyFieldPayloadCreator.payload
                })
            );
        }

        if (!isEmpty(zoneFields)) {
            dispatch(
                updateZoneFields({
                    property_id: property,
                    fields: zoneFields
                })
            );
        }
    }

    function handleCancel() {
        handleResetStateVariables();
        setShouldResetForm(true);
    }

    const resetPropertyDetailsEditMode = () => {
        setPropertyNameEditMode(false);
        setSmartboxIdEditMode(false);
        setZipCodeEditMode(false);
    };

    const handlePropertyDetailsEditCancel = () => {
        resetPropertyDetailsEditMode();
        propertyDetailsFormik.setValues(propertyDetails);
    };

    function handlePropertyDetailsEditClick(section: string) {
        setPropertyNameEditMode(section === 'name');
        setSmartboxIdEditMode(section === 'smartboxId');
        setZipCodeEditMode(section === 'zipCode');
    }

    const handlePropertyDetailsSave = (e: React.SyntheticEvent) => {
        if (propertyDetailsFormik.values.name) {
            dispatch(
                registerProperty(
                    propertyDetailsFormik.values.name,
                    propertyDetailsFormik.values.smartbox_id,
                    property,
                    undefined,
                    propertyDetailsFormik.values.zip_code
                )
            );
        }
    };

    const handleEnterKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handlePropertyDetailsSave(e);
        }
    };

    const shouldShowLoadingBar =
        propertyValueLoader === LoadingStatus.INITIATED ||
        propertyFieldValueOperationLoader === LoadingStatus.INITIATED ||
        propertyOperationLoader === LoadingStatus.INITIATED ||
        assetOperationLoader === LoadingStatus.INITIATED;

    if (shouldShowLoadingBar && propertyField === emptyPropertyFieldValues) {
        return <FullScreenCircularLoader />;
    }

    const propertyNameErrors = propertyDetailsFormik?.errors?.name;

    return (
        <>
            {shouldShowLoadingBar && <FullScreenCircularLoader />}
            <PageContainerGrid alignItems="center" justifyContent="center">
                <PropertyNameSection>
                    {!propertyNameEditMode ? (
                        <PropertyNameUpdate>
                            <CMPTypography
                                variation={TypographyVariation.HEADER2}
                                sx={{
                                    display: 'flex',
                                    textAlign: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Box>
                                    {propertyField.property_group_name + ':'}
                                </Box>
                                <PropertyNameColor>
                                    {propertyDetailsFormik.values.name}
                                </PropertyNameColor>
                                <PropertyNameEditIcon>
                                    {editPropertyAllowed && (
                                        <IconStylizer
                                            color={CMPColors.light.icon.default}
                                        >
                                            <EditIcon
                                                onClick={() =>
                                                    handlePropertyDetailsEditClick(
                                                        'name'
                                                    )
                                                }
                                            />
                                        </IconStylizer>
                                    )}
                                </PropertyNameEditIcon>
                            </CMPTypography>
                        </PropertyNameUpdate>
                    ) : (
                        <PropertyNameUpdate>
                            <CMPTypography
                                variation={TypographyVariation.HEADER2}
                                sx={{
                                    display: 'flex',
                                    textAlign: 'start',
                                    alignItems: 'start'
                                }}
                            >
                                {propertyField.property_group_name + ':'}
                                <Box>
                                    <PropertyNameBorder>
                                        <PropertyNameInput
                                            required
                                            id="propertyName"
                                            name="name"
                                            value={propertyDetailsFormik.values.name.trimStart()}
                                            inputProps={{
                                                minLength: 3,
                                                maxLength: 35
                                            }}
                                            onChange={(e) => {
                                                e.target.value = removeEmojis(
                                                    e.target.value
                                                );
                                                propertyDetailsFormik.handleChange(
                                                    e
                                                );
                                            }}
                                            disableUnderline={true}
                                            onKeyPress={handleEnterKeyPress}
                                        />

                                        <IconPropertyMainDiv>
                                            <CursorPointer>
                                                <IconButton
                                                    onClick={
                                                        handlePropertyDetailsSave
                                                    }
                                                    disabled={Boolean(
                                                        propertyNameErrors
                                                    )}
                                                >
                                                    <DoneIcon
                                                        sx={{
                                                            color: CMPColors
                                                                .light.icon
                                                                .default
                                                        }}
                                                    />
                                                </IconButton>
                                            </CursorPointer>
                                            <VerticalRulesIcon />
                                            <CursorPointer>
                                                <IconButton
                                                    onClick={
                                                        handlePropertyDetailsEditCancel
                                                    }
                                                >
                                                    <ClearIcon
                                                        sx={{
                                                            color: CMPColors
                                                                .light.icon
                                                                .default
                                                        }}
                                                    />
                                                </IconButton>
                                            </CursorPointer>
                                        </IconPropertyMainDiv>
                                    </PropertyNameBorder>
                                    {Boolean(propertyNameErrors) && (
                                        <FormHelperText
                                            error={Boolean(propertyNameErrors)}
                                        >
                                            <CMPTypography
                                                sx={{
                                                    textAlign: 'end',
                                                    color: CMPColors.light.text
                                                        .textField.error
                                                }}
                                                variation={
                                                    TypographyVariation.TYPO_SCALE_12
                                                }
                                            >
                                                {propertyNameErrors}
                                            </CMPTypography>
                                        </FormHelperText>
                                    )}
                                </Box>
                            </CMPTypography>
                        </PropertyNameUpdate>
                    )}
                    {!showRegisterAlert && (
                        <EditSmartBoxZipCodeContainer>
                            <LabledInputBox
                                key="smartBox"
                                label="SMARTBOX Location ID : "
                                value={propertyDetailsFormik.values.smartbox_id.trimStart()}
                                validation={
                                    propertyDetailsFormik.errors.smartbox_id
                                }
                                onClick={() =>
                                    handlePropertyDetailsEditClick('smartboxId')
                                }
                                showEditBox={
                                    Boolean(propertyDetails?.smartbox_id) &&
                                    smartboxIdEditMode &&
                                    !showRegisterAlert
                                }
                                enableEditCondition={smartboxIdEditMode}
                                minLength={3}
                                canEdit={editPropertyAllowed}
                                onChange={(e) => {
                                    e.target.value = removeEmojis(
                                        e.target.value
                                    );
                                    propertyDetailsFormik.handleChange(e);
                                }}
                                onEnter={handleEnterKeyPress}
                                inputBoxName="smartbox_id"
                                saveIconClick={handlePropertyDetailsSave}
                                cancelIconClick={
                                    handlePropertyDetailsEditCancel
                                }
                            />

                            <LabledInputBox
                                key="zip-code"
                                label="Zip Code : "
                                value={
                                    propertyDetailsFormik.values.zip_code ?? ''
                                }
                                validation={
                                    propertyDetailsFormik.errors.zip_code
                                }
                                onClick={() =>
                                    handlePropertyDetailsEditClick('zipCode')
                                }
                                showEditBox={
                                    Boolean(propertyDetails?.smartbox_id) &&
                                    zipCodeEditMode &&
                                    !showRegisterAlert
                                }
                                enableEditCondition={zipCodeEditMode}
                                canEdit={editPropertyAllowed}
                                minLength={5}
                                maxLength={5}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    e.preventDefault();
                                    propertyDetailsFormik.setFieldValue(
                                        'zip_code',
                                        e.target.value.replace(/[^0-9]/g, '')
                                    );
                                }}
                                onEnter={handleEnterKeyPress}
                                inputBoxName="zip_code"
                                saveIconClick={handlePropertyDetailsSave}
                                cancelIconClick={
                                    handlePropertyDetailsEditCancel
                                }
                            />
                        </EditSmartBoxZipCodeContainer>
                    )}
                </PropertyNameSection>
                {showRegisterAlert && (
                    <SmartBoxAlert>
                        <SmartBoxDetailsForm propertyId={property} />
                    </SmartBoxAlert>
                )}

                <DisableableBox disabled={showRegisterAlert}>
                    {!isEmpty(propertyField) && (
                        <>
                            {!isEmpty(themesAndAssets) && (
                                <PropertyThemeAndAssetsSection
                                    themesAndAssets={
                                        themesAndAssets as PropertyField[]
                                    }
                                    setShowSaveBar={setShowSaveBar}
                                    setHasError={(hasError: boolean) => {
                                        handleError(hasError, 'themeAndAssets');
                                    }}
                                    updateSubmitPayload={
                                        propertyFieldPayloadCreator.updatePropertyFieldPayload
                                    }
                                    setShouldResetForm={setShouldResetForm}
                                    shouldResetForm={shouldResetForm}
                                    setPropertyLogoAssetId={
                                        setPropertyLogoAssetId
                                    }
                                />
                            )}

                            <CMPTypography
                                variation={TypographyVariation.HEADER3}
                                text={
                                    cmpTexts.OnStreamDesignTexts.manageDevices
                                }
                                sx={{
                                    paddingTop: pxToRem(40),
                                    paddingBottom: pxToRem(10)
                                }}
                            />

                            <ManageZones
                                setShowSaveBar={setShowSaveBar}
                                propertyId={property}
                                onUpdateZoneFields={setUpdateZoneFields}
                                setShouldResetForm={setShouldResetForm}
                                shouldResetForm={shouldResetForm}
                                setHasError={(hasError: boolean) => {
                                    handleError(hasError, 'zones');
                                }}
                                propertyLogoAssetId={propertyLogoAssetId}
                            />
                        </>
                    )}
                </DisableableBox>
            </PageContainerGrid>
            <PropertyDetailsNavigationPrompt
                shouldBlockNavigation={
                    showSaveBar || propertyDetailsFormik.dirty
                }
            />
            {showSaveBar && (
                <StickySaveBar
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    enableSaveButton={!hasError}
                />
            )}
        </>
    );
}

export default OnStreamDesign;
