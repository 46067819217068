import * as React from 'react';
import NavigationPrompt from 'react-router-prompt';
import Button, {
    ButtonVariation,
    ButtonSize
} from '../../../components/button';
import CMPDialog, {
    CMPDialogContent,
    CMPDialogActions
} from '../../../components/cmpDialog';
import cmpTexts from '../../../locale/en';
import { pxToRem } from '../../../utils/stylesUtils';
import CMPTypography, {
    TypographyStyles,
    TypographyVariation
} from '../../../components/textFields/cmpTypography';
import CMPColors from '../../../components/colors';

interface PropertyDetailsNavigationPromptProps {
    shouldBlockNavigation: boolean;
}

const PropertyDetailsNavigationPrompt = ({
    shouldBlockNavigation
}: PropertyDetailsNavigationPromptProps) => (
    <NavigationPrompt when={shouldBlockNavigation}>
        {({ onConfirm, onCancel }: { onConfirm: any; onCancel: any }) => {
            if (shouldBlockNavigation) {
                return (
                    <CMPDialog maxWidth="sm" onClose={onCancel}>
                        <CMPTypography
                            variation={TypographyVariation.HEADER2}
                            sx={{ padding: 0, marginBottom: pxToRem(16) }}
                            text={
                                cmpTexts.PropertyNavigationModalTexts
                                    .modalHeading
                            }
                        />
                        <CMPDialogContent
                            sx={{
                                ...TypographyStyles.TYPO_SCALE_18
                            }}
                        >
                            <CMPTypography
                                sx={{ marginBottom: pxToRem(16) }}
                                text={
                                    cmpTexts.PropertyNavigationModalTexts
                                        .bodyText1
                                }
                            />
                            <CMPTypography
                                text={
                                    cmpTexts.PropertyNavigationModalTexts
                                        .bodyText2
                                }
                            />
                        </CMPDialogContent>
                        <CMPDialogActions
                            sx={{
                                justifyContent: 'center',
                                gap: pxToRem(16)
                            }}
                        >
                            <Button
                                buttonVariant={ButtonVariation.OUTLINED}
                                buttonSize={ButtonSize.LARGE}
                                sx={{
                                    color: CMPColors.light.text.button
                                        .secondary,
                                    borderColor:
                                        CMPColors.light.stroke.complete,
                                    fontWeight: 600,
                                    textTransform: 'capitalize'
                                }}
                                onClick={onConfirm}
                            >
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .button[0]
                                }
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                buttonVariant={ButtonVariation.CONTAINED}
                                onClick={onCancel}
                            >
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .button[1]
                                }
                            </Button>
                        </CMPDialogActions>
                    </CMPDialog>
                );
            }
        }}
    </NavigationPrompt>
);

export default PropertyDetailsNavigationPrompt;
