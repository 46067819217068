import React, { useState } from 'react';
import { ReactComponent as PencilIcon } from '../../../../assets/svg/pencil.svg';
import { ReactComponent as BinIcon } from '../../../../assets/svg/trashBin.svg';
import cmpTexts from '../../../../locale/en';
import {
    EventZone,
    StandardZone,
    ZoneType
} from '../../../../redux/zones/model';
import { pxToRem } from '../../../../utils/stylesUtils';
import { Mode } from '../manageZones';
import DeleteZoneOrEventModal from './deleteZoneOrEventModal';
import ZonesAndEventsOperationModal from './zonesAndEventsOperationModal';
import IconStylizer from '../../../../components/iconStylizer';
import { useSelector } from 'react-redux';
import { selectDeleteZoneAllowed } from '../../../../redux/userOperations/reducer';
import CMPColors from '../../../../components/colors';

const ActionButtons = (props: {
    zoneType: ZoneType;
    onSave: (zoneId: string) => void;
    zone: EventZone | StandardZone;
}) => {
    const { zoneType, zone } = props;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const canDeleteZone = useSelector(selectDeleteZoneAllowed);

    return (
        <>
            <IconStylizer
                color={CMPColors.light.icon.black}
                sx={{
                    marginRight: pxToRem(8),
                    cursor: 'pointer'
                }}
            >
                <PencilIcon onClick={() => setIsEditModalOpen(true)} />
            </IconStylizer>

            {canDeleteZone && (
                <IconStylizer
                    sx={{
                        cursor: 'pointer'
                    }}
                    color={CMPColors.light.icon.black}
                >
                    <BinIcon onClick={() => setIsDeleteModalOpen(true)} />
                </IconStylizer>
            )}

            {isEditModalOpen && (
                <ZonesAndEventsOperationModal
                    setIsModalOpen={setIsEditModalOpen}
                    zoneType={zoneType}
                    actionType={Mode.Edit}
                    zone={zone}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteZoneOrEventModal
                    setIsModalOpen={setIsDeleteModalOpen}
                    description={
                        zoneType === ZoneType.Event
                            ? cmpTexts.zones.eventDeleteDescription
                            : cmpTexts.zones.zoneDeleteDescription
                    }
                    heading={
                        zoneType === ZoneType.Event
                            ? cmpTexts.zones.eventDeleteHeading
                            : cmpTexts.zones.zoneDeleteHeading
                    }
                    zoneId={zone.id}
                />
            )}
        </>
    );
};

export default ActionButtons;
