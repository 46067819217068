import { SxProps } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';
import PropertySectionToolTip from './propertySectionTooltip';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface PropertySectionHeaderProps {
    header: string;
    tooltipMessage?: string;
    sx?: SxProps;
}

const PropertySectionHeader = (
    props: PropertySectionHeaderProps
): React.ReactElement => {
    const { header, tooltipMessage, sx } = props;

    return (
        <CMPTypography
            variation={TypographyVariation.HEADER3}
            sx={{
                color: CMPColors.light.text.primary,
                lineHeight: 'normal',
                marginRight: pxToRem(12),
                display: 'flex',
                justifyContent: 'center',
                padding: `${pxToRem(4)} 0px`,
                ...sx
            }}
        >
            {header}
            <PropertySectionToolTip
                tooltipMessage={tooltipMessage}
                sx={{ marginLeft: pxToRem(4) }}
            />
        </CMPTypography>
    );
};

export default PropertySectionHeader;
