import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AccessDenied from '../../assets/svg/accessDenied.svg';
import Button, { ButtonVariation } from '../../components/button';
import CMPTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const Container = styled('div')(() => ({
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CMPColors.light.background.main,
    minHeight: '100vh',
    flexGrow: 1
}));

const ImageBackground = styled('div')(() => ({
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${AccessDenied})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
}));

const ContainerGrid = styled(Grid)(() => ({
    marginTop: '15%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
}));

const GoHomeButton = styled(Button)(() => ({
    marginLeft: '0px !important',
    lineHeight: pxToRem(18)
}));

interface Props {
    title: string;
    subTitle: string;
    buttonAllowed?: boolean;
}

export default function ErrorPage(props: Props) {
    const { title, subTitle, buttonAllowed = true } = props;
    const navigate = useNavigate();

    const onGoBackClick = () => {
        navigate('/properties');
    };
    return (
        <Container
            style={!buttonAllowed ? { height: '74%', minHeight: '50vh' } : {}}
        >
            <ImageBackground>
                <Grid container>
                    <Grid item xs={6} />
                    <ContainerGrid
                        item
                        xs={6}
                        style={!buttonAllowed ? { marginTop: '18%' } : {}}
                    >
                        <CMPTypography
                            variation={TypographyVariation.HEADER2}
                            text={title}
                            sx={{ fontSize: pxToRem(24), marginBottom: '5px' }}
                        />
                        <CMPTypography
                            text={subTitle}
                            sx={{ margin: '5px 0' }}
                        />
                        {buttonAllowed && (
                            <GoHomeButton
                                buttonVariant={ButtonVariation.CONTAINED}
                                onClick={onGoBackClick}
                            >
                                {CMPTexts.errorPage.buttonText}
                            </GoHomeButton>
                        )}
                    </ContainerGrid>
                </Grid>
            </ImageBackground>
        </Container>
    );
}
