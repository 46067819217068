import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import FullScreenCircularLoader from '../../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../../components/snackbar';
import StickySaveBar from '../../../components/stickySaveBar';
import { useUrlParams } from '../../../hooks/useParam';
import cmpTexts from '../../../locale/en';
import { selectAssetOperationLoadingState } from '../../../redux/assets/reducer';
import { LoadingStatus } from '../../../redux/common/state';
import {
    getPropertyDetails,
    resetPropertOperationsLoader
} from '../../../redux/propertylist/action';
import { selectPropertyOperationLoader } from '../../../redux/propertylist/reducer';
import {
    getFieldConfigValues,
    resetGetFieldConfigValuesResponse,
    resetPropertyValueLoader,
    resetPropertyValueOperationLoader,
    updatePropertyMultipleFieldValue
} from '../../../redux/propertyValue/action';
import { PropertyField } from '../../../redux/propertyValue/model';
import {
    selectPropertyConfig,
    selectPropertyValueLoader,
    selectPropertyValueOperationLoader
} from '../../../redux/propertyValue/reducer';
import usePropertyFieldUpdatePayloadCreator from '../hooks/usePropertyFieldPayloadCreator';
import PropertyDetailsNavigationPrompt from './propertyDetailsNavigationPrompt';
import PropertySection from './propertySection';
import { PropertySectionPageContainer } from './styles';

function PropertyConfiguration() {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { property } = useUrlParams();
    const propertyConfig = useSelector(selectPropertyConfig);
    const propertyValueLoader = useSelector(selectPropertyValueLoader);
    const assetOperationLoader = useSelector(selectAssetOperationLoadingState);
    const propertyOperationLoader = useSelector(selectPropertyOperationLoader);
    const propertyFieldValueOperationLoader = useSelector(
        selectPropertyValueOperationLoader
    );

    const [showSaveBar, setShowSaveBar] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [shouldResetForm, setShouldResetForm] = useState(false);

    const propertyFieldPayloadCreator = usePropertyFieldUpdatePayloadCreator();

    useEffect(() => {
        dispatch(getFieldConfigValues(property));

        return () => {
            dispatch(resetGetFieldConfigValuesResponse());
        };
    }, []);

    // solves auto-scrolling to page bottom
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (propertyFieldValueOperationLoader === LoadingStatus.DONE) {
            showSnackbar(cmpTexts.OnStreamDesignTexts.fieldUpdateSuccess);
            dispatch(getFieldConfigValues(property));
            dispatch(resetPropertyValueOperationLoader());
        } else if (propertyFieldValueOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(
                cmpTexts.OnStreamDesignTexts.filedUpdateFailed,
                'error'
            );
            dispatch(getFieldConfigValues(property));
            dispatch(resetPropertyValueOperationLoader());
        }
        handleResetStateVariables();
    }, [propertyFieldValueOperationLoader]);

    useEffect(() => {
        if (propertyOperationLoader === LoadingStatus.DONE) {
            dispatch(getPropertyDetails(property));
            showSnackbar(cmpTexts.PropertyTexts.proopertyEditSuccess);
            dispatch(resetPropertOperationsLoader());
        } else if (propertyOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(cmpTexts.PropertyTexts.propertyEditFail, 'error');
            dispatch(resetPropertOperationsLoader());
        }
    }, [propertyOperationLoader]);

    useEffect(() => {
        if (propertyValueLoader === LoadingStatus.DONE) {
            dispatch(resetPropertyValueLoader());
        } else if (propertyValueLoader === LoadingStatus.FAILED) {
            navigate('/properties');
            dispatch(resetPropertyValueLoader());
        }
    }, [propertyValueLoader]);

    function handleResetStateVariables() {
        setShowSaveBar(false);
        propertyFieldPayloadCreator.clearPayload();
    }

    function handleSave() {
        if (!isEmpty(propertyFieldPayloadCreator.payload))
            dispatch(
                updatePropertyMultipleFieldValue({
                    property_id: property,
                    fields: propertyFieldPayloadCreator.payload
                })
            );
    }

    function handleCancel() {
        handleResetStateVariables();
        setShouldResetForm(true);
    }

    const shouldShowLoadingBar =
        propertyValueLoader === LoadingStatus.INITIATED ||
        propertyFieldValueOperationLoader === LoadingStatus.INITIATED ||
        propertyOperationLoader === LoadingStatus.INITIATED ||
        assetOperationLoader === LoadingStatus.INITIATED;

    return (
        <>
            {shouldShowLoadingBar && <FullScreenCircularLoader />}
            <PropertySectionPageContainer
                alignItems="center"
                justifyContent="center"
            >
                {!isEmpty(propertyConfig) && (
                    <PropertySection
                        propertyConfig={propertyConfig as PropertyField[]}
                        setShowSaveBar={setShowSaveBar}
                        showSaveBar={showSaveBar}
                        setHasError={setHasError}
                        updateSubmitPayload={
                            propertyFieldPayloadCreator.updatePropertyFieldPayload
                        }
                        setShouldResetForm={setShouldResetForm}
                        shouldResetForm={shouldResetForm}
                        manuallyUpdatedFieldIds={
                            propertyFieldPayloadCreator.manuallyUpdatedFieldIds
                        }
                    />
                )}
            </PropertySectionPageContainer>
            <PropertyDetailsNavigationPrompt
                shouldBlockNavigation={showSaveBar}
            />
            {showSaveBar && (
                <StickySaveBar
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    enableSaveButton={!hasError}
                />
            )}
        </>
    );
}

export default PropertyConfiguration;
