import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { pxToRem, StylesDictionary } from '../../../utils/stylesUtils';
import CMPColors from '../../../components/colors';

export const PropertyFieldStyles: StylesDictionary = {
    fieldDisabled: {
        pointerEvents: 'none',
        opacity: 0.5
    }
};

export const PropertySectionPageContainer = styled(Box)(() => ({
    width: '100%',
    backgroundColor: CMPColors.light.background.main,
    padding: `${pxToRem(24)} ${pxToRem(48)} ${pxToRem(48)}`
}));
