import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import ApiResponseCode from '../../api/apiResponseCode';
import { fetchPageList } from '../elementsList/action';
import { replaceBreadCrumb } from '../login/action';
import { getPropertyDetails } from '../propertylist/action';
import { ActionPayload } from '../types';
import {
    fieldConfigValuesResponse,
    GET_FIELD_CONFIG_VALUES,
    updatePropertyMultipleFieldValueRes,
    UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE,
    FETCH_AUTHORIZED_CHANNELS,
    fetchAuthorizedChannelsResponse
} from './action';
import {
    fetchAuthorizedChannels,
    fetchFieldConfigValues,
    updateMultiFieldvalue
} from './helper';
import {
    Channel,
    PropertyFieldValues,
    UpdatePropertyFieldValuesBody
} from './model';

//=====================================================================================
//                     Get Field Config Values
//=====================================================================================

function* getFieldValueConfigWorker(data: ActionPayload<string>) {
    try {
        yield put(fetchPageList(data.payload, 'PAGE'));
        const response: ApiResponse<PropertyFieldValues> = yield call(
            fetchFieldConfigValues,
            data
        );
        if (response.code === ApiResponseCode[200]) {
            yield put(fieldConfigValuesResponse(response.response_body, false));
        }
        yield put(
            replaceBreadCrumb([
                {
                    // id: response.response_body.property_id,
                    name: 'Properties',
                    link: '/properties',
                    state: {}
                },
                {
                    // id: response.response_body.property_id,
                    name: response.response_body.property_name || 'property',
                    link: `/properties/${response.response_body.property_id}`,
                    state: {}
                }
            ])
        );
    } catch (err) {
        const e: any = err;
        const result: any = e.message;
        yield put(fieldConfigValuesResponse(result, true));
    }
}

//=====================================================================================
//                    Update Multi Property Fields
//=====================================================================================

function* updatePropertyMultiFieldValueWorker(
    data: ActionPayload<UpdatePropertyFieldValuesBody>
) {
    try {
        yield call(updateMultiFieldvalue, data.payload);
        yield put(updatePropertyMultipleFieldValueRes());
        yield put(getPropertyDetails(data.payload.property_id));
    } catch (err) {
        console.log('error in updateMultiFiled', err);
        const e: any = err;
        const errorMessage: any = e.message;
        yield put(updatePropertyMultipleFieldValueRes(errorMessage));
    }
}

function* fetchAuthorizedChannelsWorker(data: ActionPayload<string>) {
    try {
        const response: ApiResponse<Channel[]> = yield call(
            fetchAuthorizedChannels,
            data.payload
        );

        yield put(
            fetchAuthorizedChannelsResponse(response.response_body, false)
        );
    } catch (err) {
        const e: any = err;
        const errorMessage: any = e.message;
        fetchAuthorizedChannelsResponse(errorMessage, true);
    }
}

export function* getSelectEntryDropDownWatcher() {
    yield all([
        takeEvery(GET_FIELD_CONFIG_VALUES, getFieldValueConfigWorker),
        takeLatest(
            UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE,
            updatePropertyMultiFieldValueWorker
        ),
        takeLatest(FETCH_AUTHORIZED_CHANNELS, fetchAuthorizedChannelsWorker)
    ]);
}
