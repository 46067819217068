import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import CMPColors from './colors';

const StyledBox = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 99999,
    background: CMPColors.loader,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%) '
}));

export default function FullScreenCircularLoader() {
    return (
        <StyledBox>
            <CircularProgress />
        </StyledBox>
    );
}
