import { Box, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorPicker, ColorValue } from 'mui-color';
import React from 'react';
import { DisableableBox } from '../../../components/disableableBox';
import { PropertyField } from '../../../redux/propertyValue/model';
import { pxToRem } from '../../../utils/stylesUtils';
import CMPTextField from '../../../components/textFields/cmpTextField';
import PropertySectionHeader from './components/propertySectionHeader';
import CMPColors from '../../../components/colors';

interface Props {
    theme: PropertyField;
    onFieldUpdate: (index: number, value: any) => void;
    getPropertyFieldError: (index: number) => string;
}

const ColorRowDiv = styled('div')(() => ({
    display: 'flex',
    flex: 1
}));

const ColorPickerComponent = styled(Box)(() => ({
    '& .MuiButtonBase-root.MuiButton-root': {
        width: pxToRem(200),
        marginRight: pxToRem(12),
        boxShadow: 'none',
        div: {
            minWidth: 'max-content',
            width: pxToRem(200),
            height: pxToRem(55)
        }
    },

    display: 'flex',
    alignItems: 'center'
}));

function PropertyThemeSection({
    theme,
    onFieldUpdate,
    getPropertyFieldError
}: Props) {
    const handleColorChange = (values: ColorValue, index: number) => {
        if (Object(values)?.error) {
            onFieldUpdate(index, Object(values).raw.toUpperCase());
        } else {
            onFieldUpdate(
                index,
                Object(values).css.backgroundColor.toUpperCase()
            );
        }
    };

    const handleTextChange = (value: string, index: number) => {
        onFieldUpdate(index, value);
    };

    return (
        <>
            <ColorRowDiv>
                {theme.children && theme?.children!.length > 0
                    ? theme?.children?.map((pf: PropertyField, i: number) => {
                          const canView = pf.access_details?.View;
                          const canEdit = pf.access_details?.Edit;
                          if (!canView) return null;

                          const error = getPropertyFieldError(i);

                          return (
                              <DisableableBox
                                  key={pf.id}
                                  disabled={!canEdit}
                                  changeOpacity={false}
                                  sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'end'
                                  }}
                              >
                                  <ColorPickerComponent>
                                      <PropertySectionHeader
                                          header={pf?.name}
                                          tooltipMessage={
                                              pf?.configuration?.toolTip
                                          }
                                      />

                                      <ColorPicker
                                          value={String(pf.value)}
                                          onChange={(e) =>
                                              handleColorChange(e, i)
                                          }
                                          hideTextfield
                                          disableAlpha
                                      />
                                      <CMPTextField
                                          sx={{ margin: 0 }}
                                          fullWidth={false}
                                          label="Hex Color"
                                          startInputAdornment="#"
                                          startInputAdornmentStyles={{
                                              fontSize: pxToRem(16),
                                              padding: 0,
                                              marginTop: pxToRem(8),
                                              marginRight: pxToRem(-5)
                                          }}
                                          value={
                                              (pf?.value as string)?.split(
                                                  '#'
                                              )[1]
                                          }
                                          maxLength={6}
                                          onChange={(value) =>
                                              handleTextChange(
                                                  `#${value}`.toUpperCase(),
                                                  i
                                              )
                                          }
                                          disabled={!canEdit}
                                          error={Boolean(error)}
                                      />
                                  </ColorPickerComponent>
                                  {Boolean(error) && (
                                      <FormHelperText
                                          sx={{
                                              '&.Mui-error': {
                                                  width: 'fit-content',
                                                  color: CMPColors.light.text
                                                      .textField.error
                                              }
                                          }}
                                          error={Boolean(error)}
                                          required
                                      >
                                          {error}
                                      </FormHelperText>
                                  )}
                              </DisableableBox>
                          );
                      })
                    : null}
            </ColorRowDiv>
        </>
    );
}

export default PropertyThemeSection;
