import { List, ListItemButton, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import React from 'react';
import { CloseIconComponent } from '../../../components/closeIconComponent';
import { pxToRem, StylesDictionary } from '../../../utils/stylesUtils';
import { Platform } from './platforms';
import CMPTypography, {
    TypographyVariation
} from '../../../components/textFields/cmpTypography';
import CMPColors from '../../../components/colors';

interface PreviewPlatformSelectionDialogProps {
    platforms: Platform[];
    onPlatformSelected: (platform: Platform) => void;
    onCancel: () => void;
    open: boolean;
    canvas?: boolean;
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: pxToRem(16)
    }
};

const PreviewPlatformSelectionDialog = (
    props: PreviewPlatformSelectionDialogProps
): React.ReactElement => {
    const { platforms, onCancel, onPlatformSelected, open, canvas } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth
            maxWidth="sm"
            sx={{
                '& .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-root': {
                    backgroundColor:
                        CMPColors[canvas ? 'dark' : 'light'].background.modal
                }
            }}
        >
            <Box sx={styles.container}>
                <CMPTypography
                    variation={TypographyVariation.HEADER2}
                    text="Select a Platform to Preview"
                    sx={{
                        color: CMPColors[canvas ? 'dark' : 'light'].text.primary
                    }}
                />

                <CloseIconComponent onClick={onCancel} />
            </Box>

            <List>
                {platforms.map((platform: Platform) => {
                    return (
                        <ListItemButton
                            onClick={() => onPlatformSelected(platform)}
                            divider
                            key={platform.name}
                        >
                            <ListItemText
                                primary={platform.displayName}
                                sx={{
                                    color: CMPColors[canvas ? 'dark' : 'light']
                                        .text.primary
                                }}
                            />
                        </ListItemButton>
                    );
                })}
            </List>
        </Dialog>
    );
};

export default PreviewPlatformSelectionDialog;
