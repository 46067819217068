import React from 'react';
import { Box } from '@mui/material';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPTypography, {
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface PropertySectionHorizontalOptionSelectorProps {
    value: any;
    options: any[];
    description?: string;
    onSelectOption?: (value: any) => void;
}

const PropertySectionHorizontalOptionSelector = (
    props: PropertySectionHorizontalOptionSelectorProps
): React.ReactElement => {
    const { value, options, onSelectOption, description } = props;

    return (
        <>
            {description && (
                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_18}
                    sx={{
                        color: CMPColors.light.text.primary,
                        marginBottom: pxToRem(12)
                    }}
                    text={description}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    border: `1px solid ${CMPColors.light.stroke.default}`,
                    borderRadius: pxToRem(4),
                    width: 'fit-content',
                    overflow: 'hidden'
                }}
            >
                {options.map((o, i) => {
                    return (
                        <Box
                            sx={{
                                height: pxToRem(32),
                                minWidth: pxToRem(72),
                                padding: `0 ${pxToRem(8)}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight:
                                    i < options.length - 1
                                        ? `1px solid ${CMPColors.light.stroke.default}`
                                        : '',
                                cursor: 'pointer',
                                background:
                                    o === value
                                        ? CMPColors.brandColor
                                        : CMPColors.light.background.tabs
                                              .primary
                            }}
                            key={o}
                            onClick={() => onSelectOption?.(o)}
                        >
                            <CMPTypography
                                sx={{
                                    textAlign: 'center',
                                    height: 'fit-content',
                                    color:
                                        o === value
                                            ? CMPColors.dark.text.primary
                                            : CMPColors.light.text.primary
                                }}
                                text={o}
                            />
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export default PropertySectionHorizontalOptionSelector;
