import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, FormControl, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import { get, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import { useUrlParams } from '../../hooks/useParam';
import CmpTexts from '../../locale/en';
import { getAssetLists } from '../../redux/assets/action';
import { selectAssets } from '../../redux/assets/reducer';
import { setPageBackgroundMessage } from '../../redux/element/action';
import {
    ConfigurationType,
    ContentField,
    FieldType,
    HeaderVariants,
    MediaType,
    RailAlias,
    // Rewards, the component is hidden as per product requirement
    WidgetSkeleton
} from '../../redux/element/model';
import { ElementCategory } from '../../redux/elementsList/model';
import { selectRails, selectWidgets } from '../../redux/elementsList/reducer';
import { sideScroll } from '../../utils/scrollUtil';
import { pxToRem } from '../../utils/stylesUtils';
import {
    DragPageHandleProps,
    SortablePageItemProps,
    SortablePageListProps
} from './elementsInterface';
import HeaderGenerator from './headerGenerator';
import HeroCarousal from './heroCarousal';
import MediaAssetInputField from './mediaAssetInputField';
import RailHeadingContainer from './railHeadingContainer';
// import RewardsGenerator from './rewardsGenerator'; the component is hidden as per product requirement
import SwimlaneRail from './swimlaneRail';
import { filterAssetsByType } from '../assets/assets';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const SCROLL_FORWARD_THRESHOLD = 400;
const SCROLL_BACKWARD_THRESHOLD = -400;

export const ListContainer = styled(Box)(() => ({
    marginBottom: 20,
    backgroundColor: '#ebf6f9',
    border: `${pxToRem(2)} solid #AED9E6`,
    borderLeft: `${pxToRem(2)} solid #AED9E6`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const ItemList = styled('div')(() => ({
    color: CMPColors.light.text.hyperlink,
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold',
    marginBottom: 20,
    width: '72vw'
}));

export const HeroList = styled('div')(() => ({
    color: CMPColors.light.text.hyperlink,
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold',
    marginBottom: 20,
    marginLeft: 5
}));

export const StyledFormControl = styled(FormControl)(() => ({
    marginBottom: 12,
    width: '100%'
}));

export const WidgetContainer = styled('div')(() => ({
    marginLeft: 8
}));

export const WidgetNameContainer = styled(Box)(() => ({
    display: 'flex',
    width: '98%',
    flexDirection: 'column',
    borderLeft: `${pxToRem(2)} solid #AED9E6`,
    marginLeft: 3
}));

export const ReorderSharp = styled(DragIndicatorIcon)(() => ({
    color: 'rgba(23,23,37,0.54)',
    marginLeft: 3
}));

export const AddButton = styled(Button)(() => ({
    width: 260,
    height: 90,
    backgroundColor: '#ebf6f9',
    border: `${pxToRem(2)}  solid #AED9E6`,
    color: CMPColors.light.text.hyperlink,
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderRadius: 2,
    marginTop: 10,
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: '#ebf6f9'
    }
}));

export const AddIconWidget = styled(AddIcon)(() => ({
    width: 28,
    height: 70
}));

export const ContentWrapper = styled('div')(() => ({
    overflow: 'hidden',
    scrollBehavior: 'smooth',
    '& > :${Content}': {
        margin: `${pxToRem(10)}`
    }
}));

export const ButtonScroll = styled('div')(() => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'end',
    paddingRight: pxToRem(4),
    width: '4%',
    '&:hover': {
        opacity: 0.8
    }
}));

export const BackButtonScroll = styled('div')(() => ({
    cursor: 'pointer',
    width: '2%',
    '&:hover': {
        opacity: 0.8
    }
}));

export const ArrowBackIcon = styled(ArrowBackIosNewIcon)(() => ({
    color: CMPColors.light.text.hyperlink
}));

export const ArrowForwardIcon = styled(ArrowForwardIosIcon)(() => ({
    color: CMPColors.light.text.hyperlink
}));

export const ContainerDiv = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}));

export const WidgetContainerdiv = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 190
}));

export const HeroCarouselLightbulbIconDiv = styled('div')(() => ({
    flex: 1,
    marginLeft: 20,
    marginTop: 32
}));

const Container = styled('div')(() => ({
    marginTop: pxToRem(25),
    marginBottom: pxToRem(25)
}));

const PageContainer = styled(Box)(() => ({
    marginLeft: 32,
    marginRight: 82
}));

interface PageGeneratorProps {
    formik: FormikProps<WidgetSkeleton>;
    setIsEdited: (edited: boolean) => void;
    isEdited?: boolean;
    setShowAddElement?: (showAddElement: boolean) => void;
    setRedirectParams?: (params: { link: string; state: {} }) => void;
    setShowWarningModal?: (showModal: boolean) => void;
}

const DragHandle = SortableHandle((props: DragPageHandleProps) => {
    const {
        formik,
        index,
        itemDataId,
        itemDataName,
        itemDataWidgetTypeName,
        itemDataAlias,
        setIsEdited,
        isEdited,
        showRemove,
        setShowWarningModal,
        mwtIndex
    } = props;
    const [showBackarrowButton, setBackarrowButton] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const contentWrapper = React.useRef<any>(null);
    const widgets = useSelector(selectWidgets);
    const railData = useSelector(selectRails).find((r) => r.id === itemDataId);

    const [railDataArray, allowedTypeIds] = isArray(
        railData?.content_fields[0].values
    )
        ? [
              railData?.content_fields[0].values,
              railData?.content_fields[0].allowed_type_id
          ]
        : [[], []];

    const handleRemoveItem = (removedItem: string) => {
        setIsEdited(true);
        formik.setFieldValue(
            `content_fields[${mwtIndex}].values`,
            isArray(formik.values.content_fields[mwtIndex]?.values) &&
                (
                    formik.values?.content_fields[mwtIndex]?.values as string[]
                ).filter((item: string) => item !== removedItem)
        );
    };

    const handleElementRedirect = () => {
        const splitPathname = location.pathname.split('/');
        splitPathname.pop();
        splitPathname.push(itemDataId);
        navigate(splitPathname.join('/'), {
            state: {
                widget: {
                    widget_type_name: itemDataWidgetTypeName,
                    type: ElementCategory.RAIL
                },
                origin: ElementCategory.PAGE
            }
        });
    };

    const handleElementRedirectClick = () => {
        if (isEdited && setShowWarningModal) {
            handleElementRedirect();
        } else if (location.pathname !== undefined && location) {
            const splitPathname = location.pathname.split('/');
            splitPathname.pop();
            splitPathname.push(itemDataId);
            navigate(splitPathname.join('/'), {
                state: {
                    widget: {
                        widget_type_name: itemDataWidgetTypeName,
                        type: ElementCategory.RAIL
                    },
                    origin: ElementCategory.PAGE
                }
            });
        }
    };

    return (
        <>
            {itemDataAlias === RailAlias.HeroCarousel ? (
                <ListContainer key={index + ''}>
                    <ReorderSharp />
                    <WidgetNameContainer>
                        <RailHeadingContainer
                            itemDataName={itemDataName}
                            itemDataId={itemDataId}
                            itemDataWidgetTypeName={itemDataWidgetTypeName}
                            handleElementRedirectClick={
                                handleElementRedirectClick
                            }
                            showRemove={showRemove}
                            handleRemoveItem={handleRemoveItem}
                        />
                        <HeroList>
                            <Box style={{ minHeight: 200 }}>
                                <ContentWrapper ref={contentWrapper}>
                                    <HeroCarousal
                                        railDataArray={
                                            isArray(railDataArray)
                                                ? railDataArray
                                                : []
                                        }
                                        allowedTypeIds={
                                            isArray(allowedTypeIds)
                                                ? allowedTypeIds
                                                : []
                                        }
                                        widgets={widgets}
                                        itemDataWidgetTypeName={
                                            itemDataWidgetTypeName
                                        }
                                    />
                                </ContentWrapper>
                            </Box>
                        </HeroList>
                    </WidgetNameContainer>
                </ListContainer>
            ) : (
                <ListContainer key={index + ''}>
                    <ReorderSharp />

                    <WidgetNameContainer>
                        <RailHeadingContainer
                            itemDataName={itemDataName}
                            itemDataId={itemDataId}
                            showRemove={showRemove}
                            itemDataWidgetTypeName={itemDataWidgetTypeName}
                            handleElementRedirectClick={
                                handleElementRedirectClick
                            }
                            handleRemoveItem={handleRemoveItem}
                        />

                        <ContainerDiv>
                            <BackButtonScroll>
                                {showBackarrowButton && (
                                    <ArrowBackIcon
                                        onClick={() => {
                                            sideScroll(
                                                setBackarrowButton,
                                                contentWrapper.current,
                                                SCROLL_BACKWARD_THRESHOLD
                                            );
                                        }}
                                    />
                                )}
                            </BackButtonScroll>

                            <ItemList>
                                <ContentWrapper ref={contentWrapper}>
                                    <SwimlaneRail
                                        railDataArray={
                                            isArray(railDataArray)
                                                ? railDataArray
                                                : []
                                        }
                                        allowedTypeIds={
                                            isArray(allowedTypeIds)
                                                ? allowedTypeIds
                                                : []
                                        }
                                        itemDataAlias={itemDataAlias}
                                        widgets={widgets}
                                        itemDataWidgetTypeName={
                                            itemDataWidgetTypeName
                                        }
                                    />
                                </ContentWrapper>
                            </ItemList>

                            <ButtonScroll
                                onClick={() => {
                                    sideScroll(
                                        setBackarrowButton,
                                        contentWrapper.current,
                                        SCROLL_FORWARD_THRESHOLD
                                    );
                                }}
                            >
                                {isArray(railDataArray) &&
                                    railDataArray.length > 4 && (
                                        <ArrowForwardIcon />
                                    )}
                            </ButtonScroll>
                        </ContainerDiv>
                    </WidgetNameContainer>
                </ListContainer>
            )}
        </>
    );
});

const SortableItem = SortableElement((props: SortablePageItemProps) => {
    const {
        formik,
        itemDataId,
        itemDataName,
        itemDataWidgetTypeName,
        itemDataAlias,
        index,
        setIsEdited,
        canEditRail,
        isEdited,
        setRedirectParams,
        setShowWarningModal,
        mwtIndex
    } = props;

    return (
        <DragHandle
            formik={formik}
            itemDataId={itemDataId}
            itemDataName={itemDataName}
            itemDataWidgetTypeName={itemDataWidgetTypeName}
            itemDataAlias={itemDataAlias}
            key={index + ''}
            showRemove={canEditRail}
            index={index}
            mwtIndex={mwtIndex}
            setIsEdited={setIsEdited}
            isEdited={isEdited}
            setRedirectParams={setRedirectParams}
            setShowWarningModal={setShowWarningModal}
        />
    );
});

const SortableListContainer = SortableContainer(
    (props: SortablePageListProps) => {
        const {
            formik,
            setIsEdited,
            isEdited,
            sortRail,
            canEditRail,
            setShowAddElement,
            setRedirectParams,
            setShowWarningModal,
            index
        } = props;

        const rails = useSelector(selectRails);

        const handleAddrail = () => {
            if (setShowAddElement) {
                setShowAddElement(true);
            }
        };

        const multiWidgetTypeCF = formik.values?.content_fields[index];
        return (
            <WidgetContainer>
                {isArray(multiWidgetTypeCF?.values) &&
                    !isEmpty(rails) &&
                    (multiWidgetTypeCF.values as string[]).map(
                        (itemD: string, cfIndex: number) => {
                            const rail = rails.find((r) => r.id === itemD);
                            if (rail) {
                                return (
                                    <SortableItem
                                        formik={formik}
                                        key={cfIndex + ''}
                                        index={cfIndex}
                                        mwtIndex={index}
                                        itemDataId={rail.id}
                                        itemDataName={rail.name}
                                        itemDataWidgetTypeName={
                                            rail.widget_type_name
                                        }
                                        disabled={!sortRail}
                                        canEditRail={canEditRail}
                                        itemDataAlias={rail.alias}
                                        setIsEdited={setIsEdited}
                                        isEdited={isEdited}
                                        setRedirectParams={setRedirectParams}
                                        setShowWarningModal={
                                            setShowWarningModal
                                        }
                                    />
                                );
                            }
                            return null;
                        }
                    )}

                {multiWidgetTypeCF.access_details?.Edit && (
                    <AddButton onClick={handleAddrail}>
                        <AddIconWidget />
                        Add rail
                    </AddButton>
                )}
            </WidgetContainer>
        );
    }
);

const PageGenerator = (props: PageGeneratorProps) => {
    const {
        formik,
        setIsEdited,
        isEdited,
        setShowAddElement,
        setRedirectParams,
        setShowWarningModal
    } = props;

    const assets = useSelector(selectAssets);
    const { property } = useUrlParams();
    const dispatch = useDispatch();
    const rails = useSelector(selectRails);

    useEffect(() => {
        dispatch(getAssetLists(property));
    }, []);

    useEffect(() => {
        const backgroundContentField = formik.values.content_fields.find(
            (cf) => cf.type === FieldType.Media
        );

        const isImageVideoType =
            backgroundContentField?.configuration.mediaType?.toLowerCase() ===
            MediaType.Image_Video.toLowerCase();

        const backgroundValue = backgroundContentField?.values;

        const bgId = isImageVideoType
            ? get(backgroundValue, 'assetId', '')
            : backgroundValue;

        const isAudioEnabled = isImageVideoType
            ? get(backgroundValue, 'isAudioEnabled', false)
            : false;

        const isImage =
            assets.find((e) => e.id === bgId)?.type?.toLocaleLowerCase() ===
            MediaType.Image.toLowerCase();

        const mwtContentField = formik.values.content_fields.find(
            (cf) => cf.type === FieldType.MultiWidgetType
        );

        if (
            isArray(mwtContentField?.values) &&
            mwtContentField?.values[0] &&
            rails.find((r) => r.id === (mwtContentField?.values as string[])[0])
                ?.alias === RailAlias.HeroCarousel
        ) {
            dispatch(
                setPageBackgroundMessage(
                    CmpTexts.pageGenerator.bgMessageHeroCarousel
                )
            );
        } else if (bgId) {
            dispatch(
                setPageBackgroundMessage(
                    isImage
                        ? CmpTexts.pageGenerator.bgMessageImage
                        : isAudioEnabled
                        ? CmpTexts.pageGenerator.bgMessageVideoAudioEnabled
                        : CmpTexts.pageGenerator.bgMessageVideoAudioDisabled
                )
            );
        } else if (!backgroundContentField) {
            dispatch(
                setPageBackgroundMessage(CmpTexts.pageGenerator.bgMessageBlack)
            );
        } else {
            dispatch(
                setPageBackgroundMessage(
                    CmpTexts.pageGenerator.bgMessageDefaultImage
                )
            );
        }
    }, [formik.values.content_fields]);

    const onSortEnd = (
        mwtIndex: number,
        oldIndex: number,
        newIndex: number
    ) => {
        if (!isArray(formik.values.content_fields[mwtIndex]?.values)) return;
        setIsEdited(true);
        const updatedValues = arrayMove(
            formik.values.content_fields[mwtIndex].values as string[],
            oldIndex,
            newIndex
        );
        formik.setFieldValue(
            `content_fields[${mwtIndex}].values`,
            updatedValues
        );
    };

    return (
        <PageContainer>
            {formik.values.content_fields.map(
                (item: ContentField, index: number) => (
                    <Container key={index}>
                        {item.access_details?.View &&
                            item.type === FieldType.MultiWidgetType && (
                                <>
                                    <CMPTypography
                                        variation={TypographyVariation.HEADER3}
                                        sx={{
                                            marginLeft: pxToRem(8),
                                            marginBottom: pxToRem(8)
                                        }}
                                    >
                                        {CmpTexts.pageGenerator.Rails}
                                    </CMPTypography>
                                    <StyledFormControl>
                                        <SortableListContainer
                                            formik={formik}
                                            index={index}
                                            setIsEdited={setIsEdited}
                                            onSortEnd={({
                                                oldIndex,
                                                newIndex
                                            }) =>
                                                onSortEnd(
                                                    index,
                                                    oldIndex,
                                                    newIndex
                                                )
                                            }
                                            useDragHandle={true}
                                            lockAxis="y"
                                            distance={2}
                                            sortRail={
                                                item.access_details?.SortRail
                                            }
                                            canEditRail={
                                                item.access_details?.Edit
                                            }
                                            disableAutoscroll={true}
                                            isEdited={Boolean(isEdited)}
                                            setShowAddElement={
                                                setShowAddElement
                                            }
                                            setRedirectParams={
                                                setRedirectParams
                                            }
                                            setShowWarningModal={
                                                setShowWarningModal
                                            }
                                        />
                                    </StyledFormControl>
                                </>
                            )}

                        {/* {item.access_details?.View &&  the component is hidden as per product requirement
                            item.type === FieldType.Json &&
                            item.configuration.type ===
                                ConfigurationType.Rewards && (
                                <RewardsGenerator
                                    onRewardsChange={(rewardsObj: Rewards) =>
                                        formik.setFieldValue(
                                            `content_fields[${index}].values`,
                                            rewardsObj
                                        )
                                    }
                                    item={item}
                                    setIsEdited={setIsEdited}
                                />
                            )} */}

                        {item.access_details?.View &&
                            item.type === FieldType.Json &&
                            item.configuration.type ===
                                ConfigurationType.WelcomeHeader1 && (
                                <HeaderGenerator
                                    variant="VARIANT_1"
                                    item={item}
                                    setIsEdited={setIsEdited}
                                    onHeaderChange={(
                                        headerObj: HeaderVariants
                                    ) =>
                                        formik.setFieldValue(
                                            `content_fields[${index}].values`,
                                            headerObj
                                        )
                                    }
                                />
                            )}

                        {item.access_details?.View &&
                            item.type === FieldType.Json &&
                            item.configuration.type ===
                                ConfigurationType.WelcomeHeader2 && (
                                <HeaderGenerator
                                    variant="VARIANT_2"
                                    item={item}
                                    setIsEdited={setIsEdited}
                                    onHeaderChange={(
                                        headerObj: HeaderVariants
                                    ) =>
                                        formik.setFieldValue(
                                            `content_fields[${index}].values`,
                                            headerObj
                                        )
                                    }
                                />
                            )}

                        {item.access_details?.View &&
                            item.type === FieldType.Media && (
                                <MediaAssetInputField
                                    assets={filterAssetsByType(
                                        assets,
                                        item.configuration.mediaType ??
                                            MediaType.Image
                                    )}
                                    mediaConfigs={item.configuration}
                                    name={item.name}
                                    description={item.description}
                                    isRequired={item.is_required}
                                    initialAssetId={
                                        item.configuration.mediaType?.toLowerCase() ===
                                        MediaType.Image_Video.toLowerCase()
                                            ? get(item.values, 'assetId', '')
                                            : item.values
                                    }
                                    disabled={!item.access_details?.Edit}
                                    onMediaAssetSelected={(assetId: string) => {
                                        formik.setFieldValue(
                                            `content_fields[${index}].values`,
                                            item.configuration.mediaType ===
                                                MediaType.Image_Video
                                                ? {
                                                      assetId,
                                                      isAudioEnabled: false
                                                  }
                                                : assetId
                                        );
                                        setIsEdited(true);
                                    }}
                                />
                            )}
                    </Container>
                )
            )}
        </PageContainer>
    );
};

export default PageGenerator;
