import { Box } from '@mui/material';
import { CloseIconComponent } from '../../components/closeIconComponent';
import { styled } from '@mui/material/styles';
import React from 'react';
import { pxToRem } from '../../utils/stylesUtils';
import CMPColors from '../../components/colors';

const IconWrapper = styled(Box)(() => ({
    right: pxToRem(16),
    top: pxToRem(16),
    width: pxToRem(20),
    height: pxToRem(20),
    position: 'absolute',
    cursor: 'pointer'
}));

const Overlay = styled(Box)(() => ({
    width: '100%',
    display: 'block',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5,
    background: 'rgba(0, 0, 0,0.5)'
}));

interface CanvasModalEditorProps {
    children?: React.ReactNode;
    onClose: () => void;
}

const CanvasModalEditor = (props: CanvasModalEditorProps) => {
    const { children, onClose } = props;

    return (
        <>
            <Overlay />
            <Box
                sx={{
                    width: pxToRem(520),
                    color: CMPColors.dark.text.secondary,
                    zIndex: 10,
                    padding: `${pxToRem(39)} ${pxToRem(30)} ${pxToRem(43)}`,
                    backgroundColor: CMPColors.dark.background.modal,
                    borderRadius: pxToRem(4),
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    border: `1px solid ${CMPColors.dark.stroke.default}`,
                    maxHeight: '85vh',
                    overflow: 'scroll',
                    position: 'fixed',
                    top: '50%',
                    left: '55%',
                    transform: 'translate(-40%, -55%)',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none'
                }}
            >
                <IconWrapper
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    <CloseIconComponent
                        style={{
                            width: pxToRem(24),
                            height: pxToRem(24),
                            color: CMPColors.dark.icon.primary
                        }}
                    />
                </IconWrapper>
                <Box sx={{ marginTop: pxToRem(4) }}>{children}</Box>
            </Box>
        </>
    );
};

export default CanvasModalEditor;
