import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';

import cmpTexts from '../../locale/en';
import CMPColors from '../../components/colors';

interface Props {
    associations: {
        association_type: string;
        association_id: string;
        association_group: {
            id: string;
            type: string;
            name: string;
            resource_type: string;
        };
    }[];
}

const styles = {
    background: {
        background: CMPColors.light.background.modal
    }
};

const TableHeader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    height: 50,
    width: '100%',
    color: CMPColors.dark.text.secondary,
    font: 'Proxima Nova Bold',
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: CMPColors.light.background.accordion.primary,
    padding: 28
}));

const AssetsAssociationTable = (props: Props) => {
    const { associations } = props;
    console.log(associations);
    return (
        <Fragment>
            <TableHeader>
                {cmpTexts.assets.assetAssociationTable.header}
            </TableHeader>
            <Table sx={styles.background}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 900 }}>
                            {cmpTexts.assets.assetAssociationTable.cell_1}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }}>
                            {cmpTexts.assets.assetAssociationTable.cell_2}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {associations.map((row) => {
                        return (
                            <TableRow key={row.association_id}>
                                <TableCell component="th">
                                    {row.association_group.resource_type}
                                </TableCell>
                                <TableCell scope="row">
                                    {row.association_group.name}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Fragment>
    );
};

export default AssetsAssociationTable;
