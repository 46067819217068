import * as React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import CMPTypography, { TypographyVariation } from './textFields/cmpTypography';
import CMPColors from './colors';

const TimeDisplay = (props: { time: number }) => {
    const { time } = props;
    const dateAndTime = moment(time).format('MMMM D, YYYY;hh:mm A').split(';');

    return (
        <Box>
            <CMPTypography text={dateAndTime[0]} />
            <CMPTypography
                variation={TypographyVariation.TYPO_SCALE_14}
                sx={{
                    color: CMPColors.light.text.tertiary
                }}
                text={dateAndTime[1]}
            />
        </Box>
    );
};

export default TimeDisplay;
