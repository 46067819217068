import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';
import CMPTypography from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface PropertySectionConsentCheckboxProps {
    checked: boolean;
    handleChange: (value: boolean) => void;
    description: string;
    helperText?: string;
}

const PropertySectionConsentCheckbox = (
    props: PropertySectionConsentCheckboxProps
): React.ReactElement => {
    const { checked, handleChange, description, helperText } = props;

    return (
        <>
            <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                }
                label={
                    <CMPTypography
                        sx={{
                            color: CMPColors.light.text.button.secondary,
                            padding: `${pxToRem(12)} 0 0`
                        }}
                        text={description}
                    />
                }
            />
            <FormHelperText error={true}>{helperText}</FormHelperText>
        </>
    );
};

export default PropertySectionConsentCheckbox;
