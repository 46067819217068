import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoutBroadcastChannel from '../../auth/logoutBroadcastChannel';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import useQuery from '../../hooks/useQuery';
import CMPTexts from '../../locale/en';
import { setLoginStatus, userLogin } from '../../redux/login/action';
import { LoginStatus } from '../../redux/login/model';
import { selectError, selectLoginstatus } from '../../redux/login/reducer';
import { emailVerificationValidationSchema } from '../../utils/loginUtils';
import CMPTextField, {
    TextVariation
} from '../../components/textFields/cmpTextField';
import { ButtonContainer, FormWrapper } from '../../components/cmpAuthWrapper';
import SuccessForm from './successForm';
import CMPValidationTextField from '../../components/textFields/cmpTextfieldWithValidations';
import { Validation } from '../../components/textFields/cmpValidationTexts';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import { pxToRem } from '../../utils/stylesUtils';
import CMPColors from '../../components/colors';

const EmailVerification = () => {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const query = useQuery();
    const email: string | null = query.get('email');
    const loadingStatus: string = useSelector(selectLoginstatus);
    const emailVerificationError = useSelector(selectError);

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        LogoutBroadcastChannel.triggerLogout();
        dispatch(setLoginStatus(LoginStatus.NONE));
    }, []);

    useEffect(() => {
        if (loadingStatus === LoginStatus.NEW_PASSWORD_SET_SUCCESSFULLY) {
            dispatch(setLoginStatus(LoginStatus.NONE));
            setShowSuccess(true);
        } else if (loadingStatus === LoginStatus.NEW_PASSWORD_FAILED) {
            showSnackbar(emailVerificationError, 'error');
            console.log('Could not verify user - ', emailVerificationError);
        }
    }, [loadingStatus]);

    useEffect(() => {
        if (showSuccess) {
            const timer = setTimeout(() => {
                handleRedirect();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [showSuccess]);

    const handleRedirect = () => {
        console.log('Redirecting to Login...');
        navigate('/');
    };

    const formik = useFormik({
        initialValues: {
            email: email ?? '',
            tempPassword: '',
            password: '',
            repeatPassword: ''
        },
        validationSchema: emailVerificationValidationSchema,
        onSubmit: () => {}
    });

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(
            userLogin({
                email: formik.values.email,
                password: formik.values.tempPassword,
                newPassword: formik.values.password
            })
        );
        console.log(`verifying user ${email}...`);
    };

    if (showSuccess) {
        return (
            <SuccessForm
                title={CMPTexts.emaiVerificationSubmit.title}
                description={CMPTexts.emaiVerificationSubmit.description}
                buttonText={CMPTexts.emaiVerificationSubmit.button}
                onClick={() => navigate(-1)}
            />
        );
    }

    return (
        <>
            {(loadingStatus === LoginStatus.LOGGING_IN ||
                loadingStatus === LoginStatus.NEW_PASSWORD_REQUIRED) && (
                <FullScreenCircularLoader />
            )}
            <FormWrapper onSubmit={handleSubmit}>
                <CMPTypography
                    variation={TypographyVariation.HEADER2}
                    sx={{
                        letterSpacing: pxToRem(-0.20000000298023224),
                        marginBottom: pxToRem(32)
                    }}
                >
                    {CMPTexts.emailVerification.title}
                </CMPTypography>
                <CMPTextField
                    disabled={Boolean(email)}
                    id="outlined-basic"
                    label={CMPTexts.login.textFieldEmail}
                    value={formik.values.email}
                    onChange={() => null}
                    error={Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                />
                <CMPTextField
                    variation={TextVariation.PASSWORD}
                    value={formik.values.tempPassword}
                    onChange={(val) =>
                        formik.setFieldValue('tempPassword', val)
                    }
                    required
                    id="outlined-basic"
                    label={CMPTexts.emailVerification.textFieldTempPass}
                    error={Boolean(formik.errors.tempPassword)}
                    helperText={formik.errors.tempPassword}
                />
                <CMPValidationTextField
                    variation={TextVariation.PASSWORD}
                    value={formik.values.password}
                    onChange={(val) => formik.setFieldValue('password', val)}
                    required
                    id="password"
                    onBlur={formik.handleBlur}
                    label={CMPTexts.emailVerification.textFieldNewPass}
                    error={
                        Boolean(formik.errors.password) &&
                        Boolean(formik.touched.password)
                    }
                    options={[
                        {
                            name: 'One Lowercase Character',
                            validation: /[a-z]/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Uppercase Character',
                            validation: /[A-Z]/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Number',
                            validation: /\d/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: '8 Characters Minimum',
                            validation: /.{8,}/.test(formik.values.password)
                                ? Validation.PASS
                                : Validation.ERROR
                        },
                        {
                            name: 'One Special Character',
                            validation: /[!@#$%^&*()\-=+{};:,<.>]/.test(
                                formik.values.password
                            )
                                ? Validation.PASS
                                : Validation.ERROR
                        }
                    ]}
                />
                <CMPValidationTextField
                    variation={TextVariation.PASSWORD}
                    value={formik.values.repeatPassword}
                    onChange={(val) =>
                        formik.setFieldValue('repeatPassword', val)
                    }
                    required
                    id="repeatPassword"
                    label={CMPTexts.emailVerification.textFieldRepeatNewPass}
                    onBlur={formik.handleBlur}
                    error={
                        Boolean(formik.errors.repeatPassword) &&
                        Boolean(formik.touched.repeatPassword)
                    }
                    options={[
                        {
                            name: 'Passwords Must Match',
                            validation:
                                formik.values.password.length > 0 &&
                                formik.values.password ===
                                    formik.values.repeatPassword
                                    ? Validation.PASS
                                    : Validation.ERROR
                        }
                    ]}
                />
                <CMPTypography
                    variation={TypographyVariation.TYPO_SCALE_14}
                    sx={{
                        marginTop: pxToRem(8),
                        color: CMPColors.light.text.tertiary
                    }}
                >
                    {CMPTexts.common.requiredText}
                </CMPTypography>
                <ButtonContainer>
                    <Button
                        type="submit"
                        buttonVariant={ButtonVariation.CONTAINED}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        {CMPTexts.emailVerification.button}
                    </Button>
                </ButtonContainer>
            </FormWrapper>
        </>
    );
};

export default EmailVerification;
