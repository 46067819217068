import { Action } from 'redux';
import { ApiResponse } from '../../api/apiInterfaces';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    AddGroupPayload,
    AddGroupResponse,
    AddPropertyPayload,
    DuplicatePropertyPayload,
    AddPropertyResponse,
    DeletePropertyGroupPayload,
    GetPropertyListResponse,
    MovePropertyPayload,
    PropertiesUnderPropertyGroupRes,
    PropertyGroupResponse,
    PropertyIdPayload,
    PropertyTypesResponse,
    RegisterPropertyPayload,
    SearchPropertyGroup,
    SetExpandedPropertyGroupIds,
    UpdatePropertyNameInPropertyList,
    EditPropertyGroupPayload
} from './actionPayload';
import { PropertyDetails, PropertyGroupInterface, PropertyType } from './model';

export const PROPERTY_LIST = 'PROPERTY_LIST';
export const PROPERTY_LIST_RESPONSE = 'PROPERTY_LIST_RESPONSE';
export const ADD_PROPERTY_GROUP = 'ADD_PROPERTY_GROUP';
export const ADD_PROPERTY_GROUP_RESPONSE = 'ADD_PROPERTY_GROUP_RESPONSE';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const ADD_PROPERTY_RESPONSE = 'ADD_PROPERTY_RESPONSE';
export const DUPLICATE_PROPERTY = 'DUPLICATE_PROPERTY';
export const DUPLICATE_PROPERTY_RESPONSE = 'DUPLICATE_PROPERTY_RESPONSE';
export const MOVE_PROPERTY = 'MOVE_PROPERTY';
export const MOVE_PROPERTY_RESPONSE = 'MOVE_PROPERTY_RESPONSE';
export const PURGE_PROPERTY_LIST = 'PURGE_PROPERTY_LIST';
export const REGISTER_PROPERTY = 'REGISTER_PROPERTY';
export const REGISTER_PROPERTY_RESPONSE = 'REGISTER_PROPERTY_RESPONSE';
export const GET_PROPERTY_DETAILS = 'GET_PROPERTY_DETAILS';
export const PROPERTY_DETAILS_RESPONSE = 'PROPERTY_DETAILS_RESPONSE';
export const PURGE_PROPERTY_DETAILS = 'PURGE_SMART_BOX_DETAILS';
export const UPDATE_PROPERTY_NAME_IN_PROPERTY_LIST =
    'UPDATE_PROPERTY_NAME_IN_PROPERTY_LIST';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const DELETE_PROPERTY_RESPONSE = 'DELETE_PROPERTY_RESPONSE';
export const DELETE_PROPERTY_GROUP = 'DELETE_PROPERTY_GROUP';
export const EDIT_PROPERTY_GROUP = 'EDIT_PROPERTY_GROUP';
export const EDIT_PROPERTY_GROUP_RESPONSE = 'EDIT_PROPERTY_GROUP_RESPONSE';
export const DELETE_PROPERTY_GROUP_RESPONSE = 'DELETE_PROPERTY_GROUP_RESPONSE';
export const SEARCH_PROPERTY_GROUP_OR_PROPERTY =
    'SEARCH_PROPERTY_GROUP_OR_PROPERTY';
export const MIGRATE_PROPERTY = 'MIGRATE_PROPERTY';
export const MIGRATE_PROPERTY_RESPONSE = 'MIGRATE_PROPERTY_RESPONSE';
export const PROPERTY_TYPES = 'PROPERTY_TYPES';
export const PROPERTY_TYPES_RESPONSE = 'PROPERTY_TYPES_RESPONSE';
export const EXPANDED_PROPERTY_GROUP_ID = 'EXPANDED_PROPERTY_GROUP_ID';
export const RESET_PROPERTY_LOADER = 'RESET_PROPERTY_LOADER';
export const RESET_PROPERTY_OPERATIONS_LOADER =
    'RESET_PROPERTY_OPERATIONS_LOADER';
export const PURGE_REDIRECT_TO_PROPERTY_ID = 'PURGE_REDIRECT_TO_PROPERTY_ID';

export const resetPropertyLoader = (): Action => {
    return {
        type: RESET_PROPERTY_LOADER
    };
};

export const purgeRedirectToPropertyId = (): Action => {
    return {
        type: PURGE_REDIRECT_TO_PROPERTY_ID
    };
};

export const resetPropertOperationsLoader = (): Action => {
    return {
        type: RESET_PROPERTY_OPERATIONS_LOADER
    };
};

export const purgeCurrentPropertyDetails = (): Action => {
    return {
        type: PURGE_PROPERTY_DETAILS
    };
};

export const getPropertyDetails = (payload: string): ActionPayload<string> => {
    return {
        type: GET_PROPERTY_DETAILS,
        payload: payload
    };
};

export const propertyDetailsResponse = (
    payload: PropertyDetails,
    error: boolean
): ActionPayloadOrError<PropertyDetails> => {
    return {
        type: PROPERTY_DETAILS_RESPONSE,
        payload,
        error
    };
};

export const registerProperty = (
    propertyName: string,
    smartBoxId: string | undefined,
    propertyId: string,
    description?: string,
    zip_code?: string,
    propertyType?: string
): ActionPayload<RegisterPropertyPayload> => {
    return {
        type: REGISTER_PROPERTY,
        payload: {
            propertyName: propertyName,
            smartBoxId,
            propertyId: propertyId,
            description,
            zip_code,
            propertyType
        }
    };
};

export const registerPropertyResponse = (
    payload: ApiResponse<string>,
    error: boolean,
    message?: string
): ActionPayloadOrError<ApiResponse<string> | string> => {
    return {
        type: REGISTER_PROPERTY_RESPONSE,
        payload,
        message,
        error
    };
};

export const purgePropertyList = (): Action => {
    return {
        type: PURGE_PROPERTY_LIST
    };
};

export const addGroup = (
    payload: AddGroupPayload
): ActionPayload<AddGroupPayload> => {
    return {
        type: ADD_PROPERTY_GROUP,
        payload: payload
    };
};

export const addGroupResponse = (
    error: boolean,
    message: string,
    propertyGroupResponse?: PropertyGroupResponse
): ActionPayloadOrError<AddGroupResponse> => {
    return {
        type: ADD_PROPERTY_GROUP_RESPONSE,
        payload: { propertyGroupResponse },
        message,
        error
    };
};

export const addProperty = (
    payload: AddPropertyPayload
): ActionPayload<AddPropertyPayload> => {
    return {
        type: ADD_PROPERTY,
        payload: payload
    };
};

export const addPropertyResponse = (
    error: boolean,
    message: string,
    propertyGroupResponse?: PropertiesUnderPropertyGroupRes
): ActionPayloadOrError<AddPropertyResponse> => {
    return {
        type: ADD_PROPERTY_RESPONSE,
        payload: { propertyGroupResponse },
        message,
        error
    };
};
export const duplicateProperty = (
    payload: DuplicatePropertyPayload
): ActionPayload<DuplicatePropertyPayload> => {
    return {
        type: DUPLICATE_PROPERTY,
        payload: payload
    };
};

export const duplicatePropertyResponse = (
    error: boolean,
    message: string,
    propertyGroupResponse?: PropertiesUnderPropertyGroupRes
): ActionPayloadOrError<AddPropertyResponse> => {
    return {
        type: DUPLICATE_PROPERTY_RESPONSE,
        message,
        payload: { propertyGroupResponse },
        error
    };
};
export const moveProperty = (
    payload: MovePropertyPayload
): ActionPayload<MovePropertyPayload> => {
    return {
        type: MOVE_PROPERTY,
        payload: payload
    };
};

export const movePropertyResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<null> => {
    return {
        type: MOVE_PROPERTY_RESPONSE,
        message,
        error
    };
};

export const getPropertyList = (): Action => {
    return {
        type: PROPERTY_LIST
    };
};

export const getPropertyListResponse = (
    error: boolean,
    message: string,
    propertyList?: PropertyGroupInterface[]
): ActionPayloadOrError<GetPropertyListResponse> => {
    return {
        type: PROPERTY_LIST_RESPONSE,
        payload: {
            propertyList: propertyList
        },
        message,
        error
    };
};

export const updatePropertyNameInPropertyList = (
    propertyGroupId: string,
    propertyId: string,
    propertyName: string
): ActionPayload<UpdatePropertyNameInPropertyList> => {
    return {
        type: UPDATE_PROPERTY_NAME_IN_PROPERTY_LIST,
        payload: { propertyGroupId, propertyId, propertyName }
    };
};

export const deleteProperty = (
    propertyId: string
): ActionPayload<PropertyIdPayload> => {
    return {
        type: DELETE_PROPERTY,
        payload: { propertyId }
    };
};

export const deletePropertyResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: DELETE_PROPERTY_RESPONSE,
        error,
        message
    };
};

export const deletePropertyGroup = (
    propertyGroupId: string
): ActionPayload<DeletePropertyGroupPayload> => {
    return {
        type: DELETE_PROPERTY_GROUP,
        payload: { propertyGroupId }
    };
};

export const deletePropertyGroupResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: DELETE_PROPERTY_GROUP_RESPONSE,
        error,
        message
    };
};

export const migrateProperty = (
    propertyId: string
): ActionPayload<PropertyIdPayload> => {
    return {
        type: MIGRATE_PROPERTY,
        payload: { propertyId }
    };
};

export const migratePropertyResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: MIGRATE_PROPERTY_RESPONSE,
        error,
        message
    };
};

export const editPropertyGroup = (
    propertyGroupId: string,
    propertyGroupName: string
): ActionPayload<EditPropertyGroupPayload> => {
    return {
        type: EDIT_PROPERTY_GROUP,
        payload: { propertyGroupId, propertyGroupName }
    };
};

export const editPropertyGroupResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: EDIT_PROPERTY_GROUP_RESPONSE,
        error,
        message
    };
};

export const searchPropertyGroupOrProperty = (
    searchKeyword: string
): ActionPayload<SearchPropertyGroup> => {
    return {
        type: SEARCH_PROPERTY_GROUP_OR_PROPERTY,
        payload: { searchKeyword }
    };
};

export const setExpandedPropertyGroupIds = (
    expandedPropertyGroupIds: string[]
): ActionPayload<SetExpandedPropertyGroupIds> => {
    return {
        type: EXPANDED_PROPERTY_GROUP_ID,
        payload: { expandedPropertyGroupIds }
    };
};

export const getPropertyTypes = (): Action => {
    return {
        type: PROPERTY_TYPES
    };
};

export const propertyTypesResponse = (
    error: boolean,
    message: string,
    propertyTypes?: PropertyType[]
): ActionPayloadOrError<PropertyTypesResponse> => {
    return {
        type: PROPERTY_TYPES_RESPONSE,
        payload: { propertyTypes },
        message,
        error
    };
};
