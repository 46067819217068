import { LoadingStatus } from '../common/state';
import {
    Channel,
    emptyPropertyFieldValues,
    PropertyFieldValues
} from './model';

export interface PropertyValue {
    fieldConfigValues: PropertyFieldValues;
    propertyValueOperationLoader: LoadingStatus;
    propertyValueLoader: LoadingStatus;
    error: string;
    showGenericCommentModal: boolean;
    queuedActions: any;
    comment: string;
    channels: Channel[];
    authorizedChannelsLoader: LoadingStatus;
}

export const initialPropertyValueState: PropertyValue = {
    fieldConfigValues: emptyPropertyFieldValues,
    propertyValueOperationLoader: LoadingStatus.NONE,
    propertyValueLoader: LoadingStatus.NONE,
    error: '',
    showGenericCommentModal: false,
    queuedActions: [],
    comment: '',
    channels: [],
    authorizedChannelsLoader: LoadingStatus.NONE
};
