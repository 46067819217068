import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    SxProps,
    Tab,
    Tabs
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as InfoIcon } from '../../../../assets/svg/info.svg';
import Tooltip from '../../../../components/tooltip';
import { getAllRooms, purgeRooms } from '../../../../redux/zones/action';
import { EventZone, Room, Zone, ZoneType } from '../../../../redux/zones/model';
import { selectAllRooms } from '../../../../redux/zones/reducer';
import { shallowSearch } from '../../../../utils/searchUtil';
import { pxToRem, StylesDictionary } from '../../../../utils/stylesUtils';
import IconStylizer from '../../../../components/iconStylizer';
import { isEqual } from 'lodash';
import CMPTextField, {
    TextVariation
} from '../../../../components/textFields/cmpTextField';
import CMPTypography, {
    TypographyStyles,
    TypographyVariation
} from '../../../../components/textFields/cmpTypography';
import CMPColors from '../../../../components/colors';

interface ZoneRoomSelectorSectionProps {
    zone: Zone;
    isEditMode: boolean;
    onSelectRooms?: (
        isEdited: boolean,
        hasError: boolean,
        rooms: string[]
    ) => void;
    sx?: SxProps;
}

const styles: StylesDictionary = {
    TabsOverlay: {
        borderBottom: 1,
        borderColor: 'divider',
        marginBottom: pxToRem(20),
        padding: pxToRem(0),
        '& .MuiTabs-flexContainer': {
            gap: '10%',
            '& .MuiButtonBase-root': {
                ...TypographyStyles.TYPO_SCALE_16,
                padding: `${pxToRem(12)} 0 ${pxToRem(6)}`
            }
        },
        '& .MuiTab-root': {
            textTransform: 'capitalize',
            color: CMPColors.light.text.primary
        }
    }
};

enum RoomSelectorTabs {
    ASSIGNED_ROOMS,
    ASSIGN_NEW_ROOMS
}

interface RoomSelectionUIData {
    roomName: string;
    deviceId: string;
    hasOverlap: boolean;
    message: string;
}

const transformRoomsToRoomSelectionUIData = (
    rooms: Room[],
    zone: Zone
): RoomSelectionUIData[] => {
    return rooms?.map((r) => {
        let hasOverlap = false;
        let message = '';

        switch (zone.type) {
            case ZoneType.Standard: {
                if (
                    r.zones_attached.standard_zone &&
                    r.zones_attached.standard_zone.id !== zone.id
                ) {
                    hasOverlap = true;
                    message = `In ${r.zones_attached.standard_zone.name} Zone`;
                }
                break;
            }

            case ZoneType.Event: {
                const eventZone = zone as EventZone;
                const overlappingEvent = r.zones_attached.event_zone
                    ?.filter((e) => e.id !== zone.id)
                    ?.find((existingEvent) => {
                        return (
                            existingEvent.start_time < eventZone.end_time &&
                            existingEvent.end_time > eventZone.start_time &&
                            existingEvent.id !== eventZone.id
                        );
                    });

                if (overlappingEvent) {
                    hasOverlap = true;
                    message = `In ${overlappingEvent.name} Event`;
                }
                break;
            }
        }

        return {
            roomName: r.room_no,
            deviceId: r.id,
            hasOverlap,
            message
        };
    });
};

const ZoneRoomSelectorSection = (props: ZoneRoomSelectorSectionProps) => {
    const { sx, isEditMode, zone, onSelectRooms } = props;
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState(
        isEditMode
            ? RoomSelectorTabs.ASSIGNED_ROOMS
            : RoomSelectorTabs.ASSIGN_NEW_ROOMS
    );

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const alreadyAssignedDeviceIds = zone.rooms_attached.map(
        (ar) => ar.device_id
    );

    const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>(
        alreadyAssignedDeviceIds
    );

    const allRooms = useSelector(selectAllRooms);

    useEffect(() => {
        dispatch(getAllRooms(zone.property_id));
        return () => {
            dispatch(purgeRooms());
        };
    }, []);

    const allRoomsData = useMemo(
        () => transformRoomsToRoomSelectionUIData(allRooms, zone),
        [allRooms, zone]
    );

    const assignedRoomsData = useMemo(
        () =>
            allRoomsData.filter((r) =>
                alreadyAssignedDeviceIds.includes(r.deviceId)
            ),
        [allRoomsData, alreadyAssignedDeviceIds]
    );

    const unAssignedRoomsData = useMemo(
        () =>
            allRoomsData.filter(
                (r) => !alreadyAssignedDeviceIds.includes(r.deviceId)
            ),
        [allRoomsData, alreadyAssignedDeviceIds]
    );

    const roomDataList = useMemo(() => {
        let roomDataList =
            selectedTab === RoomSelectorTabs.ASSIGNED_ROOMS
                ? assignedRoomsData
                : unAssignedRoomsData;

        if (searchText) {
            roomDataList = shallowSearch(roomDataList, searchText);
        }
        return roomDataList;
    }, [selectedTab, assignedRoomsData, unAssignedRoomsData]);

    useEffect(() => {
        // uncheck select all button when searchtext changes
        setSelectAllChecked(false);
    }, [searchText]);

    const hasError =
        zone.type === ZoneType.Event &&
        allRoomsData.some(
            (rd) => rd.hasOverlap && selectedDeviceIds.includes(rd.deviceId)
        );

    useEffect(() => {
        onSelectRooms?.(
            !isEqual(alreadyAssignedDeviceIds.sort(), selectedDeviceIds.sort()),
            hasError,
            selectedDeviceIds
        );
    }, [hasError, selectedDeviceIds]);

    const onRoomSelectionUpdated = (deviceId: string, selected: boolean) => {
        const updatedDeviceIds = selected
            ? [...selectedDeviceIds, deviceId]
            : selectedDeviceIds.filter((id) => id !== deviceId);
        setSelectedDeviceIds(updatedDeviceIds);
    };

    const handleGroupSelection = (selected: boolean) => {
        setSelectAllChecked(selected);
        const deviceIds = roomDataList
            .filter((r) => !r.hasOverlap)
            .map((r) => r.deviceId);
        const filteredDeviceIds = selectedDeviceIds.filter(
            (id) => !deviceIds.includes(id)
        );

        if (selected)
            setSelectedDeviceIds([...filteredDeviceIds, ...deviceIds]);
        else setSelectedDeviceIds(filteredDeviceIds);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...sx
            }}
        >
            {isEditMode && (
                <Tabs
                    value={selectedTab}
                    sx={styles.TabsOverlay}
                    onChange={(_, val: RoomSelectorTabs) => setSelectedTab(val)}
                >
                    <Tab
                        label="Assigned Rooms"
                        value={RoomSelectorTabs.ASSIGNED_ROOMS}
                    />
                    <Tab
                        label="Assign New Rooms"
                        value={RoomSelectorTabs.ASSIGN_NEW_ROOMS}
                    />
                </Tabs>
            )}

            <Box
                sx={{
                    display: 'flex',
                    marginBottom: pxToRem(16),
                    justifyContent: 'space-between'
                }}
            >
                <CMPTextField
                    variation={TextVariation.SEARCH}
                    enableDebouce
                    fullWidth
                    value={searchText}
                    placeholder="Search"
                    sx={{ width: '60%' }}
                    onChange={setSearchText}
                />

                {selectedTab === RoomSelectorTabs.ASSIGN_NEW_ROOMS && (
                    <FormControlLabel
                        label={'Select All Rooms'}
                        control={
                            <Checkbox
                                checked={selectAllChecked}
                                onChange={(e) =>
                                    handleGroupSelection(e.target.checked)
                                }
                            />
                        }
                    />
                )}
            </Box>

            <Box
                sx={{
                    border: `0.5px solid ${CMPColors.light.stroke.default}`,
                    padding: pxToRem(8),
                    borderRadius: pxToRem(12),
                    flexGrow: 1,
                    overflowY: 'auto',
                    height: pxToRem(282),
                    justifyContent: 'center',
                    display: 'flex'
                }}
            >
                <Grid
                    container
                    sx={{
                        width: '80%',
                        height: 'fit-content'
                    }}
                    rowGap={1}
                >
                    {roomDataList.map((rd) => {
                        const isDeviceSelected = selectedDeviceIds.includes(
                            rd.deviceId
                        );
                        const disableSelection =
                            rd.hasOverlap && !isDeviceSelected;
                        const showError =
                            rd.hasOverlap &&
                            isDeviceSelected &&
                            zone.type === ZoneType.Event;

                        return (
                            <Grid item xs={4} key={rd.deviceId}>
                                <Box
                                    sx={{
                                        position: 'relative'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FormControlLabel
                                            label={rd.roomName}
                                            disabled={disableSelection}
                                            control={
                                                <Checkbox
                                                    checked={selectedDeviceIds.includes(
                                                        rd.deviceId
                                                    )}
                                                    onChange={(e) => {
                                                        onRoomSelectionUpdated(
                                                            rd.deviceId,
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                        {showError && (
                                            <IconStylizer
                                                color={
                                                    CMPColors.light.icon.error
                                                }
                                                height={pxToRem(15)}
                                                width={pxToRem(16)}
                                            >
                                                <InfoIcon />
                                            </IconStylizer>
                                        )}
                                    </Box>
                                    {disableSelection && (
                                        <Tooltip message={rd.message}>
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            {hasError && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: pxToRem(4),
                        marginTop: pxToRem(4)
                    }}
                >
                    <InfoOutlined sx={{ color: CMPColors.light.icon.error }} />
                    <CMPTypography
                        sx={{
                            color: CMPColors.light.text.textField.error
                        }}
                        variation={TypographyVariation.TYPO_SCALE_12}
                        text={`Some rooms already belong to an event during this time.
                    Please deselect rooms with an error or exit this modal and
                    remove the rooms from the other event.`}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ZoneRoomSelectorSection;
