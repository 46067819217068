import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../../hooks/useParam';
import { getAssetLists } from '../../../redux/assets/action';
import { selectAssets } from '../../../redux/assets/reducer';
import { MediaConfigType, MediaType } from '../../../redux/element/model';
import { PropertyField } from '../../../redux/propertyValue/model';
import PropertyAssetSelector from './components/propertyAssetSelector';
import PropertySectionHeader from './components/propertySectionHeader';

interface Props {
    asset: PropertyField;
    onFieldUpdate: (index: number, value: any) => void;
    mediaConfigs?: MediaConfigType;
}

function PropertyAssetSection({ asset, onFieldUpdate, mediaConfigs }: Props) {
    const { property } = useUrlParams();
    const dispatch = useDispatch();
    const assets = useSelector(selectAssets);
    const mediaType = mediaConfigs?.mediaType ?? MediaType.Image;
    const filteredAssets = mediaType
        ? assets.filter(
              (a) =>
                  a.type.toLocaleLowerCase() === mediaType.toLocaleLowerCase()
          )
        : assets;

    useEffect(() => {
        dispatch(getAssetLists(property));
    }, []);

    const handleAssetChange = (assetId: string, index: number) => {
        onFieldUpdate(index, assetId);
    };

    return (
        <>
            {asset?.children?.map((cField, i) => {
                const canView = cField.access_details?.View;
                const canEdit = cField.access_details?.Edit;

                if (!canView) return null;

                return (
                    <Box
                        key={cField.id}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <PropertySectionHeader
                            header={cField.name}
                            tooltipMessage={cField?.configuration?.toolTip}
                        />

                        <Box sx={{ flexGrow: 1 }}>
                            <PropertyAssetSelector
                                mediaConfigs={mediaConfigs}
                                assets={filteredAssets}
                                onChange={(assetId: string) =>
                                    handleAssetChange(assetId, i)
                                }
                                selectedAssetId={cField.value as string}
                                assetSelectionAllowed={canEdit}
                                label="Select Logo"
                            />
                        </Box>
                    </Box>
                );
            })}
        </>
    );
}

export default PropertyAssetSection;
