import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import CMPColors from './colors';

//@ts-ignore
const CustomPaper = styled(Paper)(() => ({
    textAlign: 'start',
    backgroundColor: CMPColors.light.background.modal,
    boxShadow: 12,
    padding: pxToRem(32)
}));

interface Props {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

function StyledPaper(props: Props) {
    const { children, sx } = props;
    return (
        <CustomPaper elevation={0} {...props} sx={sx}>
            {children}
        </CustomPaper>
    );
}

export default StyledPaper;
