import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import { DisableableBox } from '../../components/disableableBox';
import { Asset } from '../../redux/assets/model';
import { MediaConfigType } from '../../redux/element/model';
import AssetSelectionDropDown from './assetSelectionDropDown';
import CMPTypography, {
    TypographyVariation
} from '../../components/textFields/cmpTypography';
import CMPColors from '../../components/colors';

const Container = styled('div')({
    marginTop: '3vh',
    marginBottom: '3vh'
});

export const InputBox = styled('input')({
    display: 'none'
});

export interface MediaInputFieldProps {
    assets: Asset[];
    name: string;
    description: string;
    isRequired: boolean;
    disabled: boolean;
    initialAssetId?: string;
    onMediaAssetSelected: (assetId: string) => void;
    mediaConfigs: MediaConfigType;
}

const MediaAssetInputField = (props: MediaInputFieldProps) => {
    const {
        assets,
        isRequired,
        disabled,
        name,
        description,
        initialAssetId = '',
        mediaConfigs,
        onMediaAssetSelected
    } = props;

    const [selectedAssetId, setSelectedAssetId] = useState(initialAssetId);
    const { allowedFileTypes, fileUploadInfoMessage, maxFileSize } =
        mediaConfigs;

    const onAssetSelected = (assetId: string) => {
        onMediaAssetSelected(assetId);
        setSelectedAssetId(assetId);
    };

    useEffect(() => {
        if (isEmpty(initialAssetId)) {
            setSelectedAssetId('');
        }
    }, [initialAssetId]);

    return (
        <Container>
            <CMPTypography
                variation={TypographyVariation.HEADER5}
                sx={{
                    marginTop: '3vh',
                    color: CMPColors.light.text.primary
                }}
            >
                {`${name} ${isRequired ? '*' : ''}`}
            </CMPTypography>
            <CMPTypography
                variation={TypographyVariation.TYPO_SCALE_14}
                sx={{
                    color: CMPColors.light.text.primary
                }}
            >
                <Markdown>{description}</Markdown>
            </CMPTypography>
            <DisableableBox disabled={disabled}>
                <AssetSelectionDropDown
                    name={name}
                    selectedAssetId={selectedAssetId}
                    onAssetSelected={onAssetSelected}
                    assets={assets}
                    allowedFileTypes={allowedFileTypes}
                    assetUploadInfoMessage={fileUploadInfoMessage}
                    isRequired={isRequired}
                    maxFileSize={maxFileSize}
                />
            </DisableableBox>
        </Container>
    );
};

export default MediaAssetInputField;
