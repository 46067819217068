import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import ApiResponseCode from '../../api/apiResponseCode';
import cmpTexts from '../../locale/en';
import { elementsResponse } from '../elementsList/action';
import { fetchAllElementsHelper } from '../elementsList/helper';
import { ElementsResponse } from '../elementsList/model';
import { ActionPayload } from '../types';
import {
    createElementResponse,
    CREATE_ELEMENT,
    FETCH_ELEMENT,
    FETCH_ELEMENT_SKELETON,
    saveAllElementResponse,
    saveElementResponse,
    SAVE_ELEMENT,
    SAVE_ELEMENTS,
    widgetSkeletonResponse,
    CREATE_ALL_ELEMENTS,
    createAllElementsResponse
} from './action';
import {
    CreateAllElements,
    CreateAndSaveElement,
    FetchElement,
    SaveAllElementsPayload
} from './actionPayload';
import {
    createElementHelper,
    fetchElement,
    fetchWidgetSkeleton,
    saveElementHelper
} from './helper';
import {
    ContentField,
    ContentValues,
    ElementCreateUpdateRequestBody,
    WidgetSkeleton,
    WidgetSkeletonPayload
} from './model';

function* fetchWidgetSkeletonWorker(
    data: ActionPayload<WidgetSkeletonPayload>
) {
    try {
        const { widgetTypeId, propertyId } = data.payload;
        const widgetSkeleton: ApiResponse<WidgetSkeleton> = yield call(
            fetchWidgetSkeleton,
            widgetTypeId,
            propertyId
        );

        yield put(widgetSkeletonResponse(widgetSkeleton.response_body));
    } catch (err: any) {
        yield put(widgetSkeletonResponse(undefined, err.message));
    }
}

//=======================================================================================
//                  create element
//=======================================================================================

function createElementRequestBody(
    widgetSkeleton: WidgetSkeleton,
    propertyId: string
): ElementCreateUpdateRequestBody {
    const content_values: ContentValues[] = widgetSkeleton.content_fields.map(
        (content: ContentField) => {
            return {
                content_field_id: content.content_field_id ?? content.id,
                values: content.values,
                type: content.type,
                id: content.content_field_id ? content.id : ''
            };
        }
    );
    return {
        associated_to: 'PROPERTY',
        association_id: propertyId,
        content_values: content_values,
        id: widgetSkeleton.id,
        name: widgetSkeleton.name,
        status: widgetSkeleton.status,
        widget_type_id: widgetSkeleton.widget_type_id ?? widgetSkeleton.id
    };
}

function* createElementWorker(data: ActionPayload<CreateAndSaveElement>) {
    const { element, propertyId } = data.payload;
    try {
        const res: ApiResponse<ElementCreateUpdateRequestBody> = yield call(
            createElementHelper,
            createElementRequestBody(element, propertyId),
            propertyId
        );

        const elements: ApiResponse<ElementsResponse[]> = yield call(
            fetchAllElementsHelper,
            propertyId
        );

        yield put(elementsResponse(elements.response_body));

        yield put(createElementResponse(false, [res.response_body]));
    } catch (err) {
        const e: any = err;
        const result = e.message;
        yield put(
            createElementResponse(true, result || cmpTexts.common.genericError)
        );
    }
}

function* createAllElementsWorker(data: ActionPayload<CreateAllElements>) {
    const { elements, propertyId } = data.payload;
    try {
        const createAllElementsPromise = elements.map((element) =>
            call(
                createElementHelper,
                createElementRequestBody(element, propertyId),
                propertyId
            )
        );

        const apiResponse: ApiResponse<ElementCreateUpdateRequestBody>[] =
            yield all(createAllElementsPromise);

        const normalizedApiResponse = apiResponse.map(
            (res) => res.response_body
        );

        const allElements: ApiResponse<ElementsResponse[]> = yield call(
            fetchAllElementsHelper,
            propertyId
        );

        yield put(elementsResponse(allElements.response_body));

        yield put(createAllElementsResponse(false, normalizedApiResponse));
    } catch (err: any) {
        yield put(
            createAllElementsResponse(
                true,
                err.message ?? 'Error Occurred While Creating The Elements'
            )
        );
    }
}

//=======================================================================================
//                  save element
//=======================================================================================

function* saveElementWorker(data: ActionPayload<CreateAndSaveElement>) {
    console.log('saveElementWorker');
    const { element, propertyId } = data.payload;
    try {
        const res: ApiResponse<ElementCreateUpdateRequestBody> = yield call(
            saveElementHelper,
            createElementRequestBody(element, propertyId),
            propertyId
        );

        const elements: ApiResponse<ElementsResponse[]> = yield call(
            fetchAllElementsHelper,
            propertyId
        );

        yield put(elementsResponse(elements.response_body));

        yield put(saveElementResponse(false, res.response_body));
    } catch (err) {
        const e: any = err;
        const result = e.message;
        yield put(
            saveElementResponse(true, result || cmpTexts.common.genericError)
        );
    }
}

//=======================================================================================
//                  save all elements
//=======================================================================================

function* saveAllElementsWorker(data: ActionPayload<SaveAllElementsPayload>) {
    const { elements, propertyId } = data.payload;
    try {
        const elementSaveRequestBodies = elements.map((e) =>
            createElementRequestBody(e, propertyId)
        );
        const elementSaveResponsePromise = elementSaveRequestBodies.map((erb) =>
            call(saveElementHelper, erb, propertyId)
        );
        yield all(elementSaveResponsePromise);

        yield put(saveAllElementResponse(false, 'Page Saved Successfully'));
    } catch (err: any) {
        yield put(
            saveAllElementResponse(
                true,
                err.message ?? 'Error Occurred While Saving The Page'
            )
        );
    }
}

//==================================================================
//                       get element worker
//==================================================================

function* fetchElementWorker(data: ActionPayload<FetchElement>) {
    try {
        const elementResponse: ApiResponse<WidgetSkeleton> = yield call(
            fetchElement,
            data.payload.elementId,
            data.payload.propertyId
        );
        if (elementResponse.code !== ApiResponseCode[200]) {
            throw elementResponse;
        }
        yield put(widgetSkeletonResponse(elementResponse.response_body));
        console.log('new form widget----> ', elementResponse);
    } catch (err) {
        console.log(err);
        const e: any = err;
        yield put(
            widgetSkeletonResponse(
                undefined,
                e.message || cmpTexts.common.genericError
            )
        );
    }
}

export function* watchElements() {
    yield all([
        takeLatest(FETCH_ELEMENT_SKELETON, fetchWidgetSkeletonWorker),
        takeLatest(CREATE_ELEMENT, createElementWorker),
        takeLatest(CREATE_ALL_ELEMENTS, createAllElementsWorker),
        takeLatest(SAVE_ELEMENT, saveElementWorker),
        takeLatest(FETCH_ELEMENT, fetchElementWorker),
        takeLatest(SAVE_ELEMENTS, saveAllElementsWorker)
    ]);
}
